import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { connect } from 'react-redux';
import {
  loginStart,
  registerStart,
} from '../../store/actions/UserAction';
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Auth.css";
import Slider from "react-slick";
import configuration from "react-global-configuration";
import SocialButton from '../Helper/SocialButton';

const LoginIndex = (props) => {

  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (values) => {
    props.dispatch(loginStart(values));
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email_address")).required(t("email_required")),
    password: Yup.string().required(t("password_required")).matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, (t("must_contain_6_characters"))),
  });
  useEffect(() => {
    if (!skipRender && !props.login.loading && Object.keys(props.login.data).length > 0) {
      if ((props.login.data.is_email_verified === 1)) {
        navigate("/");
      }
      else {
        navigate("/register/verify");
      }
    }
    setSkipRender(false);
  }, [props.login]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className="auth-page-sec">
        <div className="auth-page-left-sec">
          <Slider {...settings}>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-1.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-2.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-3.jpeg"}
              />
            </div>
          </Slider>
          <div className="auth-nav-switch-sec">
            <Link to="/login"
              className={`login-link ${location.pathname === "/login" ? "active" : ""
                }`}
            >
              {t("login")}</Link>
            <Link to="/register"
              className={`login-link ${location.pathname === "/register" ? "active" : ""
                }`}

            >{t("register")}</Link>
          </div>
        </div>
        <div className="auth-page-right-sec">
          <div className="auth-details-sec">
            <div className="auth-logo-sec">
              <Image
                className="auth-logo"
                src={window.location.origin + "/images/logo-black.png"}
              />
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              enableReinitialize={true}
              onSubmit={(values) => handleLogin(values)}
              validationSchema={loginSchema}
            >
              <FORM className="auth-form-sec">
                <div className="auth-info">
                  <h2>{t("login")}</h2>
                </div>
                <div className="auth-form">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Field
                      type="text"
                      placeholder={t("enter_email_address")}
                      name="email"
                      className="form-control"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="text-danger"
                    />
                  </Form.Group>
                  <InputGroup className="mb-0">
                    <Field
                      type={showPassword ? "text" : "password"}
                      placeholder={t("enter_password")}
                      name="password"
                      className="form-control"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <button
                        onClick={() =>
                          setShowPassword(!showPassword)
                        }
                        className="btn password-eye"
                        type="button"
                      >
                        {showPassword ?

                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                          />
                          :
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                          />
                        }
                      </button>
                    </InputGroup.Text>
                    <ErrorMessage
                      component={"div"}
                      name="password"
                      className='text-danger pass-txt'
                    />
                  </InputGroup>
                  <div className="forgot-password-link-sec">
                    <Link to="/forgot-password">
                      {t("forgot_password")}
                    </Link>
                  </div>
                  <div className="log-btn-sec">
                    <Button
                      className="default-btn"
                      type="submit"
                      disabled={props.login.buttonDisable}
                    >
                      {props.login.loadingButtonContent !== null ?
                        props.login.loadingButtonContent :
                        t("login")
                      }
                    </Button>
                  </div>

                </div>
              </FORM>
            </Formik>
            <div className="auth-social-btn-sec">
              {configuration.get("configData.FB_CLIENT_ID") ?
                <SocialButton
                  provider="facebook"
                  appId={configuration.get("configData.FB_CLIENT_ID")}
                  onLoginSuccess={handleFacebookLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  className="social-button"
                  id="facebook-connect"
                >
                  <Image
                    className="social-logo"
                    src={window.location.origin + "/images/auth/icon/facebook.svg"}
                  />
                </SocialButton>
                :
                null
              }
              {configuration.get("configData.GOOGLE_CLIENT_ID") ?
                <SocialButton
                  provider="google"
                  key={"google"}
                  appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                  onLoginSuccess={handleGoogleLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  className="social-button"
                  id="google-connect"
                >
                  <Image
                    className="social-logo"
                    src={window.location.origin + "/images/auth/icon/google.svg"}
                  />
                </SocialButton>
                :
                null
              }
            </div>
            <div className="auth-footer-link-sec">
              <h4>{t("dont_have_an_account_yet")}</h4>
              <Link to="/register">{t("sign_up_for", { siteName: configuration.get("configData.site_name") })}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  login: state.users.login,
  register: state.users.register,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(LoginIndex));
