import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    ORDERS_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_START,
    CANCEL_ORDER_START,
    ORDERS_MORE_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
    FETCH_MORE_ORDER_PAYMENT_LIST_START,
    ORDER_LIST_FOR_SELLER_START,
    UPDATE_ORDER_STATUS_START,
    FETCH_MORE_ORDER_LIST_FOR_SELLER_START,
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    fetchOrderListSuccess,
    fetchOrderListFailure,
    fetchOrderPaymentListSuccess,
    fetchOrderPaymentListFailure,
    cancelOrderSuccess,
    cancelOrderFailure,
    ordersViewForOthersSuccess,
    ordersViewForOthersFailure,
    fetchOrderListStart,
    orderListForSellerStart,
    updateOrderStatusSuccess,
    updateOrderStatusFailure,
    orderListForSellerSuccess,
    orderListForSellerFailure,
} from "../actions/OrderAction";

function* fetchOrderListAPI(action) {
    try {
        const response = yield api.postMethod("orders_list", action.data);
        if (response.data.success) {
            yield put(fetchOrderListSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchOrderListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchOrderListFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchOrderPaymentListAPI(action) {
    try {
        const response = yield api.postMethod("order_payments_list", action.data);
        if (response.data.success) {
            yield put(fetchOrderPaymentListSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchOrderPaymentListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchOrderPaymentListFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* cancelOrderAPI(action) {
    try {
        const response = yield api.postMethod("orders_cancel", action.data);
        if (response.data.success) {
            yield put(cancelOrderSuccess(response.data.data));
            yield put(fetchOrderListStart());
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(cancelOrderFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(cancelOrderFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* ordersViewForOthersAPI(action) {
    try {
        const response = yield api.postMethod("orders_view_for_others", action.data);

        if (response.data.success) {
            yield put(ordersViewForOthersSuccess(response.data.data));
        } else {
            yield put(ordersViewForOthersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(ordersViewForOthersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* orderListForSellerAPI() {
    try {
        const response = yield api.postMethod("orders_list_for_seller");
        if (response.data.success) {
            yield put(orderListForSellerSuccess(response.data.data));
        } else {
            yield put(orderListForSellerFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(orderListForSellerFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* updateOrderStatusAPI(action) {
    try {
        const response = yield api.postMethod("orders_status_update", action.data);
        if (response.data.success) {
            yield put(updateOrderStatusSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(orderListForSellerStart());
        } else {
            yield put(updateOrderStatusFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(updateOrderStatusFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

export default function* OrderSaga() {
    yield all([
        yield takeLatest(ORDERS_LIST_START, fetchOrderListAPI),
        yield takeLatest(ORDERS_MORE_LIST_START, fetchOrderListAPI),
        yield takeLatest(FETCH_ORDER_PAYMENT_LIST_START, fetchOrderPaymentListAPI),
        yield takeLatest(FETCH_MORE_ORDER_PAYMENT_LIST_START, fetchOrderPaymentListAPI),
        yield takeLatest(CANCEL_ORDER_START, cancelOrderAPI),
        yield takeLatest(ORDERS_VIEW_FOR_OTHERS_START, ordersViewForOthersAPI),
        yield takeLatest(ORDER_LIST_FOR_SELLER_START, orderListForSellerAPI),
        yield takeLatest(FETCH_MORE_ORDER_LIST_FOR_SELLER_START, orderListForSellerAPI),
        yield takeLatest(UPDATE_ORDER_STATUS_START, updateOrderStatusAPI),
    ]);
}



