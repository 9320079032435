import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Row,Col, Container } from 'react-bootstrap';

const SingleProductLoader = () => {
  return (
    <>
      <div className="single-product-sec">
        <Container>
          <Row>
            <Col md={6}>
              <Skeleton count={1} height={400} />
              <Row>
                <Col md={4}>
                  <Skeleton count={1} height={96} />
                </Col>
                <Col md={4}>
                  <Skeleton count={1} height={96} />
                </Col>
                <Col md={4}>
                  <Skeleton count={1} height={96} />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Skeleton className="mb-3" count={1} height={30} width={40} />
              <Skeleton count={2} height={20} width={30} />
              <Skeleton count={1} height={20} width={200} />
              <Skeleton className="mb-3" count={2} height={20} width={100} />
              <Skeleton className="mb-3" count={2} height={20} width={150} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SingleProductLoader;