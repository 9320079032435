import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchOngoingLiveVideosStart,
  fetchMoreOngoingLiveVideosStart,
} from "../../store/actions/HomePageAction";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../Helper/NoDataFound";
import LiveVideoCard from "../Helper/LiveVideoCard";
import CategoryFollowLoader from "../Helper/CategoryFollowLoader";

const OnGoingLiveVideos = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchOngoingLiveVideosStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreOngoingLiveVideosStart({
        skip: props.onGoingLiveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  return (
    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
          <div className="new-card-head pt-8">
            <h3 className="sub-catdata">{t("live_shows")}</h3>
          </div>
          <div className="category-card-sec mt-4">
            {props.onGoingLiveVideos.loading ?
              < CategoryFollowLoader />
              :
              Object.keys(props.onGoingLiveVideos.data).length > 0 &&
                props.onGoingLiveVideos.data.live_videos.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.onGoingLiveVideos.data.live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.onGoingLiveVideos.data.live_videos.length <
                    props.onGoingLiveVideos.data.total_live_videos
                  }
                  loader={<CategoryFollowLoader />}
                >
                  <div className="category-card-box">
                    {props.onGoingLiveVideos.data.live_videos.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  onGoingLiveVideos: state.homepage.onGoingLiveVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(OnGoingLiveVideos));
