export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_STATIC_PAGE_START = "FETCH_STATIC_PAGE_START";
export const FETCH_STATIC_PAGE_SUCCESS = "FETCH_STATIC_PAGE_SUCCESS";
export const FETCH_STATIC_PAGE_FAILURE = "FETCH_STATIC_PAGE_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START = "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS = "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE = "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const VERIFY_FORGOT_PASSWORD_START = "VERIFY_FORGOT_PASSWORD_START";
export const VERIFY_FORGOT_PASSWORD_SUCCESS = "VERIFY_FORGOT_PASSWORD_SUCCESS";
export const VERIFY_FORGOT_PASSWORD_FAILURE = "VERIFY_FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VIEW_BANK_ACCOUNT_START = "VIEW_BANK_ACCOUNT_START"
export const VIEW_BANK_ACCOUNT_SUCCESS = "VIEW_BANK_ACCOUNT_SUCCESS"
export const VIEW_BANK_ACCOUNT_FAILURE = "VIEW_BANK_ACCOUNT_FAILURE"

export const FETCH_CATEGORIES_LIST_START = "FETCH_CATEGORIES_LIST_START";
export const FETCH_CATEGORIES_LIST_SUCCESS = "FETCH_CATEGORIES_LIST_SUCCESS";
export const FETCH_CATEGORIES_LIST_FAILURE = "FETCH_CATEGORIES_LIST_FAILURE";

export const FETCH_SUB_CATEGORIES_START = "FETCH_SUB_CATEGORIES_START";
export const FETCH_SUB_CATEGORIES_SUCCESS = "FETCH_SUB_CATEGORIES_SUCCESS";
export const FETCH_SUB_CATEGORIES_FAILURE = "FETCH_SUB_CATEGORIES_FAILURE";

export const USERS_SEARCH_START = "USERS_SEARCH_START";
export const USERS_SEARCH_SUCCESS = "USERS_SEARCH_SUCCESS";
export const USERS_SEARCH_FAILURE = "USERS_SEARCH_FAILURE";

export const FETCH_SINGLE_USER_DETAILS_START = "FETCH_SINGLE_USER_DETAILS_START";
export const FETCH_SINGLE_USER_DETAILS_SUCCESS = "FETCH_SINGLE_USER_DETAILS_SUCCESS";
export const FETCH_SINGLE_USER_DETAILS_FAILURE = "FETCH_SINGLE_USER_DETAILS_FAILURE";

export const BECOME_SELLER_START = "BECOME_SELLER_START";
export const BECOME_SELLER_SUCCESS = "BECOME_SELLER_SUCCESS";
export const BECOME_SELLER_FAILURE = "BECOME_SELLER_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const FETCH_FOLLOWING_CATEGORIES_LIST_START = "FETCH_FOLLOWING_CATEGORIES_LIST_START";
export const FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS = "FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS";
export const FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE = "FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE";

export const FETCH_SUB_CATEGORY_START = "FETCH_SUB_CATEGORY_START";
export const FETCH_SUB_CATEGORY_SUCCESS = "FETCH_SUB_CATEGORY_SUCCESS";
export const FETCH_SUB_CATEGORY_FAILURE = "FETCH_SUB_CATEGORY_FAILURE";

export const FETCH_SUB_CATEGORY_VIDEOS_START = "FETCH_SUB_CATEGORY_VIDEOS_START";
export const FETCH_SUB_CATEGORY_VIDEOS_SUCCESS = "FETCH_SUB_CATEGORY_VIDEOS_SUCCESS";
export const FETCH_SUB_CATEGORY_VIDEOS_FAILURE = "FETCH_SUB_CATEGORY_VIDEOS_FAILURE";
export const FETCH_MORE_SUB_CATEGORY_VIDEOS_START = "FETCH_MORE_SUB_CATEGORY_VIDEOS_START";

export const FETCH_ONGOING_LIVE_VIDEOS_START = "FETCH_ONGOING_LIVE_VIDEOS_START";
export const FETCH_ONGOING_LIVE_VIDEOS_SUCCESS = "FETCH_ONGOING_LIVE_VIDEOS_SUCCESS";
export const FETCH_ONGOING_LIVE_VIDEOS_FAILURE = "FETCH_ONGOING_LIVE_VIDEOS_FAILURE";
export const FETCH_MORE_ONGOING_LIVE_VIDEOS_START = "FETCH_MORE_ONGOING_LIVE_VIDEOS_START";

export const RECENT_CATEGORIES_START = "RECENT_CATEGORIES_START";
export const RECENT_CATEGORIES_SUCCESS = "RECENT_CATEGORIES_SUCCESS";
export const RECENT_CATEGORIES_FAILURE = "FRECENT_CATEGORIES_FAILURE";

export const FETCH_LIVE_VIDEOS_PRODUCTS_START = "FETCH_LIVE_VIDEOS_PRODUCTS_START";
export const FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS = "FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS";
export const FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE = "FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START = "FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START";

export const LIVE_VIDEO_BOOKMARK_SAVE_START = "LIVE_VIDEO_BOOKMARK_SAVE_START";
export const LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS = "LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS";
export const LIVE_VIDEO_BOOKMARK_SAVE_FAILURE = "LIVE_VIDEO_BOOKMARK_SAVE_FAILURE";

export const FETCH_BOOKMARKED_VIDEOS_START = "FECTH_BOOKMARKED_VIDEOS_START";
export const FETCH_BOOKMARKED_VIDEOS_SUCCESS = "FETCH_BOOKMARKED_VIDOES_SUCCESS";
export const FETCH_BOOKMARKED_VIDEOS_FAILURE = "FETCH_BOOKMARKED_VIDEOS_FAILURE";
export const FETCH_MORE_BOOKMARKED_VIDEOS_START = "FECTH_MORE_BOOKMARKED_VIDEOS_START";

export const ORDERS_LIST_START = "ORDERS_LIST_FOR_START";
export const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
export const ORDERS_LIST_FAILURE = "ORDERS_LIST_FAILURE";
export const ORDERS_MORE_LIST_START = "ORDERS_MORE_LIST_START";

export const FETCH_ORDER_PAYMENT_LIST_START = "FETCH_ORDER_PAYMENT_LIST_START";
export const FETCH_ORDER_PAYMENT_LIST_SUCCESS = "FETCH_ORDER_PAYMENT_LIST_SUCCESS";
export const FETCH_ORDER_PAYMENT_LIST_FAILURE = "FETCH_ORDER_PAYMENT_LIST_FAILURE";
export const FETCH_MORE_ORDER_PAYMENT_LIST_START = "FETCH_MORE_ORDER_PAYMENT_LIST_START";

export const CANCEL_ORDER_START = "CANCEL_ORDER_START";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const FETCH_USER_PRODUCTS_START = "FETCH_USER_PRODUCTS_START";
export const FETCH_USER_PRODUCTS_SUCCESS = "FETCH_USER_PRODUCTS_SUCCESS";
export const FETCH_USER_PRODUCTS_FAILURE = "FETCH_USER_PRODUCTS_FAILURE";

export const USER_PRODUCTS_SAVE_START = "USER_PRODUCTS_SAVE_START";
export const USER_PRODUCTS_SAVE_SUCCESS = "USER_PRODUCTS_SAVE_SUCCESS";
export const USER_PRODUCTS_SAVE_FAILURE = "USER_PRODUCTS_SAVE_FAILURE";

export const FETCH_PRODUCT_CATEGORIES_START = "FETCH_PRODUCT_CATEGORIES_START";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE = "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const FETCH_PRODUCT_SUB_CATEGORIES_START = "FETCH_PRODUCT_SUB_CATEGORIES_START";
export const FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS = "FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_SUB_CATEGORIES_FAILURE = "FETCH_PRODUCT_SUB_CATEGORIES_FAILURE";

export const FETCH_USER_SINGLE_PRODUCT_START = "FETCH_USER_SINGLE_PRODUCT_START";
export const FETCH_USER_SINGLE_PRODUCT_SUCCESS = "FETCH_USER_SINGLE_PRODUCT_SUCCESS";
export const FETCH_USER_SINGLE_PRODUCT_FAILURE = "FETCH_USER_SINGLE_PRODUCT_FAILURE";

export const FETCH_USER_PRODUCTS_FOR_OWNER_START = "FETCH_USER_PRODUCTS_FOR_OWNER_START";
export const FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS = "FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS";
export const FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE = "FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE";
export const FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START = "FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START";

export const DELETE_USER_PRODUCT_START = "DELETE_USER_PRODUCT_START";
export const DELETE_USER_PRODUCT_SUCCESS = "DELETE_USER_PRODUCT_SUCCESS";
export const DELETE_USER_PRODUCT_FAILURE = "DELETE_USER_PRODUCT_FAILURE";

export const FETCH_SINGLE_PRODUCT_ORDERS_START = "FETCH_SINGLE_PRODUCT_ORDERS_START";
export const FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS = "FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS";
export const FETCH_SINGLE_PRODUCT_ORDERS_FAILURE = "FETCH_SINGLE_PRODUCT_ORDERS_FAILURE";
export const FETCH_MORE_SINGLE_PRODUCT_ORDERS_START = "FETCH_MORE_SINGLE_PRODUCT_ORDERS_START";

export const USER_PRODUCT_VIEW_FOR_OTHERS_START = "USER_PRODUCT_VIEW_FOR_OTHERS_START";
export const USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS = "USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS";
export const USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE = "USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE";

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const GENERATE_STRIPE_PAYMENT_START = "GENERATE_STRIPE_PAYMENT_START";
export const GENERATE_STRIPE_PAYMENT_SUCCESS = "GENERATE_STRIPE_PAYMENT_SUCCESS";
export const GENERATE_STRIPE_PAYMENT_FAILURE = "GENERATE_STRIPE_PAYMENT_FAILURE";

export const PAYMENT_BY_STRIPE_START = "PAYMENT_BY_STRIPE_START";
export const PAYMENT_BY_STRIPE_SUCCESS = "PAYMENT_BY_STRIPE_SUCCESS";
export const PAYMENT_BY_STRIPE_FAILURE = "PAYMENT_BY_STRIPE_FAILURE";

export const ADD_MONEY_VIA_PAYPAL_START = "ADD_MONEY_VIA_PAYPAL_START";
export const ADD_MONEY_VIA_PAYPAL_SUCCESS = "ADD_MONEY_VIA_PAYPAL_SUCCESS";
export const ADD_MONEY_VIA_PAYPAL_FAILURE = "ADD_MONEY_VIA_PAYPAL_FAILURE";

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";
export const FETCH_MORE_ALL_TRANSACTION_START = "FETCH_MORE_ALL_TRANSACTION_START";

export const FETCH_LIVE_VIDEOS_HISTORY_START = "FETCH_LIVE_VIDEOS_HISTORY_START";
export const FETCH_LIVE_VIDEOS_HISTORY_SUCCESS = "FETCH_LIVE_VIDEOS_HISTORY_SUCCESS";
export const FETCH_LIVE_VIDEOS_HISTORY_FAILURE = "FETCH_LIVE_VIDEOS_HISTORY_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_HISTORY_START = "FETCH_MORE_LIVE_VIDEOS_HISTORY_START";

export const FETCH_LIVE_VIDEO_ORDERS_START = "FETCH_LIVE_VIDEO_ORDERS_START";
export const FETCH_LIVE_VIDEO_ORDERS_SUCCESS = "FETCH_LIVE_VIDEO_ORDERS_SUCCESS";
export const FETCH_LIVE_VIDEO_ORDERS_FAILURE = "FETCH_LIVE_VIDEO_ORDERS_FAILURE";
export const FETCH_MORE_LIVE_VIDEO_ORDERS_START = "FETCH_MORE_LIVE_VIDEO_ORDERS_START";

export const ORDERS_VIEW_FOR_OTHERS_START = "ORDERS_VIEW_FOR_OTHERS_START";
export const ORDERS_VIEW_FOR_OTHERS_SUCCESS = "ORDERS_VIEW_FOR_OTHERS_SUCCESS";
export const ORDERS_VIEW_FOR_OTHERS_FAILURE = "ORDERS_VIEW_FOR_OTHERS_FAILURE";

export const FETCH_CATEGORY_VIDEOS_START = "FETCH_CATEGORY_VIDEOS_START";
export const FETCH_CATEGORY_VIDEOS_SUCCESS = "FETCH_CATEGORY_VIDEOS_SUCCESS";
export const FETCH_CATEGORY_VIDEOS_FAILURE = "FETCH_CATEGORY_VIDEOS_FAILURE";
export const FETCH_MORE_CATEGORY_VIDEOS_START = "FETCH_MORE_CATEGORY_VIDEOS_START";

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

export const ADD_USER_CARD_START = "ADD_USER_CARD_START";
export const ADD_USER_CARD_SUCCESS = "ADD_USER_CARD_SUCCESS";
export const ADD_USER_CARD_FAILURE = "ADD_USER_CARD_FAILURE";

// Live Video Call 
export const LIVE_VIDEO_CALL_BROADCAST_START = "LIVE_VIDEO_CALL_BROADCAST_START";
export const LIVE_VIDEO_CALL_BROADCAST_SUCCESS = "LIVE_VIDEO_CALL_BROADCAST_SUCCESS";
export const LIVE_VIDEO_CALL_BROADCAST_FAILURE = "LIVE_VIDEO_CALL_BROADCAST_FAILURE";

export const LIVE_VIDEOS_START_CALL_START = "LIVE_VIDEOS_START_CALL_START";
export const LIVE_VIDEOS_START_CALL_SUCCESS = "LIVE_VIDEOS_START_CALL_SUCCESS";
export const LIVE_VIDEOS_START_CALL_FAILURE = "LIVE_VIDEOS_START_CALL_FAILURE";

export const FETCH_LIVE_VIDEOS_START = "FETCH_LIVE_VIDEOS_START";
export const FETCH_LIVE_VIDEOS_SUCCESS = "FETCH_LIVE_VIDEOS_SUCCESS";
export const FETCH_LIVE_VIDEOS_FAILURE = "FETCH_LIVE_VIDEOS_FAILURE";

export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START";
export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS";
export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START = "FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START";

export const JOIN_LIVE_VIDEOS_START = "JOIN_LIVE_VIDEOS_START";
export const JOIN_LIVE_VIDEOS_SUCCESS = "JOIN_LIVE_VIDEOS_SUCCESS";
export const JOIN_LIVE_VIDEOS_FAILURE = "JOIN_LIVE_VIDEOS_FAILURE";

export const FETCH_SINGLE_LIVE_VIDEOS_START = "FETCH_SINGLE_LIVE_VIDEOS_START";
export const FETCH_SINGLE_LIVE_VIDEOS_SUCCESS = "FETCH_SINGLE_LIVE_VIDEOS_SUCCESS";
export const FETCH_SINGLE_LIVE_VIDEOS_FAILURE = "FETCH_SINGLE_LIVE_VIDEOS_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_START = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_START";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE";

export const LIVE_VIDEOS_VIEWER_UPDATE_START = "LIVE_VIDEOS_VIEWER_UPDATE_START";
export const LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS = "LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS";
export const LIVE_VIDEOS_VIEWER_UPDATE_FAILURE = "LIVE_VIDEOS_VIEWER_UPDATE_FAILURE";

export const LIVE_VIDEOS_END_START = "LIVE_VIDEOS_END_START";
export const LIVE_VIDEOS_END_SUCCESS = "LIVE_VIDEOS_END_SUCCESS";
export const LIVE_VIDEOS_END_FAILURE = "LIVE_VIDEOS_END_FAILURE";

// LookUp Actions 
export const FETCH_HOME_FEATURED_SELLERS_START = "FETCH_HOME_FEATURED_SELLERS";
export const FETCH_HOME_FEATURED_SELLERS_SUCCESS = "FETCH_HOME_FEATURED_SELLERS_SUCCESS";
export const FETCH_HOME_FEATURED_SELLERS_FAILURE = "FETCH_HOME_FEATURED_SELLERS";

//Categories Actions
export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FOLLOW_CATEGORIES_START = "FOLLOW_CATEGORIES_START";
export const FOLLOW_CATEGORIES_SUCCESS = "FOLLOW_CATEGORIES_SUCCESS";
export const FOLLOW_CATEGORIES_FAILURE = "FOLLOW_CATEGORIES_FAILURE";

export const FETCH_HOME_CATEGORIES_START = "FETCH_HOME_CATEGORIES_START";
export const FETCH_HOME_CATEGORIES_SUCCESS = "FETCH_HOME_CATEGORIES_SUCCESS";
export const FETCH_HOME_CATEGORIES_FAILURE = "FETCH_HOME_CATEGORIES_FAILURE";

// Single Profile Actions
export const FETCH_SINGLE_USER_PROFILE_START = "FETCH_SINGLE_USER_PROFILE_START";
export const FETCH_SINGLE_USER_PROFILE_SUCCESS = "FETCH_SINGLE_USER_PROFILE_SUCCESS";
export const FETCH_SINGLE_USER_PROFILE_FAILURE = "FETCH_SINGLE_USER_PROFILE_FAILURE";

export const FETCH_LIVE_VIDEO_MESSAGE_START = "FETCH_LIVE_VIDEO_MESSAGE_START";
export const FETCH_LIVE_VIDEO_MESSAGE_SUCCESS = "FETCH_LIVE_VIDEO_MESSAGE_SUCCESS";
export const FETCH_LIVE_VIDEO_MESSAGE_FAILURE = "FETCH_LIVE_VIDEO_MESSAGE_FAILURE";
export const ADD_LIVE_VIDEO_MESSAGE_CONTENT = "ADD_LIVE_VIDEO_MESSAGE_CONTENT";

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";


export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";
export const FETCH_MORE_WITHDRAWALS_START = "FETCH_MORE_WITHDRAWALS_START";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS = "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE = "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const ORDER_LIST_FOR_SELLER_START = "ORDER_LIST_FOR_SELLER_START";
export const ORDER_LIST_FOR_SELLER_SUCCESS = "ORDER_LIST_FOR_SELLER_SUCCESS";
export const ORDER_LIST_FOR_SELLER_FAILURE = "ORDER_LIST_FOR_SELLER_FAILURE";
export const FETCH_MORE_ORDER_LIST_FOR_SELLER_START = "FETCH_MORE_ORDER_LIST_FOR_SELLER_START";

export const UPDATE_ORDER_STATUS_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const FETCH_DELIVERY_ADDRESS_START = "FETCH_DELIVERY_ADDRESS_START";
export const FETCH_DELIVERY_ADDRESS_SUCCESS = "FETCH_DELIVERY_ADDRESS_SUCCESS";
export const FETCH_DELIVERY_ADDRESS_FAILURE = "FETCH_DELIVERY_ADDRESS_FAILURE";

export const SAVE_DELIVERY_ADDRESS_START = "SAVE_DELIVERY_ADDRESS_START";
export const SAVE_DELIVERY_ADDRESS_SUCCESS = "SAVE_DELIVERY_ADDRESS_SUCCESS";
export const SAVE_DELIVERY_ADDRESS_FAILURE = "SAVE_DELIVERY_ADDRESS_FAILURE";

export const DELETE_DELIVERY_ADDRESS_START = "DELETE_DELIVERY_ADDRESS_START";
export const DELETE_DELIVERY_ADDRESS_SUCCESS = "DELETE_DELIVERY_ADDRESS_SUCCESS";
export const DELETE_DELIVERY_ADDRESS_FAILURE = "DELETE_DELIVERY_ADDRESS_FAILURE";

export const SET_DEFAULT_DELIVERY_ADDRESS_START = "SET_DEFAULT_DELIVERY_ADDRESS_START";
export const SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS = "SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS";
export const SET_DEFAULT_DELIVERY_ADDRESS_FAILURE = "SET_DEFAULT_DELIVERY_ADDRESS_FAILURE";

export const VIEW_DELIVERY_ADDRESS_START = "VIEW_DELIVERY_ADDRESS_START";
export const VIEW_DELIVERY_ADDRESS_SUCCESS = "VIEW_DELIVERY_ADDRESS_SUCCESS";
export const VIEW_DELIVERY_ADDRESS_FAILURE = "VIEW_DELIVERY_ADDRESS_FAILURE";

export const FOLLOW_USER_START = "FOLLOW_USER_START";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAILURE = "FOLLOW_USER_FAILURE";

export const UNFOLLOW_USER_START = "UNFOLLOW_USER_START";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAILURE = "UNFOLLOW_USER_FAILURE";

export const FETCH_FOLLOWERS_START = "FETCH_FOLLOWERS_START";
export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";
export const FETCH_FOLLOWERS_FAILURE = "FETCH_FOLLOWERS_FAILURE";
export const FETCH_MORE_FOLLOWERS_START = "FETCH_MORE_FOLLOWERS_START";

export const FETCH_ACTIVE_FOLLOWERS_START = "FETCH_ACTIVE_FOLLOWERS_START";
export const FETCH_ACTIVE_FOLLOWERS_SUCCESS = "FETCH_ACTIVE_FOLLOWERS_SUCCESS";
export const FETCH_ACTIVE_FOLLOWERS_FAILURE = "FETCH_ACTIVE_FOLLOWERS_FAILURE";
export const FETCH_MORE_ACTIVE_FOLLOWERS_START = "FETCH_MORE_ACTIVE_FOLLOWERS_START";


export const FETCH_FOLLOWING_START = "FETCH_FOLLOWING_START";
export const FETCH_FOLLOWING_SUCCESS = "FETCH_FOLLOWING_SUCCESS";
export const FETCH_FOLLOWING_FAILURE = "FETCH_FOLLOWING_FAILURE";
export const FETCH_MORE_FOLLOWING_START = "FETCH_MORE_FOLLOWING_START";

export const FETCH_ACTIVE_FOLLOWING_START = "FETCH_ACTIVE_FOLLOWING_START";
export const FETCH_ACTIVE_FOLLOWING_SUCCESS = "FETCH_ACTIVE_FOLLOWING_SUCCESS";
export const FETCH_ACTIVE_FOLLOWING_FAILURE = "FETCH_ACTIVE_FOLLOWING_FAILURE";
export const FETCH_MORE_ACTIVE_FOLLOWING_START = "FETCH_MORE_ACTIVE_FOLLOWING_START";

export const SAVE_BLOCK_USER_START = "SAVE_BLOCK_USER_START";
export const SAVE_BLOCK_USER_SUCCESS = "SAVE_BLOCK_USER_SUCCESS";
export const SAVE_BLOCK_USER_FAILURE = "SAVE_BLOCK_USER_FAILURE";

export const FETCH_BLOCK_USERS_START = "FETCH_BLOCK_USERS_START";
export const FETCH_BLOCK_USERS_SUCCESS = "FETCH_BLOCK_USERS_SUCCESS";
export const FETCH_BLOCK_USERS_FAILURE = "FETCH_BLOCK_USERS_FAILURE";
export const FETCH_MORE_BLOCK_USERS_START = "FETCH_MORE_BLOCK_USERS_START";

export const FETCH_LISTS_DETAILS_START = "FETCH_LISTS_DETAILS_START";
export const FETCH_LISTS_DETAILS_SUCCESS = "FETCH_LISTS_DETAILS_SUCCESS";
export const FETCH_LISTS_DETAILS_FAILURE = "FETCH_LISTS_DETAILS_FAILURE";

export const FETCH_CATEGORIES_FOLLOW_START = "FETCH_CATEGORIES_FOLLOW_START";
export const FETCH_CATEGORIES_FOLLOW_SUCCESS = "FETCH_CATEGORIES_FOLLOW_SUCCESS";
export const FETCH_CATEGORIES_FOLLOW_FAILURE = "FETCH_CATEGORIES_FOLLOW_FAILURE";
export const FETCH_MORE_CATEGORIES_FOLLOW_START = "FETCH_MORE_CATEGORIES_FOLLOW_START";

export const FOLLOW_SUB_CATEGORIES_START = "FOLLOW_SUB_CATEGORIES_START";
export const FOLLOW_SUB_CATEGORIES_SUCCESS = "FOLLOW_SUB_CATEGORIES_SUCCESS";
export const FOLLOW_SUB_CATEGORIES_FAILURE = "FOLLOW_SUB_CATEGORIES_FAILURE";