import React, { useState, useEffect } from "react";
import { Col, Container, Form, Image, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  saveDeliveryAddressStart,
  viewDeliveryAddressStart
} from "../../store/actions/DeliveryAddressAction";
import Skeleton from "react-loading-skeleton";
import { useParams } from 'react-router-dom';

const EditDeliveryAddress = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const params = useParams();

  const addbillingSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    address: Yup.string().required(t("address_is_required")),
    state: Yup.string().required(t("state_is_required")),
    pincode: Yup.string().required(t("pincode_is_required"))
  })

  useEffect(() => {
    props.dispatch(
      viewDeliveryAddressStart({
        delivery_address_unique_id: params.delivery_address_unique_id,
      })
    );
  }, [params.delivery_address_unique_id]);

  useEffect(() => {
    if (!skipRender && !props.deliveryAddressSave.loading &&
      Object.keys(props.deliveryAddressSave.data).length > 0) {
      navigate("/delivery-address");
    }
    setSkipRender(false);
  }, [props.deliveryAddressSave]);

  const handleSubmit = (values) => {
    props.dispatch(saveDeliveryAddressStart(values));
  }


  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("edit_delivery_address")}</h3>
            </div>
          </div>
          <div className="add-bank-sec">
            {props.viewDeliveryAddress.loading ?
              <div className="add-bank-details">
                <Row>
                  <Col md={6}>
                    <Skeleton className="mb-4" count={3} height={50} width={450} />
                  </Col>
                  <Col md={6}>
                    <Skeleton className="mb-4" count={3} height={50} width={450} />
                  </Col>
                </Row>
              </div>
              :
              <Formik
                initialValues={{
                  name: props.viewDeliveryAddress.data.delivery_address.name,
                  address: props.viewDeliveryAddress.data.delivery_address.address,
                  landmark: props.viewDeliveryAddress.data.delivery_address.landmark,
                  state: props.viewDeliveryAddress.data.delivery_address.state,
                  pincode: props.viewDeliveryAddress.data.delivery_address.pincode,
                  contact_number: props.viewDeliveryAddress.data.delivery_address.contact_number,
                }}
                validationSchema={addbillingSchema}
                onSubmit={(values => handleSubmit(values))}
              >
                <FORM>
                  <div className="add-bank-details">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("name")}: (*)</Form.Label>
                          <Field
                            type="text"
                            name="name"
                            placeholder={t("name")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("address")}: (*)</Form.Label>
                          <Field
                            type="text"
                            name="address"
                            placeholder={t("address")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("landmark")}: ({t("optional")})</Form.Label>
                          <Field
                            type="text"
                            name="landmark"
                            placeholder={t("landmark")}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("state")}: (*)</Form.Label>
                          <Field
                            type="text"
                            name="state"
                            placeholder={t("state")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="state"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("pincode")}(*)</Form.Label>
                          <Field
                            type="number"
                            name="pincode"
                            placeholder={t("pincode")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="pincode"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("contact_number")}</Form.Label>
                          <Field
                            type="number"
                            name="contact_number"
                            placeholder={t("contact_number")}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="submit-btn">
                      <Button type="submit"
                        className="default-btn"
                        disabled={props.deliveryAddressSave.buttonDisable
                        }
                      >
                        {props.deliveryAddressSave.loadingButtonContent != null ?
                          props.deliveryAddressSave.loadingButtonContent :
                          t("submit")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              </Formik>
            }
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  viewDeliveryAddress: state.deliveryAddress.viewDeliveryAddress,
  deliveryAddressSave: state.deliveryAddress.deliveryAddressSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(EditDeliveryAddress));

