import React, { useState, useEffect } from 'react';
import {
  Form,
  Modal,
  InputGroup,
  FormControl,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import { withTranslation, useTranslation } from 'react-multi-lang';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import { paymentByStripeStart } from '../../store/actions/WalletAction';
import { fetchWalletDetailsStart } from '../../store/actions/WalletAction';
import { fetchAllTransactionStart } from '../../store/actions/TransactionAction';

const StripePaymentSec = (props) => {
  const t = useTranslation("");

  const stripe = useStripe();
  const elements = useElements();
  const [skipRender, setSkipRender] = useState(true);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t("payment_succeeded"));
          break;
        case "processing":
          setMessage(t("payment_processing"));
          break;
        case "requires_payment_method":
          setMessage(t("payment_failed"));
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, ...response } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
      },
    });
    if (!error) {
      props.dispatch(paymentByStripeStart({
        amount: response.paymentIntent.amount,
        payment_id: response.paymentIntent.id,
      }));
    }
    if (error)
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage(t("an_unexpected_error_occurred"));
      }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  useEffect(() => {
    if (!skipRender &&
      !props.paymentByStripe.loading &&
      Object.keys(props.paymentByStripe.data).length > 0) {
      props.dispatch(fetchWalletDetailsStart());
      props.dispatch(fetchAllTransactionStart());
      props.closePaymentAddCardModal();
    }
    setSkipRender(false);
  }, [props.paymentByStripe]);

  return (
    <Modal
      className="modal-dialog-center payment-add-card-modal"
      size="md"
      centered
      show={true}
      onHide={props.closePaymentAddCardModal}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>{t("add_card")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} md={12}>
              <Form className="mt-3">
                <LinkAuthenticationElement
                  id="link-authentication-element"
                  className="mb-2"
                />
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <div className="d-flex justify-content-center mt-5">
                  <Button
                    disabled={props.paymentByStripe.buttonDisable}
                    id="submit"
                    type="submit"
                    className="default-btn"
                    onClick={handleSubmit}
                  >
                    {props.paymentByStripe.buttonDisable ? t("processing") : t("pay_now")}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>
  )
}

const mapStateToPros = (state) => ({
  paymentByStripe: state.wallet.paymentByStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)
  (withTranslation(StripePaymentSec));
