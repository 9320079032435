import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useParams,Link } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchUserSingleProductStart,
  fetchProductCategoriesStart,
  userProductsSaveStart,
} from '../../store/actions/ProductsAction';
import Skeleton from 'react-loading-skeleton';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { fetchSubCategoriesStart } from '../../store/actions/HomePageAction';
import { useDispatch } from 'react-redux';

const DetectCategories = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.category_id) {
      dispatch(fetchSubCategoriesStart({ category_id: values.category_id }));
    }
  }, [values.category_id]);
}

const EditProduct = (props) => {

  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);

  const productSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    quantity: Yup.string().required(t("quantity_is_required")),
    price: Yup.string().required(t("price_is_required")),
    category_id: Yup.string().required(t("category_id_is_required")),
    sub_category_id: Yup.string().required(t("sub_category_id_is_required")),
  })

  useEffect(() => {
    props.dispatch(fetchUserSingleProductStart({
      user_product_id: params.user_product_id,
    }));
    props.dispatch(fetchProductCategoriesStart());
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(userProductsSaveStart({
      picture: files[0],
      user_product_id: params.user_product_id,
      ...values,
    }));
  };

  useEffect(() => {
    if (!skipRender && !props.productSave.loading && Object.keys(props.productSave.data).length > 0) {
      navigate(`/single-product/` + props.productSave.data.unique_id);
    }
    setSkipRender(false);
  }, [props.productSave]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    }
  });

  const images = files.map(file => (
    <Image
      className="product-preview"
      key={file.name}
      src={file.preview}
      alt="image-preview"
       />
  ));

  return (
    <>
      <div className="add-product-sec">
        <Container>
          {props.productView.loading ?
            <Row className="mt-4">
              <Col md={6}>
                <Skeleton className='mb-5' count={5} height={50} />

              </Col>
              <Col md={6}>
                <Skeleton className='mb-4' count={1} height={50} />
                <Skeleton className='mb-4' count={1} height={250} />
                <Skeleton className='mb-4' count={1} height={100} />
              </Col>
            </Row>
            :
            Object.keys(props.productView.data).length > 0 ?
              <Formik
                initialValues={{
                  name: props.productView.data.user_product.name,
                  quantity: props.productView.data.user_product.quantity,
                  price: props.productView.data.user_product.price,
                  category_id: props.productView.data.user_product.category_id,
                  sub_category_id: props.productView.data.user_product.sub_category_id,
                  description: props.productView.data.user_product.description,
                }}
                validationSchema={productSchema}
                onSubmit={handleSubmit}
              >
                <FORM className="mt-4">
                <div className="single-page-header">
                <div className="single-page-title">
                <Link to="#" onClick={() => navigate(-1)}>
                    <Image src={window.location.origin + "/images/back.svg"} />
                  </Link>
                  <h3>{t("edit_product")}</h3>
                </div>
              </div>
                  <Row>
                    <Col md={6}>
                      <div className="border-right-divider add-product-form-sec">
                        <Form.Group className="mb-4">
                          <Form.Label>{t("name")}*</Form.Label>
                          <Field
                            type="text"
                            placeholder={t("name")}
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("quantity")}*</Form.Label>
                          <Field
                            type="number"
                            placeholder={t("quantity")}
                            name="quantity"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="quantity"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("price")}*</Form.Label>
                          <Field
                            type="number"
                            min="1"
                            placeholder={t("price")}
                            name="price"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="price"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("category")}*</Form.Label>
                          <Field
                            as="select"
                            className="form-control"
                            name="category_id"
                          >
                            <option value="">{t("select_category")}</option>
                            {props.categories.loading ?
                              <Skeleton count={1} height={40} />
                              :
                              Object.keys(props.categories.data).length > 0 &&
                                props.categories.data.product_categories.length > 0
                                ?
                                props.categories.data.product_categories.map((category, index) => (
                                  <option
                                    value={category.category_id}
                                    key={index}
                                  >{category.name}</option>
                                ))
                                :
                                null
                            }
                          </Field>
                          <DetectCategories />
                          <ErrorMessage
                            name="category_id"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("sub_category")}*</Form.Label>
                          <Field
                            as="select"
                            className="form-control"
                            name="sub_category_id"
                          >
                            <option value="">{t("select_sub_category")}</option>
                            {props.subCategories.loading ?
                            <Skeleton count={1} />
                            :
                            Object.keys(props.subCategories.data).length > 0 &&
                              props.subCategories.data.sub_categories.length > 0 ?
                              props.subCategories.data.sub_categories.map((sub_category) => (
                                <option value={sub_category.sub_category_id}>{sub_category.name}</option>
                              ))
                              :
                              null
                          }
                          </Field>
                          <ErrorMessage
                            name="sub_category_id"
                            component={"div"}
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="add-product-upload-file-sec">
                        <Form.Label>{t("upload_product_image")}</Form.Label>
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()} />
                          <div className="add-product-upload-box">
                            <Image src={window.location.origin + "/images/upload-icon.svg"} className="upload-icon" />
                            <p>{t("select_a_image")}</p>
                          </div>
                          <div>
                           {images}
                          </div>
                          <div className="product-img-notes">
                            {t("please_upload_jpg")}
                          </div>
                        </div>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("description")}</Form.Label>
                          <Field
                            as="textarea"
                            rows={3}
                            name="description"
                            className="height-auto form-control"
                            placeholder={t("description")}
                          />
                        </Form.Group>
                        <div className="add-product-btn-sec text-center">
                          <Button
                            type="submit"
                            className="add-product-btn"
                            disabled={props.productSave.buttonDisable}
                          >
                            {props.productSave.loadingButtonContent !== null ?
                              props.productSave.loadingButtonContent :
                              t("add")
                            }
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </FORM>
              </Formik>
              :
              null}
        </Container>
      </div>

    </>
  )
}

const mapStateToPros = (state) => ({
  productView: state.userProducts.productView,
  categories: state.userProducts.productCategories,
  subCategories: state.homepage.subCategories,
  productSave: state.userProducts.productSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(EditProduct));