import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FollowingTabSec from "./FollowingTabSec";
import FollowingActiveSec from "./FollowingActiveSec";
import FollowingAllSec from "./FollowingAllSec";
import "../Fans/Follow.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchActiveFollowingStart,
  fetchFollowingStart,
} from "../../store/actions/FollowAction";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Tab } from "react-bootstrap";

const FollowingIndex = (props) => {

  const t = useTranslation();
  const [activeSec, setActiveSec] = useState("active-sec");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeSec === "active-sec") {
      props.dispatch(fetchActiveFollowingStart({
        skip: 0,
        take: 12,
      }));
    } else if ("all") {
      props.dispatch(fetchFollowingStart({
        skip: 0,
        take: 12,
      }));
    }
  }, [activeSec]);

  return (
    <>
      <div className="lists">
        <Container>
          <Row>
            <Col sm={12} md={12} xs={12}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left">
                    <h3>
                      <Link
                        to="#"
                        className="bookmarkes-list"
                        onClick={() => navigate(-1)}
                      >
                        <Image src={window.location.origin + "/images/backs.svg"}
                          className="svg-clone"
                        />
                        {t("following")}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="listing-tab">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <FollowingTabSec
                    activeSec={activeSec}
                    setActiveSec={setActiveSec}
                  />
                  <div className="tab-content tabs">
                    {activeSec ==="active-sec" &&
                      <FollowingActiveSec
                        following={props.activeFollowing}
                      />
                    }
                    {activeSec === "all" &&
                      <FollowingAllSec
                        following={props.following}
                      />
                    }
                  </div>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  following: state.follow.following,
  activeFollowing: state.follow.activeFollowing,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros, mapDispatchToProps)(withTranslation(FollowingIndex));
