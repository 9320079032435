import React, { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Nav,
  Tab,
  InputGroup,
  Image,
  Form,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { fetchLiveVideosProductsStart } from '../../store/actions/LiveVideoAction';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../Helper/NoDataFound';

const ProductList = (props) => {

  const params = useParams();
  const t = useTranslation();
  const [search, setSearch] = useState("");


  const handleChange = (event) => {
    setSearch(event.target.value);
    props.dispatch(fetchLiveVideosProductsStart({
      live_video_unique_id: params.live_video_unique_id,
      search_key: event.target.value
    }));
  };

  const clearItem = () => {
    setSearch("");
    props.dispatch(fetchLiveVideosProductsStart({
      live_video_unique_id: params.live_video_unique_id,
    }));
  }

  return (
    <Col md={3} className="p-0">
      <div className="live-stream-left-sec">
        <div className="live-strem-title">
          <h3>{t("product_lists")}</h3>
        </div>
        <div className="live-stream-tab">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="available"
          >
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="available"
                      onClick={(event) =>
                        props.setActiveSection(event, "available")
                      }
                    >
                      {t("available")}
                      <span className="product-opt">
                        {props.liveVideosProducts.loading
                          ?
                          0
                          : props.liveVideosProducts.data.products_available
                        }
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="sold"
                      onClick={(event) =>
                        props.setActiveSection(event, "sold")
                      }
                    >
                      {t("sold")}
                      <span className="product-opt">
                        {props.liveVideosProducts.loading
                          ?
                          0
                          : props.liveVideosProducts.data.products_sold
                        }
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  {props.liveVideo.data.is_owner === 1 && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="purchased"
                        onClick={(event) =>
                          props.setActiveSection(event, "purchased")
                        }
                      >
                        {t("purchased")}
                        <span className="product-opt">
                          {props.liveVideosProducts.loading
                            ?
                            0
                            : props.liveVideosProducts.data.products_purchased
                          }
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
              <Col sm={12}>
                <Form className="live-streaming-form">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <Image
                        src={
                          window.location.origin +
                          "/images/search.svg"
                        }
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={search}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon1"
                      className="search-close"
                      style={{
                        border: "0",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {search != "" &&
                        <Image
                          onClick={clearItem}
                          src={window.location.origin + "/images/closes.svg"}
                        />
                      }
                    </InputGroup.Text>
                  </InputGroup>
                </Form>
                <div
                  className="scroll-comment-sec"
                  id="commentScrollDiv"
                  style={{
                    maxHeight: "calc(100vh - 350px)",
                    minHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  < Tab.Content >
                    <Tab.Pane eventKey="available">
                      <div className="live-streaming-tab-details">
                        {props.liveVideosProducts.loading ?
                          <div className="live-streaming-box">
                            <Skeleton count={1} height={110} width={"100%"} />
                          </div>
                          :
                          props.liveVideosProducts.data.live_video_products.length > 0 ?
                            <div className="live-streaming-box">
                              {props.liveVideosProducts.data.live_video_products.map(
                                (livevideoproduct) =>

                                  <div className="live-streaming-card">
                                    <div className="live-streaming-card-img">
                                      <Image
                                        src={livevideoproduct.picture}
                                      />
                                    </div>
                                    <div className="live-streaming-card-details">
                                      <h5>{livevideoproduct.name}</h5>
                                      <p>{livevideoproduct.category_name}</p>
                                      <h3>{livevideoproduct.price_formatted}</h3>
                                    </div>
                                  </div>

                              )}
                            </div>
                            :
                            <NoDataFound />
                        }
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sold">
                      <div className="live-streaming-tab-details">
                        {props.liveVideosProducts.loading ?
                          <div className="live-streaming-box">
                            <Skeleton count={1} height={110} width={"100%"} />
                          </div>
                          : Object.keys(props.liveVideosProducts.data).length > 0 &&
                            props.liveVideosProducts.data.live_video_products.length > 0 ?
                            <div className="live-streaming-box">
                              {props.liveVideosProducts.data.live_video_products.map(
                                (product) =>
                                  !product.is_outofstock ? (
                                    <div className="live-streaming-card">
                                      <div className="live-streaming-card-img">
                                        <Image
                                          src={product.picture}
                                        />
                                      </div>
                                      <div className="live-streaming-card-details">
                                        <h5>{product.name}</h5>
                                        <p>{product.category_name}</p>
                                        <h3>{product.price_formatted}</h3>
                                      </div>
                                    </div>
                                  )
                                    :
                                    null
                              )}
                            </div>
                            :
                            <NoDataFound />
                        }
                      </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="purchased">
                      <div className="live-streaming-tab-details">
                        {props.liveVideosProducts.loading ?
                          <div className="live-streaming-box">
                            <Skeleton count={1} height={110} width={"100%"} />
                          </div>
                          : props.liveVideosProducts.data.purchased_products &&
                            props.liveVideosProducts.data.purchased_products.length > 0 ?
                            <div className="live-streaming-box">
                              {props.liveVideosProducts.data.purchased_products.map(
                                (purchased_product) =>
                                (
                                  <div className="live-streaming-card">
                                    <div className="live-streaming-card-img">
                                      <Image
                                        src={purchased_product.picture}
                                      />
                                    </div>
                                    <div className="live-streaming-card-details">
                                      <h5>{purchased_product.name}</h5>
                                      <p>{purchased_product.category_name}</p>
                                      <h3>{purchased_product.price_formatted}</h3>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            :
                            <NoDataFound />
                        }
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col >
            </Row>
          </Tab.Container>
        </div>
      </div>
    </Col>
  )
}


const mapStateToProps = (state) => ({
  liveVideosProducts: state.liveVideo.liveVideosProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ProductList));
