import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "./LiveVideo.css";
import { Link } from "react-router-dom";
import {
  fetchLiveVideosScheduledOwnerStart,
  fetchMoreLiveVideosScheduledOwnerStart,
} from '../../store/actions/LiveVideoAction';
import NoDataFound from '../Helper/NoDataFound';
import { useTranslation, withTranslation } from 'react-multi-lang';
import LiveDataCard from "./LiveDataCard";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const UserLiveVideosIndex = (props) => {

  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchLiveVideosScheduledOwnerStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreLiveVideosScheduledOwnerStart({
      skip: props.liveVideos.data.live_video_upcomings.length,
      take: 12,
    }
    ));
  }

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="live-video-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("scheduled_live_videos")}</h3>
            </div>
            <div className="live-video-header-btn-sec">
              <Link to="/go-live" className="default-btn">
                {t("go_live")}
              </Link>
              <Link to="/live-videos-history" className="default-blue-btn">
                {t("live_history")}
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.liveVideos.loading ?
            <div className="video-list-sec">
              {[...Array(3)].map((i) => (
                <Skeleton count={1} width={300} height={350} />
              ))}
            </div>
            : Object.keys(props.liveVideos.data).length > 0 &&
              props.liveVideos.data.live_video_upcomings.length > 0 ?
              <InfiniteScroll
                dataLength={props.liveVideos.data.live_video_upcomings.length}
                next={fetchMoreData}
                hasMore={
                  props.liveVideos.data.live_video_upcomings.length <
                  props.liveVideos.data.total_live_video_upcomings}
                loader={<div className="video-list-sec">
                  {[...Array(4)].map((i) => (
                    <Skeleton count={1} width={300} height={350} />
                  ))}
                </div>}
              >
                <div className="video-list-sec">
                  {props.liveVideos.data.live_video_upcomings.map((video) => (
                    <LiveDataCard video={video} key={video.live_video_id} />
                  ))}
                </div>
              </InfiniteScroll>
              :
              <NoDataFound />
          }
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  liveVideos: state.liveVideo.liveVideosScheduledOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(UserLiveVideosIndex));