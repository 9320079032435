import React from 'react';
import { connect } from 'react-redux';
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-multi-lang';

const LiveDataCard = (props) => {
  const { video } = props;
  return (
    <>
      <div className="video-list-card">
        <div>
          <Link
            to={`/live-stream/${video.live_video_unique_id}`}
            className="btn"
            style={{ marginBottom: "1rem" }}
          >
            <div className="video-list-img-sec">
              <Image
                src={video.preview_file}
                className="video-list-img"
              />
            </div>
          </Link>
        </div>
        <div className="video-list-user-details">
          <div className="user-img-sec">
            <Image src={video.user_picture} className="user-img" />
          </div>
          <div className="video-list-user-info">

            <div className="video-list-data-type">
              <h4 className="title">{video.title}</h4>
              <h4 className="username">
                <Link to={`/${video.user_unique_id}`}>
                  @{video.user_displayname}
                </Link>
              </h4>
              <h4 className="description">
                {video.description}
              </h4>
              <h4 className="date">
                {video.formatted_schedule_time}
              </h4>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveDataCard));
