import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { liveVideoBookmarkSaveStart } from '../../store/actions/HomePageAction';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-multi-lang';

const LiveVideoCard = (props) => {

  const { live_video } = props;
  const navigate = useNavigate();

  const bookmarkVideo = (e, videoId) => {
    e.preventDefault();
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(liveVideoBookmarkSaveStart({
        live_video_id: videoId,
      }));
    } else {
      navigate("/login");
    }
  }
  return (
    <>
      <div className="category-card">
        <div className="category-card-img">
          <Link to={`/live-stream/${live_video.live_video_unique_id}`}>
            <Image
              src={live_video.preview_file}
              className="list-card-img"
            />
            <div className="category-card-view">
              <div className="category-card-icon">
                <Image src={window.location.origin + "/images/card/eye.svg"} />
                <p>{live_video.viewer_cnt}</p>
              </div>
              <div className="category-card-icon">
                <p>{live_video.total_bookmarks}</p>
                <div className="card-top-img" onClick={(e) => bookmarkVideo(e, live_video.live_video_id)}>
                  {live_video.is_video_bookmarked
                    ?
                    <Image
                      src={window.location.origin + "/images/card/bookmark-active.svg"}
                    />
                    :
                    <Image
                      src={window.location.origin + "/images/card/bookmark.svg"}
                    />
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="category-card-profile">
          <Link to={`/${live_video.username}`}>
            <Image src=
              {live_video.user_picture}
              className="cat-pro-pic"
            />
            <h5>{live_video.username}</h5>
          </Link>
        </div>
        <div className="category-card-content">
          <h4>
            {live_video.description}
          </h4>
          <Link to={`/live-stream/${live_video.live_video_unique_id}`}>{live_video.category_name}</Link>
        </div>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(LiveVideoCard));