import React from 'react'
import Skeleton from "react-loading-skeleton";

const HomeCategoryLoader = () => {
    return (
        <div className="category-card-box">
            {[...Array(5)].map((i) => (
                <div className="category-card">
                    <div className="category-card-img">
                        <Skeleton count={1} height={210} width={165} />
                    </div>
                    <div className="category-card-profile">
                        <>
                            <Skeleton count={1} width={100} />
                            <Skeleton count={1} width={150} />
                            <Skeleton count={1} width={100} />
                        </>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default HomeCategoryLoader;