import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Media,
  Image,
} from "react-bootstrap";
import "./Order.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  orderListForSellerStart,
  fetchMoreOrderPaymentListStart,
  cancelOrderStart,
  updateOrderStatusStart
} from "../../store/actions/OrderAction";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import { confirmAlert } from 'react-confirm-alert';
import TableOrderLoader from "../Helper/TableOrderLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const SellerOrderList = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(orderListForSellerStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreOrderPaymentListStart({
      skip: props.ordersListForOthers.data.orders.length,
      take: 12,
    }
    ));
  }


  const cancelOrderConfirmation = (order_id) => {
    confirmAlert({
      message: t("delete_product_confirmation"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => props.dispatch(cancelOrderStart({ order_id: order_id }))
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const updateOrderStatus = (order_id, status) => {
    props.dispatch(updateOrderStatusStart({ order_id: order_id, status: status }));
  };

  return (
    <>
      <div className="order-list-sec">
        <Container>
          <h2>{t("orders_list")}</h2>
          <Row>
            <Col md={12}>
              <div className="table-wrap">
                {props.ordersListForOthers.loading ?
                  <TableOrderLoader />
                  :
                  Object.keys(props.ordersListForOthers.data).length > 0 &&
                    props.ordersListForOthers.data.orders.length > 0 ?
                    <InfiniteScroll
                      dataLength={props.ordersListForOthers.data.orders.length}
                      next={fetchMoreData}
                      hasMore={
                        props.ordersListForOthers.data.orders.length <
                        props.ordersListForOthers.data.length}
                      loader={<TableOrderLoader />}
                    >
                      <Table responsive="md">
                        <thead className="thead-primary">
                          <tr>
                            <th>{t("products")}</th>
                            <th>{t("order_id")}</th>
                            <th>{t("shipping_address")}</th>
                            <th>{t("phone_number")}</th>
                            <th>{t("amount")}</th>
                            <th>{t("status")}</th>
                            <th className="text-center">{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.ordersListForOthers.data.orders.map(
                            (order, index) => (
                              <>
                                <tr className="alert" role="alert" key={index}>
                                  <td>
                                    <div className="order-list-flex">
                                      <div className="orderer-product">
                                        {order.order_product.map(
                                          (product, index) => (
                                            <>
                                              {product.user_product_details ? (
                                                <div
                                                  className={`order-list-product ${order.total_products > 1
                                                    ? "mb-3"
                                                    : ""
                                                    }`}
                                                  key={index}
                                                >
                                                  <div className="product-list-img-sec">
                                                    <Image
                                                      src={product.user_product_details.picture}
                                                      className="product-list-img"
                                                    />
                                                  </div>
                                                  <div className="email">
                                                    <span>{product.user_product_details.name}{" "}</span>
                                                    <span>{t("quantity")} :{" "}{product.quantity}</span>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="quantity">{order.unique_id}</td>
                                  {order.delivery_address ?
                                    <>
                                      <td className="address">
                                        {order.delivery_address.name}, <br />
                                        {order.delivery_address.landmark}, <br />
                                        {order.delivery_address.address}-
                                        {order.delivery_address.pincode} <br />
                                      </td>
                                      <td>{order.delivery_address.contact_number}</td>
                                    </>
                                    :
                                    <><td className="address"></td><td></td></>
                                  }
                                  <td className="quantity">
                                    {order.total_formatted}
                                  </td>
                                  <td>
                                    {order.order_status}
                                  </td>
                                  <td>
                                    <Link
                                      type="button"
                                      className="order-view-btn mr-3 w-100"
                                      to={`/order-view/${order.unique_id}`}
                                    >
                                      {t("view")}
                                    </Link>
                                    {order.order_btn_status.cancel_btn_status ?
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3 w-100"
                                        onClick={() => {
                                          cancelOrderConfirmation(
                                            order.id)
                                        }}
                                      >
                                        {t("cancel")}
                                      </Button>
                                      : ''}
                                    {order.order_btn_status.shipped_btn_status ?
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3 w-100"
                                        onClick={() => updateOrderStatus(order.id, 2)}
                                      >
                                        {t("mark_as_shipped")}
                                      </Button>
                                      : ''}
                                    {order.order_btn_status.delivered_btn_status ?
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={() => updateOrderStatus(order.id, 3)}
                                      >
                                        {t("mark_as_delivered")}
                                      </Button>
                                      : ''}
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                    :
                    <NoDataFound />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  ordersListForOthers: state.order.orderListForSeller,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SellerOrderList));