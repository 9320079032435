import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from 'react-redux';
import { fetchCategoriesFollowStart } from '../../store/actions/HomePageAction';
import HomeCategoryLoader from '../Helper/HomeCategoryLoader';
import LiveVideoCard from '../Helper/LiveVideoCard';

const CategoriesFollow = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchCategoriesFollowStart({
      take: 5,
    }));
  }, []);

  return (
    <div className="category-card-sec mt-4">
      {props.categoriesFollow.loading ?
        <HomeCategoryLoader />
        :
        Object.keys(props.categoriesFollow.data).length > 0 &&
          props.categoriesFollow.data.category_live_videos.length > 0 ?
          <>
            <div className="sub-title ">
              <h3>{t("categories_you_follow")}</h3>
              {props.categoriesFollow.data.category_live_videos.length > 4 ?
                <Link to="/categories-follow">{t("view_all")}</Link>
                :
                null
              }
            </div>
            <div className="category-card-box">
              {props.categoriesFollow.data.category_live_videos.map(
                (live_video) => (
                  <LiveVideoCard live_video={live_video} />
                ))}
            </div>
          </>
          :
          null
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  categoriesFollow: state.homepage.categoriesFollow,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(CategoriesFollow));
