import React from "react";
import {
  Container,
  Nav,
  Col,
  Tab,
  Row,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ProfileSidebar from "./ProfileSidebar";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchUserDetailsStart,
  updateUserDetailsStart,
  usernameValidationStart,
} from "../../store/actions/UserAction";
import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SomethingWentWrong from "../Helper/SomethingWentWrong";

const ProfileIndex = (props) => {
  const t = useTranslation();
  const [userName, setUserName] = useState("");
  const [isvalidUserName, setIsValidUserName] = useState(false);

  const profileSchema = Yup.object().shape({
    username: Yup.string().required(t("required")),
    is_online_status: Yup.string().required(t("required")),
    name: Yup.string().required(t("required")),
    gender: Yup.string().required(t("required")),
  });

  useEffect(() => {
    props.dispatch(fetchUserDetailsStart());
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(updateUserDetailsStart(values));
  };

  const options = [
    { value: "Show", label: "Show" },
    { value: "Hide", label: "Hide" },
  ];
  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const method = [
    { value: "Wallet", label: "Wallet" },
    { value: "Account", label: "Account" },
  ];

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return t("no_white_space_allowed");
      }
    } else {
      setIsValidUserName(false);
      return t("must_contain_4_characters");
    }
  };

  const customStyles = {
    ///.....
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(57,57,57,.07)",
      borderColor: "#e7e7e7",
      boxShadow: "none",
      height: "50px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "var(----active-color)",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
    ///.....
  };
  return (
    <>
      <div className="home-page-sec">
        <Container fluid className="p-0">
          <div className="new-category-sec">
            <div className="new-category-left-sec">
              <ProfileSidebar />
            </div>
            <div className="new-category-right-sec">
              <div className="profile-sidebar-right-sec">
                <div className="profile-sidebar-tab">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <div className="order-tab-img">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/profile/account.svg"
                                  }
                                />
                                <span>{t("personal_info")}</span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              <div className="order-tab-img">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/order/order.svg"
                                  }
                                />
                                <span>{t("social_media_links")}</span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="profile-sidebar-right-detail-sec">
                              <div className="profile-right-box">
                                <h3>{t("profile_information")}</h3>
                                <p>
                                  Keep your details current! Update your profile
                                  today for a better experience. Let your
                                  followers stay connected with the latest
                                  information about you!
                                </p>
                              </div>
                              <div className="profile-details-form">
                                {props.profile.loading ? (
                                  <Row>
                                    <Col md={6}>
                                      <Skeleton
                                        count={3}
                                        height={60}
                                        className="mb-3"
                                      />
                                      <Skeleton
                                        count={1}
                                        height={110}
                                        className="mt-3"
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Skeleton
                                        count={2}
                                        height={60}
                                        className="mb-3"
                                      />
                                      <div className="submit-btn">
                                        <Skeleton
                                          count={1}
                                          height={50}
                                          width={120}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : props.profile.data ? (
                                  <Formik
                                    initialValues={{
                                      username: props.profile.data.username,
                                      is_online_status:
                                        props.profile.data.is_online_status,
                                      name: props.profile.data.name,
                                      gender: props.profile.data.gender,
                                      default_payment_method:
                                        props.profile.data
                                          .default_payment_method,
                                      about: props.profile.data.about,
                                    }}
                                    validationSchema={profileSchema}
                                    onSubmit={(values) => handleSubmit(values)}
                                  >
                                    {({ errors, touched }) => (
                                      <FORM>
                                        <div className="add-bank-details">
                                          <Row>
                                            <Col md={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  {t("user_name")}: (*)
                                                </Form.Label>
                                                <Field
                                                  type="text"
                                                  placeholder={t("user_name")}
                                                  name="username"
                                                  className="form-control"
                                                  validate={
                                                    handleUsernameValidation
                                                  }
                                                  autoComplete="off"
                                                />
                                                <ErrorMessage
                                                  name="username"
                                                  component={"div"}
                                                  className="text-danger"
                                                />
                                                {props.profile.username !==
                                                  isvalidUserName &&
                                                props.validation.isInValid &&
                                                isvalidUserName ? (
                                                  <div class="text-danger">
                                                    {props.validation
                                                      .errorMessage ??
                                                      t(
                                                        "username_already_taken"
                                                      )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {props.validation.isValid &&
                                                isvalidUserName ? (
                                                  <div class="text-success">
                                                    {t("looks_good")}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  {t("online_status")} (*)
                                                </Form.Label>
                                                <Field
                                                  as="select"
                                                  className="form-control"
                                                  name="is_online_status"
                                                  placeholder={t(
                                                    "online_status"
                                                  )}
                                                >
                                                  <option value="show">
                                                    {" "}
                                                    {t("show")}
                                                  </option>
                                                  <option value="hide">
                                                    {" "}
                                                    {t("hide")}{" "}
                                                  </option>
                                                </Field>
                                                <ErrorMessage
                                                  name="is_online_status"
                                                  component={"div"}
                                                  className="text-danger"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  {t("display_name")}: (*)
                                                </Form.Label>
                                                <Field
                                                  type="text"
                                                  placeholder={t(
                                                    "display_name"
                                                  )}
                                                  name="name"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  component={"div"}
                                                  className="text-danger"
                                                  name="name"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Gender: (*)
                                                </Form.Label>
                                                <Field
                                                  as="select"
                                                  className="form-control"
                                                  name="gender"
                                                  placeholder={t("gender")}
                                                >
                                                  <option value="male">
                                                    {t("male")}{" "}
                                                  </option>
                                                  <option value="female">
                                                    {t("female")}
                                                  </option>
                                                </Field>
                                                <ErrorMessage
                                                  name="gender"
                                                  className="text-danger"
                                                  component={"div"}
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  {t("default_payment_method")}{" "}
                                                  : (*)
                                                </Form.Label>
                                                <Field
                                                  as="select"
                                                  className="form-control"
                                                  name="default_payment_method"
                                                  placeholder={t(
                                                    "default_payment_method"
                                                  )}
                                                >
                                                  <option value="account">
                                                    {t("account")}
                                                  </option>
                                                  <option value="wallet">
                                                    {t("wallet")}
                                                  </option>
                                                </Field>
                                                <ErrorMessage
                                                  name="default_payment_method"
                                                  className="text-danger"
                                                  component={"div"}
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}></Col>
                                            <Col md={6}>
                                              <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                              >
                                                <Form.Label>
                                                  {t("about")}
                                                </Form.Label>
                                                <Field
                                                  as="textarea"
                                                  rows={4}
                                                  name="about"
                                                  className="form-control"
                                                  placeholder={t("about")}
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <div className="submit-btn">
                                            <Button
                                              type="submit"
                                              className="default-btn"
                                              disabled={
                                                props.profileInputData
                                                  .buttonDisable
                                              }
                                            >
                                              {props.profileInputData
                                                .loadingButtonContent
                                                ? props.profileInputData
                                                    .loadingButtonContent
                                                : t("submit")}
                                            </Button>
                                          </div>
                                        </div>
                                      </FORM>
                                    )}
                                  </Formik>
                                ) : (
                                  <>
                                    <SomethingWentWrong />
                                    <div className="retry-btn">
                                      <Button
                                        type="submit"
                                        className="default-btn"
                                      >
                                        {t("retry")}
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="profile-sidebar-right-detail-sec">
                              {props.profile.loading ? (
                                <Row>
                                  <Col md={6}>
                                    <Skeleton
                                      count={4}
                                      height={60}
                                      className="mb-3"
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <Skeleton
                                      count={4}
                                      height={60}
                                      className="mb-3"
                                    />
                                    <div className="submit-btn">
                                      <Skeleton
                                        count={1}
                                        height={50}
                                        width={120}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <div className="profile-right-box">
                                    <h3>Social Media Links</h3>
                                    <p>
                                      Boost your online presence! Attach the
                                      links to your social media accounts and
                                      gain more traction. Keep your followers
                                      engaged and connected with your latest
                                      updates. Update your profile now for
                                      maximum impact!
                                    </p>
                                  </div>
                                  <div className="profile-details-form">
                                    {props.profile.data ? (
                                      <Formik
                                        initialValues={{
                                          website: props.profile.data.website,
                                          amazon_wishlist:
                                            props.profile.data.amazon_wishlist,
                                          instagram_link:
                                            props.profile.data.instagram_link,
                                          facebook_link:
                                            props.profile.data.facebook_link,
                                          twitter_link:
                                            props.profile.data.twitter_link,
                                          snapchat_link:
                                            props.profile.data.snapchat_link,
                                          linkedin_link:
                                            props.profile.data.linkedin_link,
                                          pinterest_link:
                                            props.profile.data.pinterest_link,
                                          youtube_link:
                                            props.profile.data.youtube_link,
                                          twitch_link:
                                            props.profile.data.twitch_link,
                                        }}
                                        onSubmit={(values) =>
                                          handleSubmit(values)
                                        }
                                      >
                                        {({ errors, touched }) => (
                                          <FORM>
                                            <div className="profile-social-link">
                                              <Row>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {" "}
                                                    {t("website_url")}{" "}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="website"
                                                      className="form-control"
                                                      placeholder={t(
                                                        "website_url"
                                                      )}
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/website.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("amazon_wishlist")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="amazon_wishlist"
                                                      placeholder={t(
                                                        "amazon_wishlist"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/amazon.png"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("instagram_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="instagram_link"
                                                      placeholder={t(
                                                        "instagram_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/instagram.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("facebook_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="facebook_link"
                                                      placeholder={t(
                                                        "facebook_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/facebook.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("twitter_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="twitter_link"
                                                      placeholder={t(
                                                        "twitter_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/twitter.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("snapchat_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="snapchat_link"
                                                      placeholder={t(
                                                        "snapchat_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/snapchat.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("linkedin_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="linkedin_link"
                                                      placeholder={t(
                                                        "linkedin_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/linkedin.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("pinterest_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="pinterest_link"
                                                      placeholder={t(
                                                        "pinterest_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/pinterest.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("youtube_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="youtube_link"
                                                      placeholder={t(
                                                        "youtube_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/youtube.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <Form.Label htmlFor="basic-url">
                                                    {t("twitch_link")}
                                                  </Form.Label>
                                                  <InputGroup className="mb-4">
                                                    <Field
                                                      type="text"
                                                      name="twitch_link"
                                                      placeholder={t(
                                                        "twitch_link"
                                                      )}
                                                      className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/images/profile/twitch.svg"
                                                        }
                                                      />
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </Col>
                                              </Row>
                                              <div className="submit-btn">
                                                <Button
                                                  type="submit"
                                                  className="default-btn"
                                                  disabled={
                                                    props.profileInputData
                                                      .buttonDisable
                                                  }
                                                >
                                                  {props.profileInputData
                                                    .loadingButtonContent !=
                                                  null
                                                    ? props.profileInputData
                                                        .loadingButtonContent
                                                    : t("submit")}
                                                </Button>
                                              </div>
                                            </div>
                                          </FORM>
                                        )}
                                      </Formik>
                                    ) : (
                                      <>
                                        <SomethingWentWrong />
                                        <div className="retry-btn">
                                          <Button
                                            type="submit"
                                            className="default-btn"
                                          >
                                            {t("retry")}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
  validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ProfileIndex));
