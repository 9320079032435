import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import CategorySidebar from "../Category/CategorySidebar";
import HomeBanner from "./HomeBanner";
import CategoriesFollow from "./CategoriesFollow";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import LiveShowcard from "./LiveShowcard";
import HomeCategorySec from "./HomeCategorySec";

const LandingPageIndex = (props) => {
  const [active, setActive] = useState(true);
  function openItem() {
    setActive(false);
  }
  function closeItem() {
    setActive(true);
  }
  const t = useTranslation();
  return (
    <>
      <div className="home-page-sec">
        <div className="moblie-category-icon-sec" >
          {active == true && (

            <span onClick={openItem}>Categories</span>
          )}
          {active == false && (

            <span onClick={closeItem}>Close</span>
          )}
        </div>
        <Container fluid className="p-0">
          <div className="new-category-sec">
            <div className={active ? " new-category-left-sec  close " : "new-category-left-sec open "}>
              <CategorySidebar />
            </div>
            <div className="new-category-right-sec">
              <div className="home-page-right-sec">
                <HomeBanner />
                {localStorage.getItem("userId") ||
                  localStorage.getItem("accessToken") ?
                  <CategoriesFollow />
                  : null
                }
                <LiveShowcard />
                <HomeCategorySec />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageIndex);

