import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchBookmarkedVideosStart,
  fetchMoreBookmarkedVideosStart,
} from "../../store/actions/HomePageAction";
import { useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import LiveVideoCard from "../Helper/LiveVideoCard";
import CategoryFollowLoader from "../Helper/CategoryFollowLoader";

const BookmarIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchBookmarkedVideosStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreBookmarkedVideosStart({
        skip: props.bookmarkedVideos.data.live_video_bookmarks.length,
        take: 12,
      })
    );
  };

  return (
    <>
     <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
        <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("all_bookmarks")}</h3>
            </div>
          </div>
          <div className="category-card-sec mt-4">
            {props.bookmarkedVideos.loading ?
              < CategoryFollowLoader />
              :
              Object.keys(props.bookmarkedVideos.data).length > 0 &&
                props.bookmarkedVideos.data.live_video_bookmarks.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.bookmarkedVideos.data.live_video_bookmarks.length}
                  next={fetchMoreData}
                  hasMore={
                    props.bookmarkedVideos.data.live_video_bookmarks.length <
                    props.bookmarkedVideos.data.total
                  }
                  loader={<CategoryFollowLoader />}
                >
                  <div className="category-card-box">
                    {props.bookmarkedVideos.data.live_video_bookmarks.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  bookmarkedVideos: state.homepage.bookmarkedVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarIndex);


