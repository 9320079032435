import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  registerSuccess,
  registerFailure,
  loginSuccess,
  loginFailure,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  changePasswordSuccess,
  changePasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  usernameValidationSuccess,
  usernameValidationFailure,
  registerVerifySuccess,
  registerVerifyFailure,
  registerVerifyResendSuccess,
  registerVerifyResendFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  usersSearchSuccess,
  usersSearchFailure,
  fetchSingleUserSuccess,
  fetchSingleUserFailure,
  becomeSellorSuccess,
  becomeSellorFailure,
  getKycDocumentSuccess,
  getKycDocumentFailure,
  saveBlockUserSuccess,
  saveBlockUserFailure,
  fetchBlockUsersSuccess,
  fetchBlockUsersFailure,
  fetchListsDetailsSuccess,
  fetchListsDetailsFailure,
} from "../actions/UserAction";

import { fetchActiveFollowingSuccess } from "../actions/FollowAction";

import {
  REGISTER_START,
  LOGIN_START,
  FETCH_USER_DETAILS_START,
  UPDATE_USER_DETAILS_START,
  CHANGE_PASSWORD_START,
  DELETE_ACCOUNT_START,
  USERNAME_VALIDATION_START,
  REGISTER_VERIFY_START,
  REGISTER_VERIFY_RESEND_START,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  VERIFY_FORGOT_PASSWORD_START,
  USERS_SEARCH_START,
  FETCH_SINGLE_USER_DETAILS_START,
  BECOME_SELLER_START,
  GET_KYC_DOCUMENT_START,
  FETCH_BLOCK_USERS_START,
  SAVE_BLOCK_USER_START,
  FETCH_MORE_BLOCK_USERS_START,
  FETCH_LISTS_DETAILS_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import api from "../../Environment";


function* userRegisterAPI(action) {
  try {
    const response = yield api.postMethod("register", action.data);
    if (response.data.success) {
      yield put(registerSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 1001) {
        localStorage.setItem("email", response.data.data.email);
      } else {
        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
      }
    } else {
      yield put(registerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(registerFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userLoginAPI(action) {
  try {
    const response = yield api.postMethod("login", action.data);
    if (response.data.success) {
      yield put(loginSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code === 1001) {
        localStorage.setItem("email", response.data.data.email)
      }
      else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("is_content_creator", response.data.data.is_content_creator);
        localStorage.setItem("user_cover", response.data.data.cover);
        localStorage.setItem("name", response.data.data.name);
      }
    } else {
      yield put(loginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  }
  catch (error) {
    yield put(loginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("profile", action.data);
    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  }
  catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("update_profile", action.data);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data.data));
      yield put(fetchUserDetailsSuccess(response.data.data));
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("picture", response.data.data.picture);
      localStorage.setItem("cover", response.data.data.cover)
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(updateUserDetailsFailure(response.data.error));
      yield call(updateUserDetailsFailure, response.data.error);
    }
  }
  catch (error) {
    yield put(updateUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* changePasswordAPI(action) {
  try {
    const response = yield api.postMethod("change_password", action.data);
    if (response.data.success) {
      yield put(changePasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(changePasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  }
  catch (error) {
    yield put(changePasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);

  }
}

function* deleteAccountAPI(action) {
  try {
    const response = yield api.postMethod("delete_account", action.data);
    if (response.data.success) {
      yield put(deleteAccountSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(deleteAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteAccountFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}
function* usernameValidationAPI(action) {
  try {
    const response = yield api.postMethod("username_validation", action.data);
    if (response.data.success) {
      yield put(usernameValidationSuccess(response.data));
    } else {
      yield put(usernameValidationFailure(response));
    }
  } catch (error) {
    yield put(usernameValidationFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}
function* registerVerify(action) {
  try {
    const response = yield api.postMethod("verify_email", action.data);

    if (response.data.success) {
      yield put(registerVerifySuccess(response.data.data));
      yield put(fetchUserDetailsSuccess(response.data.data));
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("is_model", response.data.data.is_model);
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(registerVerifyFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(registerVerifyFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* registerVerifyResend(action) {
  try {
    const response = yield api.postMethod("regenerate_email_verification_code", action.data);

    if (response.data.success) {
      yield put(registerVerifyResendSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(registerVerifyResendFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(registerVerifyResendFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}
function* forgotPasswordAPI(action) {
  try {
    const response = yield api.postMethod("forgot_password", action.data);

    if (response.data.success) {
      yield put(forgotPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 102) {
        localStorage.setItem("email", response.data.data.email);
      } else {
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
      }
    } else {
      yield put(forgotPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }

  } catch (error) {
    yield put(forgotPasswordFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}
function* verifyForgotPasswordAPI(action) {
  try {
    const response = yield api.postMethod("verify_forgot_password_code", action.data);
    if (response.data.success) {
      yield put(verifyForgotPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.setItem("accessToken", response.data.data.token);
    } else {
      yield put(verifyForgotPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(verifyForgotPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* resetPasswordAPI(action) {
  try {
    const response = yield api.postMethod("reset_password", action.data);
    if (response.data.success) {
      yield put(resetPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("email");
    } else {
      yield put(resetPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* usersSearchAPI(action) {
  try {
    const response = yield api.postMethod("users_search", action.data);
    if (response.data.success) {
      yield put(usersSearchSuccess(response.data.data));
    } else {
      yield put(usersSearchFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(usersSearchFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchSingleUserAPI(action) {
  try {

    const response = yield api.postMethod("other_profile", action.data);
    if (response.data.success) {
      yield put(fetchSingleUserSuccess(response.data.data));
    } else {
      yield put(fetchSingleUserFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleUserFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* becomeSellerAPI(action) {
  try {

    const response = yield api.postMethod("become_a_seller", action.data);
    if (response.data.success) {
      yield put(becomeSellorSuccess(response.data.data));
      yield put(fetchUserDetailsSuccess(response.data.data.user));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(becomeSellorFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(becomeSellorFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* getKycDocumentAPI(action) {
  try {
    const response = yield api.postMethod("documents_list", action.data);
    if (response.data.success) {
      yield put(getKycDocumentSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(getKycDocumentFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(getKycDocumentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchBlockUsersAPI() {
  try {
    const response = yield api.postMethod("block_users");
    if (response.data.success) {
      yield put(fetchBlockUsersSuccess(response.data.data));
    } else {
      yield put(fetchBlockUsersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBlockUsersFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* saveBlockUserAPI(action) {
  try {
    const response = yield api.postMethod("block_users_save", action.data);
    if (response.data.success) {
      yield put(saveBlockUserSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let activeFollowingListData = yield select((state) => state.follow.activeFollowing.data);
      let blockListData = yield select((state) => state.users.blockUsers.data);
      if (Object.keys(activeFollowingListData).length > 0) {
        yield put(fetchActiveFollowingSuccess({
          ...activeFollowingListData,
          followers: activeFollowingListData.followers.map(follower =>
            follower.user_id == action.data.user_id
              ? {
                ...follower,
                otherUser: { ...follower.otherUser, is_block_user: response.data.data.is_block_user }
              } : follower,
          )
        }));
      }
      if (Object.keys(blockListData).length > 0) {
        yield put(fetchBlockUsersSuccess({
          ...blockListData,
          block_users: blockListData.block_users.filter(follower =>
            (follower.blocked_to != action.data.user_id)),
        }));
      }
    } else {
      yield put(saveBlockUserFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveBlockUserFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchListsAPI() {
  try {
    const response = yield api.postMethod("lists_index");
    if (response.data.success) {
      yield put(fetchListsDetailsSuccess(response.data.data));
    } else {
      yield put(fetchListsDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchListsDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* UserSaga() {
  yield all([
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
    yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
    yield takeLatest(REGISTER_VERIFY_START, registerVerify),
    yield takeLatest(REGISTER_VERIFY_RESEND_START, registerVerifyResend),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(VERIFY_FORGOT_PASSWORD_START, verifyForgotPasswordAPI),
    yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(USERS_SEARCH_START, usersSearchAPI),
    yield takeLatest(FETCH_SINGLE_USER_DETAILS_START, fetchSingleUserAPI),
    yield takeLatest(BECOME_SELLER_START, becomeSellerAPI),
    yield takeLatest(GET_KYC_DOCUMENT_START, getKycDocumentAPI),
    yield takeLatest(FETCH_BLOCK_USERS_START, fetchBlockUsersAPI),
    yield takeLatest(FETCH_MORE_BLOCK_USERS_START, fetchBlockUsersAPI),
    yield takeLatest(SAVE_BLOCK_USER_START, saveBlockUserAPI),
    yield takeLatest(FETCH_LISTS_DETAILS_START, fetchListsAPI),
  ])
}
