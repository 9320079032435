import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
  FETCH_WALLET_DETAILS_START,
  ADD_MONEY_VIA_PAYPAL_START,
  GENERATE_STRIPE_PAYMENT_START,
  PAYMENT_BY_STRIPE_START,
  SEND_WITHDRAW_REQUEST_START,
  FETCH_WITHDRAWALS_START,
  FETCH_MORE_WITHDRAWALS_START,
  CANCEL_WITHDRAW_REQUEST_START,
  FETCH_PAYMENTS_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/ToastNotification";
import {
  fetchWithDrawalsStart,
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  addMoneyViaPaypalSuccess,
  addMoneyViaPaypalFailure,
  generateStripeSuccess,
  generateStripeFailure,
  paymentByStripeSuccess,
  paymentByStripeFailure,
  sendWithDrawRequestSuccess,
  sendWithDrawRequestFailure,
  fetchWithDrawalsSuccess,
  fetchWithDrawalsFailure,
  cancelWithDrawRequestSuccess,
  cancelWithDrawRequestFailure,
  fetchPaymentsSuccess,
  fetchPaymentsFailure,
} from "../actions/WalletAction";
import { fetchAllTransactionStart } from "../actions/TransactionAction";

function* fetchWalletDetailsAPI() {
  try {
    const response = yield api.postMethod("wallets_index");
    if (response.data.success) {
      yield put(fetchWalletDetailsSuccess(response.data.data));
    } else {
      yield put(fetchWalletDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWalletDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* addMoneyViaPaypalAPI(action) {
  try {
    const response = yield api.postMethod("wallets_add_money_by_paypal", action.data);
    if (response.data.success) {
      yield put(addMoneyViaPaypalSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addMoneyViaPaypalFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addMoneyViaPaypalFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* generateStripeAPI(action) {
  try {
    const response = yield api.postMethod("generate_stripe_payment_intent", action.data);
    if (response.data.success) {
      yield put(generateStripeSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(generateStripeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addMoneyViaPaypalFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* paymentByStripeAPI(action) {
  try {
    const response = yield api.postMethod("user_wallets_stripe_payments_save", action.data);
    if (response.data.success) {
      yield put(paymentByStripeSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(paymentByStripeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addMoneyViaPaypalFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* sendWithDrawRequestAPI(action) {
  try {
    const response = yield api.postMethod("withdrawals_send_request", action.data);
    if (response.data.success) {
      yield put(sendWithDrawRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(sendWithDrawRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sendWithDrawRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWithDrawAPI() {
  try {
    const response = yield api.postMethod("withdrawals_index");

    if (response.data.success) {
      yield put(fetchWithDrawalsSuccess(response.data.data));
    } else {
      yield put(fetchWithDrawalsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWithDrawalsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* cancelWithDrawRequestAPI(action) {
  try {
    const response = yield api.postMethod("withdrawals_cancel_request", action.data);
    if (response.data.success) {
      yield put(cancelWithDrawRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(fetchWithDrawalsStart());
      yield put(fetchAllTransactionStart());
    } else {
      yield put(cancelWithDrawRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(cancelWithDrawRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getPaymentsAPI() {
  try {
    const response = yield api.postMethod("payments_index");

    if (response.data.success) {
      yield put(fetchPaymentsSuccess(response.data));
    } else {
      yield put(fetchPaymentsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchPaymentsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* WalletSaga() {
  yield all([
    yield takeLatest(FETCH_WALLET_DETAILS_START, fetchWalletDetailsAPI),
    yield takeLatest(ADD_MONEY_VIA_PAYPAL_START, addMoneyViaPaypalAPI),
    yield takeLatest(GENERATE_STRIPE_PAYMENT_START, generateStripeAPI),
    yield takeLatest(PAYMENT_BY_STRIPE_START, paymentByStripeAPI),
    yield takeLatest(SEND_WITHDRAW_REQUEST_START, sendWithDrawRequestAPI),
    yield takeLatest(FETCH_WITHDRAWALS_START, fetchWithDrawAPI),
    yield takeLatest(FETCH_MORE_WITHDRAWALS_START, fetchWithDrawAPI),
    yield takeLatest(CANCEL_WITHDRAW_REQUEST_START, cancelWithDrawRequestAPI),
    yield takeLatest(FETCH_PAYMENTS_START, getPaymentsAPI),
  ]);
}

