import React, { useState, useEffect } from "react";
// import LivePaymentModal from "./LivePaymentModal";
import { connect } from "react-redux";
import { translate, t, withTranslation, useTranslation } from "react-multi-lang";
import { Media, Image, Tab, Row, Col, Nav, Form, Button } from "react-bootstrap";
import configuration from "react-global-configuration";

const LiveProducts = (props) => {

    const [paymentModal, setPaymentModal] = useState(false);
    const t = useTranslation();
    const closePaymentModal = () => {
        setPaymentModal(false);
    };
    console.log(props.user);
    return (
        <>
            <div className="video-product-list-card">
                <div className="video-product-list-left-sec">
                    <h4>{props.product.name}</h4>
                    <h6>{props.product.category_name}</h6>
                    <h4 className="sold-color">{props.product.price_formatted}</h4>
                    {/* <p>Set Pre-bid</p> */}
                </div>
                {props.liveVideo.is_streaming == 1 ?
                    <div className="video-product-list-right-sec">
                        {props.liveVideo.user_id != localStorage.getItem("userId") ?
                            props.user.is_online_status === 1 && props.user.is_user_online === 1 ?
                                <Button
                                    type="button"
                                    onClick={() =>
                                        setPaymentModal(true)
                                    }
                                >
                                    {t("buy_now")}
                                </Button>
                                : '' : ''}
                    </div>
                    : configuration.get('configData.is_offline_products_available') == 1 ?
                        props.liveVideo.user_id != localStorage.getItem("userId") ?
                            props.user.is_online_status === 1 && props.user.is_user_online === 1 ?
                                <Button
                                    type="button"
                                    onClick={() =>
                                        setPaymentModal(true)
                                    }
                                >
                                    {t("buy_now")}
                                </Button>
                                : '' : ''

                        : ''}

            </div>
            {/* <LivePaymentModal
                paymentModal={paymentModal}
                closePaymentModal={closePaymentModal}
                product={props.product}
                liveVideo={props.liveVideo}
            /> */}
        </>
    );
};

const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(withTranslation(LiveProducts));
