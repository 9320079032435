import React, { useState, useEffect } from "react";
import "./Product.css";
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import {
  userProductsSaveStart,
  fetchProductCategoriesStart,
} from "../../store/actions/ProductsAction";
import { fetchSubCategoriesStart } from "../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import Skeleton from "react-loading-skeleton";

const DetectCategories = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.category_id) {
      dispatch(fetchSubCategoriesStart({ category_id: values.category_id }));
    }
  }, [values.category_id]);
}

const AddProduct = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);

  const productSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    quantity: Yup.string().required(t("quantity_is_required")),
    price: Yup.string().required(t("price_is_required")),
    category_id: Yup.string().required(t("category_id_is_required")),
    sub_category_id: Yup.string().required(t("sub_category_id_is_required")),
  })

  useEffect(() => {
    props.dispatch(
      fetchProductCategoriesStart()
    );
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(userProductsSaveStart({
      picture: files[0],
      ...values
    }));
  };

  useEffect(() => {
    if (!skipRender && !props.productSave.loading && Object.keys(props.productSave.data).length > 0) {
      navigate(`/single-product/${props.productSave.data.unique_id}`);
    }
    setSkipRender(false);
  }, [props.productSave]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    }
  });

  const images = files.map(file => (
    <Image
      className="product-preview"
      key={file.name}
      src={file.preview}
      alt="image-preview" />
  ))

  return (
    <>
      <div className="add-product-sec">
        <Container>
          <Formik
            initialValues={{
              name: "",
              quantity: "",
              price: "",
              category_id: "",
              sub_category_id: "",
              description: "",
            }}
            validationSchema={productSchema}
            onSubmit={handleSubmit}
          >
            <FORM className="profile-details-form mt-0">
              <div className="single-page-header">
                <div className="single-page-title">
                  <Link to="#" onClick={() => navigate(-1)}>
                    <Image src={window.location.origin + "/images/back.svg"} />
                  </Link>
                  <h3>{t("add_product")}</h3>
                </div>
              </div>
              <div className="add-product-box">
                <Row className="justify-content-md-center">
                  <Col md={6}>
                    <div className="border-right-divider add-product-form-sec">
                      <Form.Group className="mb-4">
                        <Form.Label>{t("name")}*</Form.Label>
                        <Field
                          type="text"
                          placeholder={t("name")}
                          name="name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component={"div"}
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("quantity")}*</Form.Label>
                        <Field
                          type="number"
                          placeholder={t("quantity")}
                          name="quantity"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="quantity"
                          component={"div"}
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("price")}*</Form.Label>
                        <Field
                          type="number"
                          min="1"
                          placeholder={t("price")}
                          name="price"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="price"
                          component={"div"}
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("category")}*</Form.Label>
                        <Field
                          as="select"
                          className="form-control"
                          name="category_id"
                        >
                          <option value="">{t("select_category")}</option>
                          {props.categories.loading ? "Loading" :
                            props.categories.data.product_categories.map((category) => (
                              <option value={category.category_id}>{category.name}</option>
                            ))}
                        </Field>
                        < DetectCategories />
                        <ErrorMessage
                          name="category_id"
                          component={"div"}
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("sub_category")}*</Form.Label>
                        <Field
                          as="select"
                          className="form-control"
                          name="sub_category_id"
                        >
                          <option value="0">{t("select_sub_category")}</option>
                          {props.subCategories.loading ?
                            <Skeleton count={1} />
                            :
                            Object.keys(props.subCategories.data).length > 0 &&
                              props.subCategories.data.sub_categories.length > 0 ?
                              props.subCategories.data.sub_categories.map((sub_category) => (
                                <option value={sub_category.sub_category_id}>{sub_category.name}</option>
                              ))
                              :
                              null
                          }
                        </Field>
                        <ErrorMessage
                          name="sub_category_id"
                          component={"div"}
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="add-product-upload-file-sec">
                      <Form.Label>{t("upload_product_image")}</Form.Label>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="add-product-upload-box">
                          <Image src={window.location.origin + "/images/upload-icon.svg"} className="upload-icon" />
                          <p>{t("select_a_image")}</p>
                        </div>
                        <div>
                          {images}
                        </div>
                        <div className="product-img-notes">
                          {t("please_upload_jpg")}
                        </div>
                      </div>
                      <Form.Group className="mb-4">
                        <Form.Label>{t("description")}</Form.Label>
                        <Field
                          as="textarea"
                          rows={5}
                          name="description"
                          className="height-auto form-control"
                          placeholder={t("description")}
                        />
                      </Form.Group>
                      <div className="add-product-btn-sec text-center">
                        <Button
                          type="submit"
                          className="add-product-btn"
                          disabled={props.productSave.buttonDisable}
                        >
                          {props.productSave.loadingButtonContent !== null ?
                            props.productSave.loadingButtonContent :
                            t("add")
                          }
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </FORM>
          </Formik>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  productSave: state.userProducts.productSave,
  categories: state.userProducts.productCategories,
  subCategories: state.homepage.subCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(AddProduct));
