import React, { useEffect } from 'react'
import { fetchUserDetailsFailure } from '../../store/actions/UserAction'
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { map } from 'lodash';

const LogoutIndex = (props) => {

    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem("userLoginStatus");
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        props.dispatch(fetchUserDetailsFailure());
        navigate("/");

    }, []);
    return ""
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(null, mapDispatchToProps)(LogoutIndex);