import React, { useState, useEffect } from "react";
import { Col, Container, Form, Image, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  addBankAccountStart,
  viewBankAccountStart,
} from "../../store/actions/BankAccountAction";
import Skeleton from "react-loading-skeleton";
import { useParams } from 'react-router-dom';

const EditBankAccount = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [agree, setAgree] = useState(false);
  const params = useParams();

  const options = [
    { value: "savings", label: "Savings" },
    { value: "current account", label: "Current Account" },
  ];

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string().required(t("route_number_is_required")),
    account_number: Yup.string().required(t("account_number_is_required")),
    first_name: Yup.string().required(t("first_name_is_required")),
    last_name: Yup.string().required(t("last_name_is_required")),
    bank_type: Yup.string().required(t("bank_type_is_required"))
  })

  useEffect(() => {
    props.dispatch(
      viewBankAccountStart({
        user_billing_account_id: params.user_billing_account_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!skipRender && !props.addBankAccount.loading &&
      Object.keys(props.addBankAccount.data).length > 0) {
      navigate("/bank-accounts");
    }
    setSkipRender(false);
  }, [props.addBankAccount]);

  const handleSubmit = (values) => {
    props.dispatch(addBankAccountStart(values));
  }
  const customStyles = {
    ///.....
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(57,57,57,.07)",
      borderColor: "#e7e7e7",
      boxShadow: "none",
      height: "50px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "var(----active-color)",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
    ///.....
  };
  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("edit_bank")}</h3>
            </div>
          </div>
          <div className="add-bank-sec">
            {props.viewAccount.loading ?
              <div className="add-bank-details">
                <Row>
                  <Col md={6}>
                    <Skeleton className="mb-4" count={3} height={50} width={450} />
                  </Col>
                  <Col md={6}>
                    <Skeleton className="mb-4" count={3} height={50} width={450} />
                  </Col>
                </Row>
              </div>
              :
              props.viewAccount.data.billing_accounts.map((billing_account) => (
                <Formik
                  initialValues={{
                    route_number: billing_account.route_number,
                    account_number: billing_account.account_number,
                    first_name: billing_account.first_name,
                    last_name: billing_account.last_name,
                    bank_type: billing_account.bank_type,
                    business_name: billing_account.business_name !== null ?
                      billing_account.business_name
                      : null,
                  }}
                  validationSchema={addbillingSchema}
                  onSubmit={(values => handleSubmit(values))}
                >
                  <FORM>
                    <div className="add-bank-details">
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("routing_numer")}: (*)</Form.Label>
                            <Field
                              type="number"
                              name="route_number"
                              placeholder={t("routing_numer")}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="route_number"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("account_number")}: (*)</Form.Label>
                            <Field
                              type="number"
                              name="account_number"
                              placeholder={t("account_number")}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="account_number"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("first_name")}: (*)</Form.Label>
                            <Field
                              type="text"
                              name="first_name"
                              placeholder={t("first_name")}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="first_name"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("last_name")}: (*)</Form.Label>
                            <Field
                              type="text"
                              name="last_name"
                              placeholder={t("last_name")}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="last_name"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("type_of_bank")}(*)</Form.Label>
                            <Field
                              as="select"
                              className="form-control"
                              name="bank_type"
                              placeholder={t("type_of_bank")}>
                              <option value="savings">{t("savings")}</option>
                              <option value="checking">{t("checking")}</option>
                            </Field>
                            <ErrorMessage
                              name="bank_type"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("business_name")}</Form.Label>
                            <Field
                              type="text"
                              name="business_name"
                              placeholder={t("business_name")}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label={<div>
                            <span> {t("i_agree_to")} </span>
                            <Link to="/page/terms" target='_blank'> {t("terms_conditions")}</Link>
                          </div>
                          }
                          required
                          checked={agree}
                          name="acceptTerms"
                          onChange={() => setAgree(!agree)}
                        />
                      </Form.Group>
                      <div className="submit-btn">
                        <Button type="submit"
                          className="default-btn"
                          disabled={props.addBankAccount.buttonDisable ||
                            !agree}
                        >
                          {props.addBankAccount.loadingButtonContent != null ?
                            props.addBankAccount.loadingButtonContent :
                            t("submit")}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                </Formik>
              ))
            }
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  viewAccount: state.bankAccount.viewAccount,
  addBankAccount: state.bankAccount.addBankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(EditBankAccount));

