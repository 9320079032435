import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    USER_PRODUCTS_SAVE_START,
    FETCH_PRODUCT_CATEGORIES_START,
    FETCH_PRODUCT_SUB_CATEGORIES_START,
    FETCH_USER_SINGLE_PRODUCT_START,
    FETCH_USER_PRODUCTS_FOR_OWNER_START,
    DELETE_USER_PRODUCT_START,
    FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START,
    FETCH_SINGLE_PRODUCT_ORDERS_START,
    FETCH_MORE_SINGLE_PRODUCT_ORDERS_START,
    USER_PRODUCT_VIEW_FOR_OTHERS_START,
    FETCH_USER_PRODUCTS_START,
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    userProductsSaveSuccess,
    userProductsSaveFailure,
    fetchProductCategoriesSuccess,
    fetchProductCategoriesFailure,
    fetchProductSubCategoriesSuccess,
    fetchProductSubCategoriesFailure,
    fetchUserSingleProductSuccess,
    fetchUserSingleProductFailure,
    fetchUserProductsForOwnerSuccess,
    fetchUserProductsForOwnerFailure,
    fetchUserProductsForOwnerStart,
    deleteUserProductSuccess,
    deleteUserProductFailure,
    fetchSingleProductOrdersSuccess,
    fetchSingleProductOrdersFailure,
    userProductViewForOthersSuccess,
    userProductViewForOthersFailure,
    fetchUserProductsSuccess,
    fetchUserProductsFailure
} from "../actions/ProductsAction";

function* userProductsSaveAPI(action) {
    try {
        const response = yield api.postMethod("user_products_save", action.data);

        if (response.data.success) {
            yield put(userProductsSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message)
        } else {
            yield put(userProductsSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductsSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* fetchProductCategoriesAPI() {
    try {
        const response = yield api.postMethod("product_categories");

        if (response.data.success) {
            yield put(fetchProductCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchProductCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchProductCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchProductSubCategoriesAPI() {
    try {
        const response = yield api.postMethod("product_sub_categories");

        if (response.data.success) {
            yield put(fetchProductSubCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchProductSubCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchProductSubCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchUserSingleProductAPI(action) {
    try {
        const response = yield api.postMethod("user_products_view", action.data);

        if (response.data.success) {
            yield put(fetchUserSingleProductSuccess(response.data.data));
        } else {
            yield put(fetchUserSingleProductFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserSingleProductFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchUserProductsAPI() {
    try {
      const response = yield api.postMethod("user_products");
  
      if (response.data.success) {
        yield put(fetchUserProductsSuccess(response.data.data));
      } else {
        yield put(fetchUserProductsFailure(response.data.error));
        yield call(getErrorNotificationMessage, response.data.error);
      }
    } catch (error) {
      yield put(fetchUserProductsFailure(error));
      yield call(getErrorNotificationMessage, error.message);
    }
}

function* fetchUserProductsListAPI(action) {
    try {
        const response = yield api.postMethod("user_products_for_owner", action.data);

        if (response.data.success) {
            yield put(fetchUserProductsForOwnerSuccess(response.data.data));
        } else {
            yield put(fetchUserProductsForOwnerFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserProductsForOwnerFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* deleteUserProductAPI(action) {
    try {
        const response = yield api.postMethod("user_products_delete", action.data);

        if (response.data.success) {
            yield put(deleteUserProductSuccess(response.data.data));
            yield put(fetchUserProductsForOwnerStart());
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(deleteUserProductFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deleteUserProductFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchSingleProductOrdersAPI(action) {
    try {
        const response = yield api.postMethod("user_products_orders_list", action.data);

        if (response.data.success) {
            yield put(fetchSingleProductOrdersSuccess(response.data.data));
        } else {
            yield put(fetchSingleProductOrdersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchSingleProductOrdersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* userProductViewForOthersAPI(action) {
    try {
        const response = yield api.postMethod("user_products_view_for_others", action.data);

        if (response.data.success) {
            yield put(userProductViewForOthersSuccess(response.data.data));
        } else {
            yield put(userProductViewForOthersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductViewForOthersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

export default function* ProductSaga() {
    yield all([
        yield takeLatest(FETCH_USER_PRODUCTS_START, fetchUserProductsAPI),
        yield takeLatest(USER_PRODUCTS_SAVE_START, userProductsSaveAPI),
        yield takeLatest(FETCH_PRODUCT_CATEGORIES_START, fetchProductCategoriesAPI),
        yield takeLatest(FETCH_PRODUCT_SUB_CATEGORIES_START, fetchProductSubCategoriesAPI),
        yield takeLatest(FETCH_USER_SINGLE_PRODUCT_START, fetchUserSingleProductAPI),
        yield takeLatest(FETCH_USER_PRODUCTS_FOR_OWNER_START, fetchUserProductsListAPI),
        yield takeLatest(FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START, fetchUserProductsListAPI),
        yield takeLatest(DELETE_USER_PRODUCT_START, deleteUserProductAPI),
        yield takeLatest(FETCH_SINGLE_PRODUCT_ORDERS_START, fetchSingleProductOrdersAPI),
        yield takeLatest(FETCH_MORE_SINGLE_PRODUCT_ORDERS_START, fetchSingleProductOrdersAPI),
        yield takeLatest(USER_PRODUCT_VIEW_FOR_OTHERS_START, userProductViewForOthersAPI),
       
    ]);
}

