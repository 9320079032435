import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    FETCH_HOME_FEATURED_SELLERS_START,
    FETCH_HOME_CATEGORIES_START,
    FETCH_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_START,
    FETCH_CATEGORIES_LIST_START,
    FOLLOW_CATEGORIES_START,
    FOLLOW_SUB_CATEGORIES_START
} from "../actions/ActionConstant";
import {
    fetchHomeFeaturedSellersFailure,
    fetchHomeFeaturedSellersSuccess,
    fetchHomeCategoriesSuccess,
    fetchHomeCategoriesFailure,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchSubCategoriesSuccess,
    fetchSubCategoriesFailure,
    fetchCategoriesListFailure,
    fetchCategoriesListSuccess,
    followCategoriesSuccess,
    followCategoriesFailure,
    followSubCategoriesSuccess,
    followSubCategoriesFailure,
} from "../actions/LookUpAction";
import { fetchSubCategoryVideosStart } from "../actions/HomePageAction";

function* fetchHomeFeaturedSellersAPI() {
    try {
        const response = yield api.postMethod("featured_sellers_home");
        if (response.data.success) {
            yield put(fetchHomeFeaturedSellersSuccess(response.data.data));
        } else {
            yield put(fetchHomeFeaturedSellersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchHomeFeaturedSellersFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* fetchHomeCategoriesAPI() {
    try {
        const response = yield api.postMethod("categories_home");
        if (response.data.success) {
            yield put(fetchHomeCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchHomeCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchHomeCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* fetchCategoriesAPI() {
    try {
        const response = yield api.postMethod("categories");
        if (response.data.success) {
            yield put(fetchCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* fetchSubCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("sub_categories", action.data);
        if (response.data.success) {
            yield put(fetchSubCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchSubCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchSubCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* fetchCategoriesListAPI() {
    try {
        const response = yield api.postMethod("categories_list");
        if (response.data.success) {
            yield put(fetchCategoriesListSuccess(response.data.data));
        } else {
            yield put(fetchCategoriesListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(fetchCategoriesListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* followCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("follow_category_sub_categories", action.data);
        if (response.data.success) {
            yield put(followCategoriesSuccess(response.data.data));
        } else {
            yield put(followCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(followCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* followSubCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("follow_category_sub_categories", action.data);
        if (response.data.success) {
            yield put(followSubCategoriesSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put (fetchSubCategoryVideosStart({sub_category_id: action.data.sub_category_id}))
        } else {
            yield put(followSubCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(followSubCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_HOME_FEATURED_SELLERS_START, fetchHomeFeaturedSellersAPI),
        yield takeLatest(FETCH_HOME_CATEGORIES_START, fetchHomeCategoriesAPI),
        yield takeLatest(FETCH_CATEGORIES_START, fetchCategoriesAPI),
        yield takeLatest(FETCH_SUB_CATEGORIES_START, fetchSubCategoriesAPI),
        yield takeLatest(FETCH_CATEGORIES_LIST_START, fetchCategoriesListAPI),
        yield takeLatest(FOLLOW_CATEGORIES_START, followCategoriesAPI),
        yield takeLatest(FOLLOW_SUB_CATEGORIES_START, followSubCategoriesAPI),
    ]);
}