import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Auth.css";
import Slider from "react-slick";
import { registerStart, usernameValidationStart } from "../../store/actions/UserAction";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import SocialButton from '../Helper/SocialButton';

const RegisterIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState("");
  const [skipRender, setSkipRender] = useState(false);

  const [signupInputData, setSignupInputData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
  })

  const registervalidation = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    username: Yup.string().required(t("user_name_is_required")),
    email: Yup.string().email(t("invalid_email_address_format"))
      .required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters")),
  })

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value) return t("should_not_contain_empty_space");
    return "";
  };

  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
  };

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return t("no_white_space_allowed");
      }
    } else {
      setIsValidUserName(false);
      return t("must_contain_4_characters");
    }
  };
  useEffect(() => {
    if (!skipRender && !props.register.loading &&
      Object.keys(props.register.data).length > 0) {
      if (props.register.data.code === 1001) {
        navigate("/register/verify");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.register])

  const handleSubmit = (values) => {
    props.dispatch(registerStart(values));
  }

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };


  return (
    <>
      <div className="auth-page-sec">
        <div className="auth-page-left-sec">
          <Slider {...settings}>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-1.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-2.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-3.jpeg"}
              />
            </div>
          </Slider>
          <div className="auth-nav-switch-sec">
            <Link to="/login"
              className={`login-link ${location.pathname === "/login" ? "active" : ""
                }`}
            >
              {t("login")}</Link>
            <Link to="/register"
              className={`login-link ${location.pathname === "/register" ? "active" : ""
                }`}

            >{t("register")}</Link>
          </div>
        </div>
        <div className="auth-page-right-sec">
          <div className="auth-details-sec">
            <div className="auth-logo-sec">
              <Image
                className="auth-logo"
                src={window.location.origin + "/images/logo-black.png"}
              />
            </div>
            <Formik
              initialValues={signupInputData}
              validationSchema={registervalidation}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                touched,
                errors,
                values,

              }) => (
                <FORM className="auth-form-sec">
                  <div className="auth-info">
                    <h2>{t("register")}</h2>
                  </div>
                  <div className="auth-form">
                    <Form.Group className="mb-3">
                      <Field
                        type="text"
                        placeholder={t("name")}
                        name="name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="name"
                        component={"div"}
                        className="text-danger pass-txt"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Field
                        type="text"
                        placeholder={t("user_name")}
                        name="username"
                        className="form-control"
                        validate={handleUsernameValidation}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="username"
                        component={"div"}
                        className="text-danger pass-txt"
                      />
                      {props.validation.isInValid &&
                        isvalidUserName ? (
                        <div class="text-danger">
                          {props.validation.errorMessage ??
                            t("username_already_taken")}
                        </div>
                      ) : (
                        ""
                      )}
                      {props.validation.isValid && isvalidUserName ? (
                        <div class="text-success">
                          {t("looks_good")}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Field
                        type="email"
                        placeholder={t("email_address")}
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component={"div"}
                        className="text-danger pass-txt"
                      />
                    </Form.Group>
                    <InputGroup className="mb-4">
                      <Field
                        placeholder={t("password")}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        validate={passwordValidation}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button
                          onClick={() =>
                            setShowPassword(!showPassword)
                          }
                          className="btn password-eye"
                          type="button"
                        >{showPassword ?
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                          />
                          :
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                          />
                          }
                        </button>
                      </InputGroup.Text>
                      <ErrorMessage
                        name="password"
                        component={"div"}
                        className="text-danger pass-txt"
                      />
                    </InputGroup>
                    <div className="auth-btn-sec">
                      <Button
                        type="submit"
                        className="default-btn"
                        disabled={props.register.buttonDisable}
                      >
                        {props.register.loadingButtonContent !== null ?
                          props.register.loadingButtonContent :
                          t("register")
                        }
                      </Button>
                    </div>

                    <div className="auth-footer-link-sec">
                      <h4>{t("already_have_an_account")}</h4>
                      <Link to="/login">{t("login")}</Link>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
            <div className="auth-social-btn-sec">
              {configuration.get("configData.FB_CLIENT_ID") ?
                <SocialButton
                  provider="facebook"
                  appId={configuration.get("configData.FB_CLIENT_ID")}
                  onLoginSuccess={handleFacebookLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  className="social-button"
                  id="facebook-connect"
                >
                  <Image
                    className="social-logo"
                    src={window.location.origin + "/images/auth/icon/facebook.svg"}
                  />
                </SocialButton>
                :
                null
              }
              {configuration.get("configData.GOOGLE_CLIENT_ID") ?
                <SocialButton
                  provider="google"
                  key={"google"}
                  appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                  onLoginSuccess={handleGoogleLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  className="social-button"
                  id="google-connect"
                >
                  <Image
                    className="social-logo"
                    src={window.location.origin + "/images/auth/icon/google.svg"}
                  />
                </SocialButton>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  register: state.users.register,
  validation: state.users.validationInputData,

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(RegisterIndex));
