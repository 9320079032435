import React, { useState } from "react";
import { Container, Image, Button, Row, Col, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./LiveStreaming.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  fetchLiveVideosHistoryStart,
  fetchMoreLiveVideosHistoryStart
} from '../../store/actions/LiveVideoAction';
import { connect } from 'react-redux';
import { useEffect } from "react";
import TableOrderLoader from "../Helper/TableOrderLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";

const LiveVideosHistory = (props) => {

  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchLiveVideosHistoryStart({
      skip: 0,
      take: 12,
    }))
  }, [])

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreLiveVideosHistoryStart({
        skip: props.liveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="live-video-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("live_videos_history")}</h3>
            </div>
            <div className="live-video-header-btn-sec">
              <Link to="/go-live" className="default-btn">
                {t("go_live")}
              </Link>
              <Link to="/live-videos" className="default-blue-btn">
                {t("scheduled_live_videos")}
              </Link>
            </div>
          </div>
          <div className="live-video-history-box">
            {props.liveVideos.loading ?
              <TableOrderLoader />
              :
              <Row>
                <Col md={12}>
                  <div className="order-transaction-sec">
                    <div className="order-list-table">
                      {Object.keys(props.liveVideos.data).length > 0 &&
                        props.liveVideos.data.live_videos.length > 0
                        ?
                        <InfiniteScroll
                          dataLength={props.liveVideos.data.live_videos.length}
                          next={fetchMoreData}
                          hasMore={
                            props.liveVideos.data.live_videos.length <
                            props.liveVideos.data.total}
                          loader={<TableOrderLoader />}
                        >
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>{t("title")}</th>
                                <th>{t("username")}</th>
                                <th>{t("stream_date")}</th>
                                <th>{t("View Count")}</th>
                                <th>{t("revenue")}</th>
                                <th>{t("action_order")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.liveVideos.data.live_videos.map(
                                (video) => (
                                  <tr>
                                    <td>
                                      <h5>{video.title}</h5>
                                    </td>
                                    <td>{video.username}</td>
                                    <td>{video.created_at_formatted}</td>
                                    <td> {video.viewer_cnt}</td>
                                    <td>
                                      <h5>{video.user_amount_formatted}</h5>
                                    </td>
                                    <td>
                                      <div className="live-video-submit-btn">
                                        <Link to={`/live-orders/${video.live_video_id}`}
                                          className="default-btn"
                                        >
                                          {t("orders")}
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                        :
                        <NoDataFound />
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liveVideos: state.liveVideo.liveVideosHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(LiveVideosHistory));

