import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Col, Image,Button } from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoEndStart,
} from "../../store/actions/LiveVideoAction";
import {
  unFollowUserStart,
  followUserStart,
} from "../../store/actions/FollowAction";
import configuration from "react-global-configuration";
import "../VideoCall/VideoCall.css";
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;

const LiveVideoStream = (props) => {
  const [followStatus, setFollowStatus] = useState("");


  var rtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };

  const localTrackState = {
    videoTrackEnabled: true,
    audioTrackEnabled: true,
  };

  var options = {
    // Pass your app ID here.
    appId: configuration.get("configData.agora_app_id"),
    // set UID
    uid: props.isOwner ? 1 : 0,
    // Set the channel name.
    channel: props.liveVideo.data.virtual_id,
    // Pass a token if your project enables the App Certificate.
    token: props.liveVideo.data.agora_token
      ? props.liveVideo.data.agora_token
      : null,
    // Set the user role in the channel. // "audience"
    role: props.isOwner ? "host" : "audience",
  };

  var remoteUsers = {};

  async function startBasicCall() {
    AgoraRTC.setLogLevel(4);
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    client.setClientRole(options.role);

    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      // If the subscribed track is video.
      if (mediaType === "video") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;

        remoteVideoTrack.play("agora_local");
        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      props.dispatch(
        liveViewerUpdateStart({
          live_video_id: props.liveVideo.data.live_video_id,
        })
      );

      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }
    });

    const uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token || null,
      options.uid || null
    );

    if (options.role === "host") {
      // Create an audio track from the audio sampled by a microphone.
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera.
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      // Publish the local audio and video tracks to the channel.
      rtc.localVideoTrack.play("agora_local");

      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    }
  }

  async function leaveCall() {
    if (options.role === "host") {
      // Destroy the local audio and video tracks.
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      // Traverse all remote users.
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      props.dispatch(
        liveVideoEndStart({
          live_video_id: props.liveVideo.data.live_video_id,
        })
      );
      window.location.assign("/live-videos-history");
    } else {
      // Leave the channel.
      await rtc.client.leave();
      window.location.assign("/live-videos-history");
    }
  }

  async function muteAudio() {
    if (!rtc.localAudioTrack) return;
    if (localTrackState.audioTrackEnabled == true) {
      await rtc.localAudioTrack.setEnabled(false);
      localTrackState.audioTrackEnabled = false;
      $("#mute-audio").hide();
      $("#unmute-audio").show();
    } else {
      await rtc.localAudioTrack.setEnabled(true);
      localTrackState.audioTrackEnabled = true;
      $("#mute-audio").show();
      $("#unmute-audio").hide();
    }
  }

  async function muteVideo() {
    if (!rtc.localVideoTrack) return;
    if (localTrackState.videoTrackEnabled == true) {
      await rtc.localVideoTrack.setEnabled(false);
      localTrackState.videoTrackEnabled = false;
      $("#mute-video").hide();
      $("#unmute-video").show();
    } else {
      await rtc.localVideoTrack.setEnabled(true);
      localTrackState.videoTrackEnabled = true;
      $("#mute-video").show();
      $("#unmute-video").hide();
    }
  }

  startBasicCall();

  return (
    <Col md={6}>
      <div className="live-streaming-middle-sec">
        <div className="live-sec-img" id="agora_local"
          style={{ width: "100%", height: "100%", position: "relative" }}>
          <div className="live-stream-profile">
            {props.isOwner ?
              <div className="live-profile-img">
                <div className="live-img">
                  <Image
                    src={props.liveVideo.data.user_picture}
                  />
                </div>
                <div className="live-profile-img-content">
                  <h4> <Link to={`/` + props.liveVideo.data.user_unique_id}>
                    {t("you")}
                  </Link></h4>
                </div>
              </div>
              :
              <>
                <h4 className="user-details"> <Link to={`/` + props.liveVideo.data.user_unique_id}>
                  {props.liveVideo.data.user_displayname}
                </Link>
                </h4>
                <div className="live-follow-btn">
                  {props.liveVideo.data.is_following ?
                    <Button
                      onClick={() => {
                        props.dispatch(
                          unFollowUserStart({
                            user_id: props.liveVideo.data.user_id,
                          })
                        );
                      }}
                    >
                      {t("unfollow")}
                    </Button>
                    :
                    <Button
                      onClick={() => {
                        props.dispatch(
                          followUserStart({
                            user_id: props.liveVideo.data.user_id,
                          })
                        );
                      }}
                    >
                      {t("follow")}
                    </Button>
                  }
                </div>
              </>

            }
            <div className="live-stream-close-btn">
              <div className="participant-actions">
                <ul className="list-unstyled participant-notify">
                  {props.isOwner ? (
                    <li as="li">
                      <Link
                        to="#"
                        onClick={(e) =>
                          window.confirm("end call")
                            ? leaveCall()
                            : e.preventDefault()
                        }
                        title="Close"
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/close.svg"
                          }
                        />
                      </Link>
                    </li>
                  ) : (
                    <li as="li">
                      <Link to={"/"} title="Close">
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/close.svg"
                          }
                        />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="live-stream-btn">
            <div className="live-icons">
              {props.liveVideo.data.status === 0 ? (
                <h4>{t("live")}</h4>
              ) : (
                <h4>{t("offline")}</h4>
              )}
              <div className="live-bottom-icon live-eye-icon">
                <Image
                  src={
                    window.location.origin +
                    "/images/live-streaming/eye.svg"
                  }
                />
                <span>( {props.liveVideo.data.viewer_cnt})</span>
              </div>
            </div>
            <div className="live-icons">
              <ul className="list-unstyled live-action-flex">
                {props.isOwner ?
                  <>
                    <li className='media' id="mute-video">
                      <Link to="#"
                        onClick={() => muteVideo()}
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/video-unmute.svg"
                          }
                        />
                      </Link>
                    </li>
                    <li className='media'
                      id="unmute-video"
                      style={{ display: "none" }}
                    >
                      <Link
                        to="#"
                        onClick={() => muteVideo()}
                        title="Open Cam"
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/video-mute.svg"
                          }
                        />
                      </Link>
                    </li>
                    <li className='media' id="mute-audio">
                      <Link to="#" title="Mute"
                        onClick={() => muteAudio()}
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/audio-unmute.svg"
                          }
                        />
                      </Link>
                    </li>
                    <li className='media'
                      id="unmute-audio"
                      style={{ display: "none" }}
                    >
                      <Link to="#" onClick={() => muteAudio()} title="Unmute">
                        <Image
                          src={
                            window.location.origin +
                            "/images/live-streaming/mic-mute.svg"
                          }
                        />
                      </Link>
                    </li>
                  </>
                  :
                  ""
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}


const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
  userDetails: state.otherUser.userDetails,
  liveEnd: state.liveVideo.liveEnd,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveVideoStream));
