import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Image, Media } from "react-bootstrap";
import { connect } from "react-redux";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import { unFollowUserStart } from "../../store/actions/FollowAction";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import { useTranslation, withTranslation } from 'react-multi-lang';
import { confirmAlert } from 'react-confirm-alert';

const UserCard = (props) => {

  const t = useTranslation();

  const handleBlockUser = () => {
    props.dispatch(
      saveBlockUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleUnfollowUserConfirmation = () => {
    confirmAlert({
      message: t("unfollow_confirmation"),
      buttons: [
        {
          label: t("yes"),
          onClick: () =>
            props.dispatch(
              unFollowUserStart({
                user_id: props.user.user_id,
              })
            )
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage(
      t('profile_link_copied')));
  };

  return (
    <>
      <div className="follower-lists">
        <div className="follower-subscription-lists">
          <div className="follower-subscription-inner">
            <Link to={`/` + props.user.user_unique_id}>
              <div className="follower-wrapper">
                <Image className="follower-cover" src={props.user.cover} />
              </div>
            </Link>
            <div className="follower-profile-header">
              <Link to={`/` + props.user.user_unique_id}>
                <span className="follower-profile-avatar">
                  <Image src={props.user.picture} className="" />
                </span>
              </Link>
              <div className="follower-info">
                <div className="follower-profile-status">
                  <div className="follower-profile-toggle-dropdown">
                    <Dropdown className="btn dropdown-toggle btn-link">
                      <Dropdown.Toggle
                        className="user-dropdown-dots dropdown-toggle"
                        type="button"
                        id="dropdown-basic"
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/images/vertical-dots-white.svg"
                          }
                          className="svg-clone vertical-dots"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <CopyToClipboard
                          text={props.user.share_link}
                          onCopy={onCopy}
                        >
                          <li className="media">
                            <Link to="#"> {t("copy_link_to_profile")} </Link>
                          </li>
                        </CopyToClipboard>

                        <li className="media">
                          <Link
                            to="#"
                            onClick={(event) =>
                              handleBlockUser(event)
                            }
                          >
                            {props.user.is_block_user == 1 ?
                              <span>{t("unblock_the_user")}</span>
                              :
                              <span> {t("block_the_user")}</span>
                            }
                          </Link>
                        </li>
                        {props.user.show_follow === 1 ?
                          <li className="media">
                            <Link
                              to="#"
                              onClick={(event) =>
                                handleUnfollowUserConfirmation(event)
                              }
                            >
                              {t("unfollow")}
                            </Link>
                          </li>
                          :
                          <li>
                            <Link to={`/` + props.user.user_unique_id}>
                              {t("follow")}
                            </Link>
                          </li>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="follower-wrapper-name">
                  <div className="follower-profile-names">
                    <div className="follower-name-row">
                      <Link to={`/` + props.user.user_unique_id}>
                        <div className="follower-user-name">
                          {props.user.name}{" "}
                          {props.user.is_verified_badge == 1 ? (
                            <VerifiedBadgeNoShadow />
                          ) : null}
                        </div>
                      </Link>
                    </div>
                    <div className="follower-name-row">
                      <Link
                        to={`/` + props.user.user_unique_id}
                        className="g-user-realname__wrapper"
                      >
                        <div className="follower-user-id">
                          @{props.user.username}
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="group-follower-btns">
                    <CopyToClipboard
                      text={props.user.share_link}
                      onCopy={onCopy}
                    >
                      <Button
                        type="button"
                        className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                      >
                        <Image
                          src="/images/share.svg"
                          className="svg-clone "
                        />
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  unFollowUser: state.follow.unFollowUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(UserCard));