import React, { useState, useEffect } from "react";
import "./Profile.css";
import { Link } from "react-router-dom";
import { Row, Col, Image, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";
import { fetchSingleUserStart } from "../../store/actions/UserAction";
import Skeleton from "react-loading-skeleton";
import LiveHistory from "../LandingPage/LiveHistory";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import Page404 from "../Helper/Page404";
import {
  followUserStart,
  unFollowUserStart
} from "../../store/actions/FollowAction";

const OtherProfile = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleUserStart({
        user_unique_id: params.user_unique_id,
      }))
  }, [params]);

  return (
    <>
      <div className="other-profile-sec">
        <div className="other-profile-banner"></div>
        <Container>
          {props.singleUser.loading ?
            <div className="other-profile-img-sec">
              <Row className="justify-content-center">
                <Col md={4}>
                  <div className="other-profile-content">
                    <Skeleton className="mb-3" circle={true} count={1} width={144} height={144} />
                    <h3> <Skeleton count={1} /></h3>
                    <p> <Skeleton count={1} /></p>
                    <h4><Skeleton count={1} /></h4>
                  </div>
                </Col>
              </Row>
            </div>
            :
            Object.keys(props.singleUser.data).length > 0 && props.singleUser.data.user ?
              <div className="other-profile-img-sec">
                <Row className="justify-content-center">
                  <Col md={4}>
                    <div className="other-profile-content">
                      <CustomLazyLoad
                        src={props.singleUser.data.user.picture ?
                          props.singleUser.data.user.picture :
                          window.location.origin + "/images/profile/profile.png"}
                        placeholderSrc={window.location.origin +
                          "/images/loading.svg"}
                      />
                      <h3>{props.singleUser.data.user.name}</h3>
                      <p>{props.singleUser.data.user.name}</p>
                      <div className="profile-btn">
                        {props.singleUser.data.is_following ?
                          <Button
                            onClick={() => {
                              props.dispatch(
                                unFollowUserStart({
                                  user_id: props.singleUser.data.user.user_id,
                                })
                              );
                            }}
                          >
                            {t("unfollow")}
                          </Button>
                          :
                          <Button
                            onClick={() => {
                              props.dispatch(
                                followUserStart({
                                  user_id: props.singleUser.data.user.user_id,
                                })
                              );
                            }}
                          >
                            {t("follow")}
                          </Button>
                        }
                      </div>
                      <div className="other-profile-follower">
                        <h4>
                          <Link to="/fans">
                            {props.singleUser.data.total_followers} <span>{t("fans")}</span>
                          </Link>
                        </h4>
                        <h4>
                          <Link to="/following">
                            {props.singleUser.data.is_following} <span>{t("following")}</span>
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="other-profile-card-list">
                  <LiveHistory />
                </div>
              </div>
              :
              <Page404 />
          }
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  singleUser: state.users.singleUser,
  followUser: state.follow.followUser,
  unFollowUser: state.follow.unFollowUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(OtherProfile));
