import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchListsDetailsStart } from "../../store/actions/UserAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const ListIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchListsDetailsStart());
  }, []);

  return (
    <div className="lists">
      <Container>
        <Row>
          <Col sm={12} md={12}>
            {props.lists.loading ? (
              <div className="vertical-menu">
                {[...Array(4)].map((e, i) => (
                  <div className="user-lists">
                    <div className="pull-left">
                      <h3>
                        <Skeleton count="2" width={"200px"} />
                      </h3>
                    </div>
                    <div className="pull-right"></div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="vertical-menu">
                <div className="bookmarkes-list bookmarks-right-side ">
                <h3>
                      <Link
                        to="#"
                        className="bookmarkes-list"
                        onClick={() => navigate(-1)}
                      >
                        <Image src={window.location.origin + "/images/backs.svg"}
                          className="svg-clone"
                        />
                        {t("lists")}
                      </Link>
                    </h3>
                </div>

                <div className="user-lists">
                  <Link to={"/fans"}>
                    <div className="pull-left">
                      <h3>{t("fans")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_followers} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>

                <div className="user-lists">
                  <Link to={"/following"}>
                    <div className="pull-left">
                      <h3>{t("following")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_followings} {t("people")}
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="user-lists">
                  <Link to={"/bookmarks"}>
                    <div className="pull-left">
                      <h3>{t("bookmarks")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.total_bookmarks} {t("shows")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>
                <div className="user-lists">
                  <Link to={"/blocked-users"}>
                    <div className="pull-left">
                      <h3>{t("blocked_users")}</h3>
                      <span className="user-list-count">
                        {props.lists.data.blocked_users} {t("people")}
                      </span>
                    </div>
                    <div className="pull-right"></div>
                  </Link>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  lists: state.users.lists,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ListIndex));
