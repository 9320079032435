import React, { useState } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";
import Slider from "react-slick";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import * as Yup from "yup";
import { resetPasswordStart, forgotPasswordStart } from "../../store/actions/UserAction";
import { useEffect } from "react";

const ForgotPasswordIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);

  const handleForgotPassword = (values) => {
    props.dispatch(forgotPasswordStart(values));
  };
  const forgotpwdSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_address"))
      .required(t("email_required")),
  });

  useEffect(() => {
    if (!skipRender && !props.forgotPassword.loading && props.forgotPassword.data.success == true) {
      navigate("/verify-forget-password");
    }
    setSkipRender(false)
  }, [props.forgotPassword]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
  };

  return (
    <>
      <div className="auth-page-sec">
        <div className="auth-page-left-sec">
          <Slider {...settings}>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-1.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-2.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-3.jpeg"}
              />
            </div>
          </Slider>
          <div className="auth-nav-switch-sec">
            <Link to="/login">Login</Link>
            <Link to="/register" className="active">Register</Link>
          </div>
        </div>
        <div className="auth-page-right-sec">
          <div className="auth-details-sec">
            <div className="auth-logo-sec">
              <Image
                className="auth-logo"
                src={window.location.origin + "/images/logo-black.png"}
              />
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotpwdSchema}
              onSubmit={(values) => handleForgotPassword(values)}
            >
              <FORM className="auth-form-sec">
                <div className="auth-info">
                  <h2>{t("forgot_password")}</h2>
                </div>
                <div className="auth-form">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder={t("email_address")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="text-danger"
                    />
                  </Form.Group>
                  <div className="auth-btn-sec">
                    <Button className="default-btn"
                      type="submit"
                      disabled={props.forgotPassword.buttonDisable}
                    >
                      {props.forgotPassword.loadingButtonContent !== null ?
                        props.forgotPassword.loadingButtonContent :
                        t("forgot_password")
                      }
                    </Button>
                  </div>
                  <div className="auth-footer-link-sec">
                    <Link to="/login">Login for WhyNot</Link>
                  </div>
                </div>
              </FORM>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ForgotPasswordIndex));
