import React, { Component } from "react";

class VideoCallLayout extends Component {
  state = {};
  render() {
    return (
        <div className="videocall-wrapper">
          {React.cloneElement(this.props.children)}
        </div>
    );
  }
}

export default VideoCallLayout;
