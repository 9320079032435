import React from 'react'
import { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import "./Order.css";
import { ordersViewForOthersStart, cancelOrderStart } from '../../store/actions/OrderAction';
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../Helper/NoDataFound';
import { confirmAlert } from 'react-confirm-alert';

const OrderView = (props) => {

  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(ordersViewForOthersStart({
      order_unique_id: params.unique_id,
    }))
  }, []);

  const cancelOrderConfirmation = (order_id) => {
    confirmAlert({
      message: t("delete_product_confirmation"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => props.dispatch(cancelOrderStart({ order_id: order_id }))
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  return (
    <>
      <div className="order-view-sec">
        <Container>
          <div className="single-page-title">
            <Link to="#" onClick={() => navigate(-1)}>
              <Image src={window.location.origin + "/images/back.svg"} />
            </Link>
            <h3>{t("order_view")}</h3>
          </div>
          {props.orderDetail.loading ? (
            <Row>
              <Col md={6}>
                <Skeleton count={1} height={320} width={320} />
                <Skeleton className='mb-1' count={5} height={50} />

              </Col>
              <Col md={6}>
                <Skeleton count={1} height={320} />
                <Skeleton className='mb-1' count={4} height={50} />
              </Col>
            </Row>
          ) : (
            Object.keys(props.orderDetail.data).length > 0 ?
              <>
                <Row>
                  <Col md={6}>
                    {props.orderDetail.data.order.order_product.length > 0 ?
                      props.orderDetail.data.order.order_product.map((product, index) => (
                        <>
                          <div className="order-view-card">
                            <div className="order-view-img-sec" key={index}>
                              <Image
                                className="order-view-img"
                                src={product.user_product_details.picture}
                              />
                            </div>
                            <div className="order-view-info">
                              <h4>
                                {t("product_name")} :{" "}
                                <span>
                                  <Link to={"/single-product/" + product.user_product_details.unique_id}>{product.user_product_details.name}</Link>
                                </span>

                              </h4>
                              <h4>
                                {t("quantity")} :{" "}
                                <span>
                                  {product.quantity}
                                </span>
                              </h4>
                              <h4>
                                {t("list_price")} :{" "}
                                <span>
                                  {product.sub_total_formatted}
                                </span>
                              </h4>
                              <h4>
                                {t("order_date")} : <span>{props.orderDetail.data.order.order_payment.paid_date_formatted}</span>
                              </h4>
                              <h4 className="border-bottom-zero">
                                {t("payment_method")} :{" "}
                                <span>
                                  {
                                    props.orderDetail.data.order
                                      .order_payment.payment_mode
                                  }
                                </span>
                              </h4>
                            </div>
                          </div>
                        </>
                      ))
                      : null
                    }
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        {props.orderDetail.data.order.order_payment ? (
                          <div className="order-view-summary-sec">
                            <h3 className="sub-title">{t("order_summary")}</h3>
                            <div className="product-details-body-sec">
                              {props.orderDetail.data.order.delivery_address ?
                                <div className="product-details-card">
                                  <h5>{t("shipping_address")}</h5>
                                  <p className="product-amount text-right">
                                    <span>
                                      {
                                        props.orderDetail.data.order
                                          .delivery_address.name
                                      }{" "}
                                      ,
                                      <br />
                                      {
                                        props.orderDetail.data.order
                                          .delivery_address.landmark
                                      }{" "}
                                      , <br />
                                      {
                                        props.orderDetail.data.order
                                          .delivery_address.address
                                      }{" "}
                                      -{" "}
                                      {
                                        props.orderDetail.data.order
                                          .delivery_address.pincode
                                      }{" "}

                                      <br />
                                      {
                                        props.orderDetail.data.order
                                          .delivery_address.contact_number
                                      }{" "}
                                    </span>
                                  </p>
                                </div>
                                : ""}
                              <div className="product-details-card">
                                <h5>{t("order_id")}</h5>
                                <p className="product-amount">
                                  <span>
                                    {
                                      props.orderDetail.data.order
                                        .unique_id
                                    }{" "}
                                  </span>
                                </p>
                              </div>
                              <hr></hr>
                              <h3 className="sub-title">{t("price_details")}</h3>
                              <div className="product-details-card">
                                <h5>{t("list_price")}</h5>
                                <p className="product-amount">{props.orderDetail.data.order.order_payment.sub_total_formatted}</p>
                              </div>
                              <div className="product-details-card">
                                <h5>{t("shipping_fee")}</h5>
                                <p className="product-amount">{props.orderDetail.data.order.order_payment.delivery_price_formatted}</p>
                              </div>
                              <div className="product-details-card">
                                <h5>{t("taxes")}</h5>
                                <p className="product-amount">{props.orderDetail.data.order.order_payment.tax_price_formatted}</p>
                              </div>
                            </div>
                            <div className="product-details-footer-sec">
                              <h5>{t("total")}</h5>
                              <div className="product-details-final-amount">
                                <p>{props.orderDetail.data.order.order_payment.total_formatted}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="cancel-order mt-3">
                          {props.orderDetail.data.order.order_btn_status.cancel_btn_status ?
                            <Button
                              type="button"
                              className="order-view-btn mt-3 w-100"
                              onClick={() => {
                                cancelOrderConfirmation(
                                  props.orderDetail.data.order.id)
                              }}
                            >
                              {t("cancel")}
                            </Button>
                            : ''}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="track">
                          <div className={props.orderDetail.data.order.status >= 1 ? "step active" : "step"}>
                            {" "}
                            <span className="icon">
                              {" "}
                              <i className="fa fa-check"></i>{" "}
                            </span>{" "}
                            <span className="text">{t("order_placed")}</span>{" "}
                          </div>
                          <div className={props.orderDetail.data.order.order_status_formatted == "Approved" || props.orderDetail.data.order.status == 4 ? "step active" : "step"}>
                            {" "}
                            <span className="icon">
                              {" "}
                              <i className="fa fa-check"></i>{" "}
                            </span>{" "}
                            <span className="text">{props.orderDetail.data.order.order_status_formatted}</span>{" "}
                          </div>

                          <div className={props.orderDetail.data.order.status >= 2 && props.orderDetail.data.order.order_status_formatted != "Cancelled" ? "step active" : "step"}>
                            {" "}
                            <span className="icon">
                              {" "}
                              <i className="fa fa-truck"></i>{" "}
                            </span>{" "}
                            <span className="text">{t("order_shipped")}</span>{" "}
                          </div>
                          <div className={props.orderDetail.data.order.status == 3 ? "step active" : "step"}>
                            {" "}
                            <span className="icon">
                              {" "}
                              <i className="fa fa-box"></i>{" "}
                            </span>{" "}
                            <span className="text">{t("delivered")}</span>{" "}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
              :
              <NoDataFound />
          )}
        </Container>
      </div>

    </>
  )
}

const mapStateToPros = (state) => ({
  orderDetail: state.order.ordersViewForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(OrderView));