import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  fetchCategoriesFollowStart,
  fetchMoreCategoriesFollowStart
} from "../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import CategoryFollowLoader from "../Helper/CategoryFollowLoader";
import LiveVideoCard from '../Helper/LiveVideoCard';

const FollowingCategories = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchCategoriesFollowStart({
      skip: 0,
      take: 12
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreCategoriesFollowStart({
      skip: props.categoriesFollow.data.category_live_videos.length,
      take: 12,
    }));
  }

  return (
    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
          <div className="new-card-head pt-8">
            <h3 className="sub-catdata">{t("live_shows")}</h3>
          </div>
          <div className="category-card-sec mt-4">
            {props.categoriesFollow.loading ?
              <CategoryFollowLoader />
              :
              Object.keys(props.categoriesFollow.data).length > 0 &&
                props.categoriesFollow.data.category_live_videos.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.categoriesFollow.data.category_live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.categoriesFollow.data.category_live_videos.length <
                    props.categoriesFollow.data.total_category_live_videos
                  }
                  loader={<CategoryFollowLoader />}
                >
                  <div className="category-card-box">
                    {props.categoriesFollow.data.category_live_videos.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  categoriesFollow: state.homepage.categoriesFollow,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(FollowingCategories));
