import React, { useEffect, useState, useRef, useTransition } from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import io from "socket.io-client";
import config from "react-global-configuration";
import { connect } from "react-redux";
import { addLiveVideoMessageContent } from "../../store/actions/LiveVideoAction";
import {
  Form,
  Col,
  Image,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { format } from "timeago.js";
import "../VideoCall/VideoCall.css"

let chatSocket;

const LiveVideoChat = (props) => {
  const [inputMessage, setInputMessage] = useState("");
  const [initialHeight, setInitialHeight] = useState(0);
  const t = useTranslation();
  const [emptyMessageCheck, setEmptyMessageCheck] = useState(false);

  const invalidMessageRef = useRef(null);
 
  const messageRef = useRef();

  useEffect(() => {
    chatSocketConnect();
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [!props.loadingChatData]);

  useEffect(() => {
    if (messageRef) {
      messageRef.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [])

  // Scroll down function..
  useEffect(() => {
    const objDiv = document.getElementById("options-holder");
    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      if (differenceNumber > 280) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = initialHeight;
        setInitialHeight(initialHeight + 20);
      }
    }
  }, [props.chatMessages.data.messages]);

  const chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    if (chatSocketUrl && Object.keys(props.liveVideoData).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + props.liveVideoData.virtual_id + `'`,
      });

      chatSocket.emit("update livevideo", {
        room: props.liveVideoData.virtual_id,
      });
      let chatContent;
      chatSocket.on("livevideo message", (newData) => {
        let content = [];
        content.push(newData);
        props.dispatch(addLiveVideoMessageContent(content));
      });
    }
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();
    if (inputMessage.length == 0) {
      setEmptyMessageCheck(true);
    }

    if (inputMessage.trim() !== "") {
      let chatData = [
        {
          live_video_id: props.liveVideoData.live_video_id,
          user_id: localStorage.getItem("userId"),
          from_user_id: localStorage.getItem("userId"),
          message: inputMessage,
          from_username: localStorage.getItem("username"),
          from_userpicture: localStorage.getItem("user_picture"),
          created: Date(),
        },
      ];
      let chatSocketUrl = config.get("configData.chat_socket_url");

      if (chatSocketUrl && Object.keys(props.liveVideoData).length > 0) {
        chatSocket.emit("livevideo message", chatData[0]);
      }
      let messages;

      if (props.chatMessages.data.messages != null) {
        messages = [...props.chatMessages.data.messages, ...chatData];
      } else {
        messages = [...chatData];
      }
      console.log(chatData);

      setInputMessage("");
      props.dispatch(addLiveVideoMessageContent(chatData));
    }
  };

  const chatInputChange = (value) => {
    setInputMessage(value);
    if (inputMessage.length > 0) {
      setEmptyMessageCheck(false);
    }
  };
  return (
    <>
      <Col md={3} className="p-0">
        {props.chatMessages.loading ? (
          <Skeleton count={1} />
        ) : (
          <div className="right-side" ref={messageRef}>
            <div className="chat-container">
              <div className="chat-header-new">{t("live_comments")}</div>
              <div
                className="scroll-comment-sec"
                id="commentScrollDiv"
                initialScrollBehavior="smooth"
                style={{
                  maxHeight: "calc(100vh - 350px)",
                  minHeight: "400px",
                  overflowY: "auto",
                }}
              >
                <div className="chat-area">
                  {Object.keys(props.chatMessages.data).length > 0 &&
                    props.chatMessages.data.messages.length > 0
                    ? props.chatMessages.data.messages.map((chatMessage, index) => (
                      <>
                        {chatMessage.from_user_id !==
                          localStorage.getItem("userId") ? (
                          <div className="live-chat-profile" key={index}>
                            <div className="live-chat-img">
                              <div className="live-chat-profile-img">
                                <Link
                                  to={`/` + chatMessage.from_user_unique_id}
                                  className=""
                                >
                                  <Image
                                    src={chatMessage.from_userpicture}
                                  />
                                </Link>
                              </div>
                              <div className="live-chat-profile-content" >
                                <h5>
                                  <Link
                                    to={`/` + chatMessage.from_user_unique_id}
                                    className=""
                                  >
                                    {chatMessage.from_username}
                                  </Link>
                                </h5>
                                <p>
                                  {chatMessage.message}
                                </p>
                              </div>
                            </div>
                            <div className="live-chat-time">
                              <p> {format(chatMessage.created)}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="live-chat-profile reverse" key={index}>
                            <div className="live-chat-img">
                              <div className="live-chat-profile-img">
                                <Image
                                  src={chatMessage.from_userpicture}
                                />
                              </div>
                              <div className="live-chat-profile-content">
                                <h5>{chatMessage.from_username}</h5>
                                <p>
                                  {chatMessage.message}
                                </p>
                              </div>
                            </div>
                            <div className="live-chat-time">
                              <p> {format(chatMessage.created)}</p>
                            </div>
                          </div>
                        )}{" "}
                      </>
                    ))
                    : ""}
                </div>
              </div>
              <div className="chat-typing-area-wrapper">
                <Form
                  id="chat_post_form"
                  className="has-advanced-upload"
                  onSubmit={handleChatSubmit}
                >
                  <div className="chat-typing-area">
                    <Form.Control
                      type="text"
                      name="text"
                      placeholder={t("say_something")}
                      className="chat-input"
                      value={inputMessage}
                      ref={invalidMessageRef}
                      disabled={props.liveVideoData.is_streaming !== 1}
                      onChange={(event) => {
                        chatInputChange(event.currentTarget.value);
                      }}
                    />
                    <button
                      type="submit"
                      className="send-button"
                      onClick={handleChatSubmit}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-send"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
                      </svg>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Col>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveVideoChat));
