import React, { useState, useEffect } from "react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changePasswordStart } from "../../store/actions/UserAction";
import "./Profile.css";
import ProfileSidebar from "./ProfileSidebar";

const ChangePasswordIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  })

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")).required(t("required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required")),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("passwords_need_to_be_same")
        ),
      }),
  });
  useEffect(() => {
    if (!skipRender &&
      !props.changePassword.loading &&
      props.changePassword.data.success === true &&
      props.changePassword.data.code === 104) {
      navigate("/logout");
    }
    setSkipRender(false);
  }, [props.changePassword]);

  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  }

  return (
    <>
      <div className="home-page-sec">
        <Container fluid className="p-0">
          <div className="new-category-sec">
            <div className="new-category-left-sec">
              <ProfileSidebar />
            </div>
            <div className="new-category-right-sec">
              <div className="profile-sidebar-right-sec">
                <div className="profile-sidebar-right-detail-sec">
                  <div className="profile-right-box">
                    <h3>{t("change_password")}</h3>
                    <p>{t("set_password_para")}</p>
                  </div>
                  <div className="profile-details-form">
                    <Formik
                      initialValues={{
                        old_password: "",
                        password: "",
                        password_confirmation: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      <FORM>
                        <Row>
                          <Col md={6}>
                            <Form.Label>{t("old_password")}*</Form.Label>
                            <InputGroup className="mb-3">
                              <Field
                                type={showPassword.oldPassword ? "text" : "password"}
                                name="old_password"
                                placeholder={t("enter_your_old_password")}
                                className="form-control"
                              />
                              <InputGroup.Text className="delete-passwd">
                                <button
                                  onClick={() => setShowPasswordVisible({
                                    ...showPassword,
                                    oldPassword: !showPassword.oldPassword
                                  })}
                                  className="btn password-eye"
                                  type="button"
                                >{showPassword.oldPassword ?
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                                  />
                                  :
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                                  />
                                  }
                                </button>
                              </InputGroup.Text>
                              <ErrorMessage
                                component={"div"}
                                name="old_password"
                                className="text-danger pass-txt"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label>{t("new_password")}*</Form.Label>
                            <InputGroup className="mb-3">
                              <Field
                                type={showPassword.newPassword ? "text" : "password"}
                                name="password"
                                placeholder={t("enter_your_new_password")}
                                className="form-control"
                              />
                              <InputGroup.Text className="delete-passwd">
                                <button
                                  onClick={() => setShowPasswordVisible({
                                    ...showPassword,
                                    newPassword: !showPassword.newPassword
                                  })}
                                  className="btn password-eye"
                                  type="button"
                                >{showPassword.newPassword ?
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                                  />
                                  :
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                                  />
                                  }
                                </button>
                              </InputGroup.Text>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-danger pass-txt pass-txt"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label>{t("confirm_password")}*</Form.Label>
                            <InputGroup className="mb-3">
                              <Field
                                type={showPassword.confirmPassword ? "text" : "password"}
                                name="password_confirmation"
                                placeholder={t("confirm_password")}
                                className="form-control"
                              />
                              <InputGroup.Text className="delete-passwd">
                                <button
                                  onClick={() =>
                                    setShowPasswordVisible({
                                      ...showPassword,
                                      confirmPassword: !showPassword.confirmPassword,
                                    })}
                                  className="btn password-eye"
                                  type="button"
                                >{showPassword.confirmPassword ?
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                                  />
                                  :
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                                  />
                                  }
                                </button>
                              </InputGroup.Text>
                              <ErrorMessage
                                component={"div"}
                                name="password_confirmation"
                                className="text-danger pass-txt"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <div className="submit-btn">
                          <Button type="submit"
                            className="default-btn"
                            disabled={props.changePassword.buttonDisable}
                          >
                            {props.changePassword.loadingButtonContent !== null ?
                              props.changePassword.loadingButtonContent :
                              t("change_password")
                            }
                          </Button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  changePassword: state.users.changePassword,
})
function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ChangePasswordIndex));
