import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import {
  fetchOrderPaymentListStart,
  fetchMoreOrderPaymentListStart,
} from "../../store/actions/OrderAction";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import TableOrderLoader from "../Helper/TableOrderLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";

const OrderTransaction = (props) => {

  const navigate = useNavigate();
  const t = useTranslation();
  const [search, setSearch] = useState("")

  useEffect(() => {
    props.dispatch(fetchOrderPaymentListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreOrderPaymentListStart({
      skip: props.orderPayments.data.orders.length,
      take: 12,
    }));
  }

  const handleChange = (event) => {
    setSearch(event.target.value);
    props.dispatch(fetchOrderPaymentListStart({
      search_key: event.target.value,
    }))
  };

  const clearItem = () => {
    setSearch("");
    props.dispatch(fetchOrderPaymentListStart({
      skip: 0,
      take: 12,
    }));
  }

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <div className="single-page-desc">
                <h3>{t("overview")}</h3>
              </div>
            </div>
            <div className="search-end-sec">
              <Form>
                <div className="tab-search-end">
                  <div className="search-box">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            window.location.origin +
                            "/images/order/search.svg"
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={search}
                        onChange={(event) => handleChange(event)}
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        className="search-close"
                        style={{
                          border: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {search ?
                          <span onClick={clearItem}>
                            <i class="fa-solid fa-xmark"></i>
                          </span>
                          :
                          null
                        }
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="add-bag-img">
                    <Link to="#">
                      <Image
                        src={
                          window.location.origin +
                          "/images/order/bag.svg"
                        }
                      />
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div className="order-transaction-sec">
            <div className="order-list-table">
              {props.orderPayments.loading
                ?
                <TableOrderLoader />
                :
                Object.keys(props.orderPayments.data).length > 0 &&
                  props.orderPayments.data.orders.length > 0
                  ?
                  <InfiniteScroll
                    dataLength={props.orderPayments.data.orders.length}
                    next={fetchMoreData}
                    hasMore={
                      props.orderPayments.data.orders.length <
                      props.orderPayments.data.total}
                    loader={<TableOrderLoader />}
                  >
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>{t("date")}</th>
                          <th>{t("order_id")}</th>
                          <th>{t("payment_id")}</th>
                          <th>{t("mode")}</th>
                          <th>{t("amount")}</th>
                          <th>{t("delivery_fee")}</th>
                          <th>{t("taxes")}</th>
                          <th>{t("total")}</th>
                          <th>{t("status")}</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.orderPayments.data.orders.map(
                          (transaction, index) => (
                            <tr key={index} >
                              <td>
                                <h5>{transaction.order_payment.paid_date_formatted}</h5>
                              </td>
                              <td> {transaction.unique_id}</td>
                              <td>{transaction.order_payment.payment_id}</td>
                              <td> {transaction.order_payment.payment_mode}</td>
                              <td>
                                <h5> {transaction.order_payment.sub_total_formatted}</h5>
                              </td>
                              <td>
                                <h5>{transaction.order_payment
                                  .delivery_price_formatted}</h5>
                              </td>
                              <td>
                                <h5>{transaction.tax_price_formatted}</h5>
                              </td>
                              <td>
                                <h5>{
                                  transaction.total_formatted
                                }</h5>
                              </td>
                              <td>
                                <h5>{transaction.order_payment.status ==
                                  1 ? (
                                  <>
                                    <p>
                                      <i className="far fa-check-circle mr-2"></i>
                                      {t('success')}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <i className="far fa-times-circle text-danger mr-2"></i>
                                      {t('failed')}
                                    </p>
                                  </>
                                )}</h5>
                              </td>
                              <td>
                                <Link
                                  type="button"
                                  className="order-view-btn mr-3"
                                  to={`/order-view/${transaction.unique_id}`}
                                >
                                  {t("view")}
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  orderPayments: state.order.orderPayments,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderTransaction));

