import React from 'react'
import Skeleton from "react-loading-skeleton";

const SubCategoryLoader = () => {
  return (
    <>
      <div className="new-category-header-sec">
        <Skeleton count={1} height={50} width={180} />
        <Skeleton count={1} height={50} width={75} />
      </div>
      <div className="category-card-box">
        {[...Array(5)].map((i) => (
          <div className="category-card">
            <Skeleton count={1} height={217} width={210} />
            <div className="category-card-profile">
              <>
                <Skeleton count={1} width={100} />
                <Skeleton count={1} width={150} />
                <Skeleton count={1} width={100} />
              </>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default SubCategoryLoader;