import {
    FETCH_LIVE_VIDEOS_PRODUCTS_START,
    FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
    FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
    FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START,
    FETCH_LIVE_VIDEOS_HISTORY_START,
    FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
    FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
    FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
    FETCH_LIVE_VIDEO_ORDERS_START,
    FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
    FETCH_LIVE_VIDEO_ORDERS_FAILURE,
    FETCH_MORE_LIVE_VIDEO_ORDERS_START,
    LIVE_VIDEO_CALL_BROADCAST_START,
    LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
    LIVE_VIDEO_CALL_BROADCAST_FAILURE,
    LIVE_VIDEOS_START_CALL_START,
    LIVE_VIDEOS_START_CALL_SUCCESS,
    LIVE_VIDEOS_START_CALL_FAILURE,
    FETCH_LIVE_VIDEOS_START,
    FETCH_LIVE_VIDEOS_SUCCESS,
    FETCH_LIVE_VIDEOS_FAILURE,
    LIVE_VIDEOS_END_START,
    LIVE_VIDEOS_END_SUCCESS,
    LIVE_VIDEOS_END_FAILURE,
    FETCH_SINGLE_LIVE_VIDEOS_START,
    FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
    FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
    LIVE_VIDEOS_VIEWER_UPDATE_START,
    LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
    LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
    ADD_LIVE_VIDEO_MESSAGE_CONTENT,
    FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
    FETCH_LIVE_VIDEO_MESSAGE_START,
    FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE,
    FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START,
} from "./ActionConstant";

export function videoCallBroadcastStart(data) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_START,
        data,
    };
}

export function videoCallBroadcastSuccess(data) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
        data,
    };
}

export function videoCallBroadcastFailure(error) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_FAILURE,
        error,
    };
}

export function liveVideoStartCallStart(data) {
    return {
        type: LIVE_VIDEOS_START_CALL_START,
        data,
    };
}

export function liveVideoStartCallSuccess(data) {
    return {
        type: LIVE_VIDEOS_START_CALL_SUCCESS,
        data,
    };
}

export function liveVideoStartCallFailure(error) {
    return {
        type: LIVE_VIDEOS_START_CALL_FAILURE,
        error,
    };
}

export function fetchLiveVideosStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchLiveVideosSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchLiveVideosFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_FAILURE,
        error,
    };
}

export function liveVideoEndStart(data) {
    return {
        type: LIVE_VIDEOS_END_START,
        data,
    };
}

export function liveVideoEndSuccess(data) {
    return {
        type: LIVE_VIDEOS_END_SUCCESS,
        data,
    };
}

export function liveVideoEndFailure(error) {
    return {
        type: LIVE_VIDEOS_END_FAILURE,
        error,
    };
}

export function fetchLiveVideosProductsStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_START,
        data,
    };
}

export function fetchLiveVideosProductsSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
        data,
    };
}

export function fetchLiveVideosProductsFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
        error,
    };
}

export function fetchMoreLiveVideosProductsStart(data) {
    return {
        type: FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START,
        data,
    };
}

export function fetchLiveVideosHistoryStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_START,
        data,
    };
}

export function fetchLiveVideosHistorySuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
        data,
    };
}

export function fetchLiveVideosHistoryFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
        error,
    };
}

export function fetchMoreLiveVideosHistoryStart(data) {
    return {
        type: FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
        data,
    };
}

export function fetchLiveVideoOrdersStart(data) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_START,
        data,
    }
}

export function fetchLiveVideoOrdersSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
        data,
    }
}

export function fetchLiveVideoOrdersFailure(error) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_FAILURE,
        error,
    }
}

export function fetchMoreLiveVideoOrdersStart(data) {
    return {
        type: FETCH_MORE_LIVE_VIDEO_ORDERS_START,
        data,
    }
}

export function fetchSingleLiveVideoStart(data) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchSingleLiveVideoSuccess(data) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchSingleLiveVideoFailure(error) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
        error,
    };
}

export function liveViewerUpdateStart(data) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_START,
        data,
    };
}

export function liveViewerUpdateSuccess(data) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
        data,
    };
}

export function liveViewerUpdateFailure(error) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
        error,
    };
}

export function addLiveVideoMessageContent(data) {
    return {
        type: ADD_LIVE_VIDEO_MESSAGE_CONTENT,
        data,
    };
}

export function fetchLiveVideoMessageStart(data) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_START,
        data,
    };
}
  
export function fetchLiveVideoMessageSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
        data,
    };
  }
  
export function fetchLiveVideoMessageFailure(error) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
        error,
    };
  }

  export function fetchLiveVideosScheduledOwnerStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
        data,
    };
}

export function fetchLiveVideosScheduledOwnerSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS,
        data,
    };
}

export function fetchLiveVideosScheduledOwnerFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE,
        error,
    };
}

export function fetchMoreLiveVideosScheduledOwnerStart(data) {
    return {
        type: FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START,
        data,
    };
}
