import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Table, Row, Button, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  deleteDeliveryAddressStart,
  fetchDeliveryAddressStart,
  setDefaultDeliveryAddressStart,
} from '../../store/actions/DeliveryAddressAction';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../Helper/NoDataFound';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';



const DeliveryAddressIndex = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchDeliveryAddressStart());
  }, []);

  const makeDefaultConfirmation = (delivery_address_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_make_this_as_default"),
      buttons: [
        {
          label: t("yes"),
          onClick: () =>
            props.dispatch(setDefaultDeliveryAddressStart({
              delivery_address_id: delivery_address_id,
            }))
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const deleteConfirmation = (delivery_address_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_delete_this_address"),
      buttons: [
        {
          label: t("yes"),
          onClick: () =>
            props.dispatch(deleteDeliveryAddressStart({
              delivery_address_id: delivery_address_id,
            }))
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  return (
    <Container>
      <div className="bank-account-list-card">
        <Row>
          <Col md={12}>
            <div className="bank-account-list-btn">
              <Link to="/add-delivery-address" className="add-new-account-btn">
                {t("add_delivery_address")}
              </Link>
            </div>
            {props.deliveryAddress.loading ?
              <Skeleton count={4} height={50} />
              : Object.keys(props.deliveryAddress.data).length > 0 &&
                props.deliveryAddress.data.delivery_addresses.length > 0 ?
                <Table responsive className="bank-account-list-table">
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th>{t("address")}</th>
                      <th>{t("pincode")}</th>
                      <th>{t("state")}</th>
                      <th>{t("landmark")}</th>
                      <th>{t("contact_number")}</th>
                      <th>{t("is_default")}</th>
                      <th>{t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.deliveryAddress.data.delivery_addresses.map(
                      (address) =>
                        <tr>
                          <td>{address.name}</td>
                          <td> {address.address}</td>
                          <td> {address.pincode}</td>
                          <td>{address.state} </td>
                          <td> {address.landmark ? address.landmark : t("n_a")}</td>
                          <td>{address.contact_number ? address.contact_number : t("n_a")}</td>
                          {address.is_default === 1 ? (
                            <td>
                              <Badge className="confirm success-badge">
                                {t("yes")}
                              </Badge>
                            </td>
                          ) : (
                            <td>
                              <Badge className="unconfirmed-badge default">
                                {t("no")}
                              </Badge>
                            </td>
                          )}
                          <td>
                            <div className="btn-flex">
                              <Link to={`/edit-delivery-address/${address.delivery_address_unique_id}`}
                                className="btn btn-info">
                                {t("edit")} </Link>
                              {address.is_default === 0 ?
                                <Button
                                  type="submit"
                                  className="btn btn-warning default"
                                  onClick={() => {
                                    makeDefaultConfirmation(
                                      address.delivery_address_id)
                                  }}
                                >
                                  {t("make_as_default")}
                                </Button>
                                : null
                              }
                              <Button
                                type="submit"
                                className='btn btn-danger delete-account'
                                onClick={() => {
                                  deleteConfirmation(address.delivery_address_id)
                                }}
                              >
                                {t("delete")}
                              </Button>
                            </div>
                          </td>
                        </tr>
                    )}
                  </tbody>
                </Table>
                :
                <NoDataFound />
            }
          </Col>
        </Row>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  deliveryAddress: state.deliveryAddress.deliveryAddress,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(DeliveryAddressIndex));
