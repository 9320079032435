import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";

const HomeBanner = () => {

  const t = useTranslation();

  return (
    <div className="banner-card">
      <div className="banner-card-img">
        <Image
          src={window.location.origin + "/images/banner-img.png"}
        />
      </div>
      <div className="banner-card-content">
        <h1> {t("sell_whatever_you")} </h1>
        <h1>{t("want")}<span> {t("online")}</span></h1>
        <div className="Banner-btn">
          <Link to="/become-a-seller" className="default-blue-btn">
            {t("sell_now")}
          </Link>
        </div>
      </div>
      <div className="banner-card-img">
        <Image className="banner-image-one"
          src={window.location.origin + "/images/banner-img-1.png"}
        />
      </div>
    </div>
  )
}

export default HomeBanner;