import {
  USER_PRODUCTS_SAVE_START,
  USER_PRODUCTS_SAVE_SUCCESS,
  USER_PRODUCTS_SAVE_FAILURE,
  FETCH_PRODUCT_CATEGORIES_START,
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  FETCH_PRODUCT_CATEGORIES_FAILURE,
  FETCH_PRODUCT_SUB_CATEGORIES_START,
  FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS,
  FETCH_PRODUCT_SUB_CATEGORIES_FAILURE,
  FETCH_USER_SINGLE_PRODUCT_START,
  FETCH_USER_SINGLE_PRODUCT_SUCCESS,
  FETCH_USER_SINGLE_PRODUCT_FAILURE,
  FETCH_USER_PRODUCTS_FOR_OWNER_START,
  FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS,
  FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE,
  FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START,
  DELETE_USER_PRODUCT_START,
  DELETE_USER_PRODUCT_SUCCESS,
  DELETE_USER_PRODUCT_FAILURE,
  FETCH_SINGLE_PRODUCT_ORDERS_START,
  FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS,
  FETCH_SINGLE_PRODUCT_ORDERS_FAILURE,
  FETCH_MORE_SINGLE_PRODUCT_ORDERS_START,
  USER_PRODUCT_VIEW_FOR_OTHERS_START,
  USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS,
  USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE,
  FETCH_USER_PRODUCTS_START,
  FETCH_USER_PRODUCTS_SUCCESS,
  FETCH_USER_PRODUCTS_FAILURE
} from "./ActionConstant";

export function fetchUserProductsStart(data) {
  return {
    type: FETCH_USER_PRODUCTS_START,
    data,
  };
}

export function fetchUserProductsSuccess(data) {
  return {
    type: FETCH_USER_PRODUCTS_SUCCESS,
    data,
  };
}

export function fetchUserProductsFailure(error) {
  return {
    type: FETCH_USER_PRODUCTS_FAILURE,
    error,
  };
}

export function userProductsSaveStart(data) {
  return {
    type: USER_PRODUCTS_SAVE_START,
    data,
  };
}

export function userProductsSaveSuccess(data) {
  return {
    type: USER_PRODUCTS_SAVE_SUCCESS,
    data,
  };
}

export function userProductsSaveFailure(error) {
  return {
    type: USER_PRODUCTS_SAVE_FAILURE,
    error,
  };
}

export function fetchProductCategoriesStart(data) {
  return {
    type: FETCH_PRODUCT_CATEGORIES_START,
    data,
  };
}

export function fetchProductCategoriesSuccess(data) {
  return {
    type: FETCH_PRODUCT_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchProductCategoriesFailure(error) {
  return {
    type: FETCH_PRODUCT_CATEGORIES_FAILURE,
    error,
  };
}

export function fetchProductSubCategoriesStart(data) {
  return {
    type: FETCH_PRODUCT_SUB_CATEGORIES_START,
    data,
  };
}

export function fetchProductSubCategoriesSuccess(data) {
  return {
    type: FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchProductSubCategoriesFailure(error) {
  return {
    type: FETCH_PRODUCT_SUB_CATEGORIES_FAILURE,
    error,
  };
}

export function fetchUserSingleProductStart(data) {
  return {
    type: FETCH_USER_SINGLE_PRODUCT_START,
    data,
  };
}

export function fetchUserSingleProductSuccess(data) {
  return {
    type: FETCH_USER_SINGLE_PRODUCT_SUCCESS,
    data,
  };
}

export function fetchUserSingleProductFailure(error) {
  return {
    type: FETCH_USER_SINGLE_PRODUCT_FAILURE,
    error,
  };
}

export function fetchUserProductsForOwnerStart(data) {
  return {
    type: FETCH_USER_PRODUCTS_FOR_OWNER_START,
    data,
  };
}

export function fetchUserProductsForOwnerSuccess(data) {
  return {
    type: FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS,
    data,
  };
}

export function fetchUserProductsForOwnerFailure(error) {
  return {
    type: FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE,
    error,
  };
}

export function fetchMoreUserProductsForOwnerStart(data) {
  return {
    type: FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START,
    data,
  };
}

export function deleteUserProductStart(data) {
  return {
    type: DELETE_USER_PRODUCT_START,
    data,
  };
}

export function deleteUserProductSuccess(data) {
  return {
    type: DELETE_USER_PRODUCT_SUCCESS,
    data,
  };
}

export function deleteUserProductFailure(error) {
  return {
    type: DELETE_USER_PRODUCT_FAILURE,
    error,
  };
}

export function fetchSingleProductOrdersStart(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_ORDERS_START,
    data,
  };
}

export function fetchSingleProductOrdersSuccess(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS,
    data,
  };
}

export function fetchSingleProductOrdersFailure(error) {
  return {
    type: FETCH_SINGLE_PRODUCT_ORDERS_FAILURE,
    error,
  };
}

export function fetchMoreSingleProductOrdersStart(data) {
  return {
    type: FETCH_MORE_SINGLE_PRODUCT_ORDERS_START,
    data,
  };
}



export function userProductViewForOthersStart(data) {
  return {
    type: USER_PRODUCT_VIEW_FOR_OTHERS_START,
    data,
  };
}

export function userProductViewForOthersSuccess(data) {
  return {
    type: USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS,
    data,
  };
}

export function userProductViewForOthersFailure(error) {
  return {
    type: USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE,
    error,
  };
}

