import React, { useState } from "react";
import { Image, Container } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchLiveVideosHistoryStart,
  fetchMoreLiveVideosHistoryStart
} from "../../store/actions/LiveVideoAction";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import LiveVideoCard from "../Helper/LiveVideoCard";
import CategoryFollowLoader from "../Helper/CategoryFollowLoader";

const LiveHistory = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchLiveVideosHistoryStart({
      user_unique_id: params.user_unique_id,
      skip: 0,
      take: 12,
    }))
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreLiveVideosHistoryStart({
        user_unique_id: params.user_unique_id,
        skip: props.liveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  return (

    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec live">
          <div className="new-card-head pt-8">
            <h3>{t("live_history")}</h3>
          </div>
          <div className="category-card-sec mt-4">
            {props.liveVideos.loading ?
              < CategoryFollowLoader />
              :
              Object.keys(props.liveVideos.data).length > 0 &&
                props.liveVideos.data.live_videos.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.liveVideos.data.live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.liveVideos.data.live_videos.length <
                    props.liveVideos.data.total}
                  loader={<CategoryFollowLoader />}
                >
                  <div className="category-card-box">
                    {props.liveVideos.data.live_videos.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  liveVideos: state.homepage.liveVideosHistory,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveHistory);
