import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FanTabSec from "./FanTabSec";
import FanActiveSec from "./FanActiveSec";
import FanAllSec from "./FanAllSec";
import "./Follow.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchActiveFollowersStart,
  fetchFollowersStart,
} from "../../store/actions/FollowAction";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Tab } from "react-bootstrap";

const FanIndex = (props) => {

  const t = useTranslation();
  const [activeSec, setActiveSec] = useState("active-sec");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeSec === "active-sec") {
      props.dispatch(fetchActiveFollowersStart({
        skip: 0,
        take: 12,
      }));
    } else if (activeSec === "all") {
      props.dispatch(fetchFollowersStart({
        skip: 0,
        take: 12,
      }));
    }
  }, [activeSec]);

  return (
    <>
      <div className="lists">
        <Container>
          <Row>
            <Col sm={12} md={12} xs={12}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side">
                  <div className="pull-left">
                    <h3>
                      <Link
                        to="#"
                        className="bookmarkes-list"
                        onClick={() => navigate(-1)}
                      >
                        <Image src={window.location.origin + "/images/backs.svg"}
                          className="svg-clone"
                        />
                        {t("fans")}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="listing-tab">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <FanTabSec
                    activeSec={activeSec}
                    setActiveSec={setActiveSec}
                  />
                  <div className="tab-content tabs">
                    {activeSec === "active-sec" &&
                      <FanActiveSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        followers={props.activeFollowers}
                      />
                    }
                    {activeSec === "all" &&
                      <FanAllSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        followers={props.followers}
                      />
                    }
                  </div>
                </Tab.Container>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  followers: state.follow.followers,
  activeFollowers: state.follow.activeFollowers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros, mapDispatchToProps)(withTranslation(FanIndex));
