import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { recentCategoriesStart } from "../../store/actions/HomePageAction";
import HomeCategoryLoader from "../Helper/HomeCategoryLoader";
import LiveVideoCard from "../Helper/LiveVideoCard";

const HomeCategorySec = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(recentCategoriesStart());
  }, [])

  return (
    <>
      {props.recentCategories.loading ?
        <HomeCategoryLoader />
        :
        Object.keys(props.recentCategories.data).length > 0 &&
          props.recentCategories.data.recent_categories.length > 0 ?
          props.recentCategories.data.recent_categories.map(
            (live_video) => (
              <div className="category-card-sec mt-4">
                <div className="sub-title ">
                  <h3>{live_video.name}</h3>
                  {live_video.live_videos.length > 4 ?
                    <Link to={`/category-videos/${live_video.category_id}/${live_video.unique_id}`}>{t("view_all")}</Link>
                    :
                    null
                  }
                </div>
                <div className="category-card-box">
                  {live_video.live_videos.map(
                    (live_video) => (
                      <LiveVideoCard live_video={live_video} />
                    ))}
                </div>
              </div>
            ))
          :
          null
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  recentCategories: state.homepage.recentCategories,
  liveBookmarkSave: state.liveVideo.liveBookmarkSave,
  profile: state.users.profile,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeCategorySec);
