import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PageReducer from "./PageReducer";
import BankAccountReducer from "./BankAccountReducer";
import HomePageReducer from "./HomePageReducer";
import LiveVideoReducer from "./LiveVideoReducer";
import OrderReducer from "./OrderReducer"
import ProductsReducer from "./ProductsReducer";
import WalletReducer from "./WalletReducer";
import TransactionReducer from "./TransactionReducer";
import LookUpReducer from "./LookUpReducer";
import OtherUserReducer from "./OtherUserReducer";
import DeliveryAddressReducer from "./DeiveryAddressReducer";
import FollowReducer from "./FollowReducer";

export default combineReducers({
    users: UserReducer,
    page: PageReducer,
    bankAccount: BankAccountReducer,
    homepage: HomePageReducer,
    liveVideo: LiveVideoReducer,
    order: OrderReducer,
    userProducts: ProductsReducer,
    wallet: WalletReducer,
    transaction: TransactionReducer,
    lookUp: LookUpReducer,
    otherUser: OtherUserReducer,
    deliveryAddress:DeliveryAddressReducer,
    follow: FollowReducer,
})