import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { usersSearchStart, fetchUserDetailsStart } from "../../../store/actions/UserAction";
import CommonCenterLoader from "../../Helper/CommonCenterLoader";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const HeaderIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [show, toggleShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const handleSearch = (event) => {
    setInputValue(event.target.value);
    if (event.currentTarget.value === "") {
      toggleShow(false);
    }
    else {
      toggleShow(true);
      props.dispatch(
        usersSearchStart({ key: event.currentTarget.value })
      )
    }
  }
  const resetInputField = () => {
    setInputValue("");
    toggleShow(false);
  }

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken"))
      props.dispatch(fetchUserDetailsStart());
  }, []);


  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">
                <Image
                  className="logo"
                  src={configuration.get("configData.site_logo")}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.svg"}
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Form>
                  <div className="header-search">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={window.location.origin + "/images/search.svg"}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("username")}
                        aria-label="Username"
                        name="key"
                        value={inputValue}
                        onChange={handleSearch}
                        autoComplete="off"
                        type="text"
                        aria-describedby="basic-addon1"
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        className="search-close"
                        style={{
                          border: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {show ? (
                          <Image
                            onClick={resetInputField}
                            src={window.location.origin + "/images/closes.svg"}
                          />
                        ) : null}
                      </InputGroup.Text>
                    </InputGroup>
                    {show && (
                      <div className="search-suggestion-sec">
                        <ul className="list-unstyled search-dropdown-list-sec">
                          {props.usersSearch.loading ? (
                            <CommonCenterLoader />
                          ) : props.usersSearch.data.users.length > 0 ? (
                            props.usersSearch.data.users.map((user) => (
                              <li className="search-res">
                                <Link
                                  to={`/${user.user_unique_id}`}
                                  onClick={resetInputField}
                                >
                                  <div className="search-body">
                                    <div className="user-img-sec">
                                      <CustomLazyLoad
                                        src={user.picture}
                                        placeholderSrc={window.location.origin + "/images/loading.svg"}
                                        className="user-img"
                                      />
                                    </div>
                                    <div className="search-content">
                                      <h5 className="title">{user.name}</h5>
                                      <h5 className="user-title">{user.username}</h5>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))
                          ) : (
                            <div className="search-no-result-sec">
                              <p>{t("no_search_result_found")}</p>
                              <div className="no-result-search-header">
                                <div className="no-result-search-icon">
                                  <Image
                                    src={window.location.origin + "/images/search-opt.svg"}
                                  />
                                  <i className="fas fa-search"></i>
                                </div>
                                <div className="no-result-search-info">
                                  <p>"{inputValue}"</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Form>
              </Nav>
              <Nav className="ms-auto align-items-center nav-end">
                {!localStorage.getItem("userId") ||
                  !localStorage.getItem("accessToken") ?
                  <Nav.Link>
                    <Link className="head-sign" to="/login">
                      <div className="header-invite-btn">
                        <Image
                          src={window.location.origin + "/images/sign-in.svg"}
                        />
                        {t("sign_in")}
                      </div>
                    </Link>
                  </Nav.Link>
                  : (
                    <>
                      {props.profile.data.is_content_creator === 1
                        ?
                        <Nav.Link href="/become-a-seller">{t("become_a_seller")}</Nav.Link>
                        : null}
                        {/* Hide invite friends from header */}
                      {/* <Nav.Link href="#">
                        <div className="header-invite-btn">
                          <Image
                            src={window.location.origin + "/images/invite.svg"}
                          />
                          {t("invite_friends")}
                        </div>
                      </Nav.Link> */}
                      <Nav.Link href="#">
                        <div className="user-dropdown-sec">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <Image
                                src={props.profile.data.picture}
                                className="head-profile-img"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Link to="/profile">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M14 21h-4l-.551-2.48a6.991 6.991 0 0 1-1.819-1.05l-2.424.763-2-3.464 1.872-1.718a7.055 7.055 0 0 1 0-2.1L3.206 9.232l2-3.464 2.424.763A6.992 6.992 0 0 1 9.45 5.48L10 3h4l.551 2.48a6.992 6.992 0 0 1 1.819 1.05l2.424-.763 2 3.464-1.872 1.718a7.05 7.05 0 0 1 0 2.1l1.872 1.718-2 3.464-2.424-.763a6.99 6.99 0 0 1-1.819 1.052L14 21z"
                                    />
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="3"
                                      stroke="currentColor"
                                      stroke-width="2"
                                    />
                                  </svg>
                                </span>
                                {t("settings")}
                              </Link>
                              <Link to="/logout">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-log-out"
                                  >
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                  </svg>
                                </span>
                                {t("logout")}
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Nav.Link>
                    </>
                  )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
const mapStateToProps = (state) => ({
  profile: state.users.profile,
  usersSearch: state.users.usersSearch,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(HeaderIndex));

