import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "react-bootstrap";

const LiveStreamingLoader = () => {
  return (
    <>
      <Col md={6}>
        <div className="live-streaming-middle-sec">
          <Skeleton count={1} height={530} />
        </div>
      </Col>
    </>
  );
};

export default LiveStreamingLoader;
