import {
  FETCH_CATEGORIES_LIST_START,
  FETCH_CATEGORIES_LIST_SUCCESS,
  FETCH_CATEGORIES_LIST_FAILURE,
  FETCH_FOLLOWING_CATEGORIES_LIST_START,
  FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS,
  FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE,
  FETCH_SUB_CATEGORY_START,
  FETCH_SUB_CATEGORY_SUCCESS,
  FETCH_SUB_CATEGORY_FAILURE,
  FETCH_SUB_CATEGORY_VIDEOS_START,
  FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
  FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
  FETCH_MORE_SUB_CATEGORY_VIDEOS_START,
  FETCH_ONGOING_LIVE_VIDEOS_START,
  FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
  FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
  FETCH_MORE_ONGOING_LIVE_VIDEOS_START,
  RECENT_CATEGORIES_START,
  RECENT_CATEGORIES_SUCCESS,
  RECENT_CATEGORIES_FAILURE,
  LIVE_VIDEO_BOOKMARK_SAVE_START,
  LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
  LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
  FETCH_BOOKMARKED_VIDEOS_START,
  FETCH_BOOKMARKED_VIDEOS_SUCCESS,
  FETCH_BOOKMARKED_VIDEOS_FAILURE,
  FETCH_MORE_BOOKMARKED_VIDEOS_START,
  FETCH_SUB_CATEGORIES_START,
  FETCH_SUB_CATEGORIES_SUCCESS,
  FETCH_SUB_CATEGORIES_FAILURE,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_CATEGORY_VIDEOS_SUCCESS,
  FETCH_CATEGORY_VIDEOS_FAILURE,
  FETCH_MORE_CATEGORY_VIDEOS_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
  FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_CATEGORIES_FOLLOW_START,
  FETCH_CATEGORIES_FOLLOW_SUCCESS,
  FETCH_CATEGORIES_FOLLOW_FAILURE,
  FETCH_MORE_CATEGORIES_FOLLOW_START,
} from "../actions/ActionConstant";

const initialState = {
  categoriesList: {
    data: {},
    loading: true,
    error: false,
  },
  followingCategoriesList: {
    data: {},
    loading: true,
    error: false,
  },
  subCategory: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  subCategoryVideos: {
    data: {
      live_videos: []
    },
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  onGoingLiveVideos: {
    data: {
      live_videos: []
    },
    loading: true,
    error: false
  },
  recentCategories: {
    data: {
      recent_categories: [],
    },
    loading: true,
    error: false,
    inputData: {},
  },
  liveBookmarkSave: {
    data: {},
    loading: true,
    error: false,
  },
  bookmarkedVideos: {
    data: {
      live_video_bookmarks: [],
      total: 0,
    },
    loading: true,
    error: false,
  },
  subCategories: {
    data: {},
    loading: true,
    error: false,
  },
  categoryVideos: {
    data: {
      live_videos: []
    },
    loading: true,
    error: false,
  },
  liveVideosHistory: {
    data: {
      live_videos: [],
      live_video_upcomings: [],
      total: 0,
      total_live_video_upcomings: 0,
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  categoriesFollow: {
    data: {
      category_live_videos: [],
    },
    loading: true,
    error: false,
  },
};
const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_LIST_START:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoriesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_FOLLOWING_CATEGORIES_LIST_START:
      return {
        ...state,
        followingCategoriesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        followingCategoriesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        followingCategoriesList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SUB_CATEGORY_START:
      return {
        ...state,
        subCategory: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case FETCH_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        subCategory: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SUB_CATEGORY_VIDEOS_START:
      return {
        ...state,
        subCategoryVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.subCategoryVideos.data.live_videos : [],
            total_live_videos: 0,
          },
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case FETCH_SUB_CATEGORY_VIDEOS_SUCCESS:
      return {
        ...state,
        subCategoryVideos: {
          data: {
            ...action.data,
            live_videos: [...state.subCategoryVideos.data.live_videos, ...action.data.live_videos],
            total_live_videos: action.data.total_live_videos,
          },
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SUB_CATEGORY_VIDEOS_FAILURE:
      return {
        ...state,
        subCategoryVideos: {
          data: state.subCategoryVideos.data,
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_MORE_SUB_CATEGORY_VIDEOS_START:
      return state

    case FETCH_ONGOING_LIVE_VIDEOS_START:
      return {
        ...state,
        onGoingLiveVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.onGoingLiveVideos.data.live_videos : [],
            total_live_videos: 0,
          },
          loading: true,
          error: false,
        }
      };
    case FETCH_ONGOING_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        onGoingLiveVideos: {
          data: {
            ...action.data,
            live_videos: [...state.onGoingLiveVideos.data.live_videos, ...action.data.live_videos],
            total_live_videos: action.data.total_live_videos,
          },
          loading: false,
          error: false,
        }
      };
    case FETCH_ONGOING_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        onGoingLiveVideos: {
          data: state.onGoingLiveVideos.data,
          loading: true,
          error: action.error,
        }
      };
    case FETCH_MORE_ONGOING_LIVE_VIDEOS_START:
      return state;

    case RECENT_CATEGORIES_START:
      return {
        ...state,
        recentCategories: {
          data: {
            recent_categories: action.data?.fetchMore === true ? state.recentCategories.data.recent_categories : []
          },
          loading: true,
          error: false,
        },
      };
    case RECENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        recentCategories: {
          data: {
            ...action.data,
            recent_categories: [...state.recentCategories.data.recent_categories, ...action.data.recent_categories],
            total_videos: action.data.total_videos,
          },
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case RECENT_CATEGORIES_FAILURE:
      return {
        ...state,
        recentCategories: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case LIVE_VIDEO_BOOKMARK_SAVE_START:
      return {
        ...state,
        liveBookmarkSave: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS:
      return {
        ...state,
        liveBookmarkSave: {
          data: action.data,
          loading: false,
          error: false,
        },
        liveVideosHistory: {
          ...state.liveVideosHistory,
          data: {
            ...state.liveVideosHistory.data,
            live_videos: state.liveVideosHistory.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        },
        categoriesFollow: {
          ...state.categoriesFollow,
          data: {
            ...state.categoriesFollow.data,
            category_live_videos: state.categoriesFollow.data.category_live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        },
        onGoingLiveVideos: {
          ...state.onGoingLiveVideos,
          data: {
            ...state.onGoingLiveVideos.data,
            live_videos: state.onGoingLiveVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        },

        bookmarkedVideos: {
          ...state.bookmarkedVideos,
          data: {
            live_video_bookmarks: state.bookmarkedVideos.data.live_video_bookmarks.filter((video) =>
              video.live_video_id !== action.data.live_video.live_video_id
            )
          },
        },
        recentCategories: {
          ...state.recentCategories,
          data: {
            ...state.recentCategories.data,
            recent_categories: state.recentCategories.data.recent_categories.map((category) =>
              category.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : category
            )
          }
        },
        recentCategories: {
          ...state.recentCategories,
          data: {
            ...state.recentCategories.data,
            recent_categories: state.recentCategories.data.recent_categories.map(category =>
              category.category_id === action.data.live_video.category_id ? {
                ...category,
                live_videos: category.live_videos.map(video =>
                  video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
                )
              } : category
            )
          }
        },
        categoryVideos: {
          ...state.categoryVideos,
          data: {
            ...state.categoryVideos.data,
            live_videos: state.categoryVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        },
        subCategoryVideos: {
          ...state.subCategoryVideos,
          data: {
            ...state.subCategoryVideos.data,
            live_videos: state.subCategoryVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        }
      };

    case LIVE_VIDEO_BOOKMARK_SAVE_FAILURE:
      return {
        ...state,
        liveBookmarkSave: {
          data: {},
          loading: false,
          error: action.error,
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_START:
      return {
        ...state,
        bookmarkedVideos: {
          data: {
            live_video_bookmarks: action.data?.fetchMore === true ? state.bookmarkedVideos.data.live_video_bookmarks : [],
            total: 0,
          },
          loading: true,
          error: false,
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_SUCCESS:
      return {
        ...state,
        bookmarkedVideos: {
          data: {
            ...action.data,
            live_video_bookmarks: [...state.bookmarkedVideos.data.live_video_bookmarks, ...action.data.live_video_bookmarks],
            total: action.data.total,
          },
          loading: false,
          error: false,
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_FAILURE:
      return {
        ...state,
        bookmarkedVideos: {
          data: state.bookmarkedVideos.data,
          loading: false,
          error: action.error,
        }
      };
    case FETCH_MORE_BOOKMARKED_VIDEOS_START:
      return state;

    case FETCH_SUB_CATEGORIES_START:
      return {
        ...state,
        subCategories: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        subCategories: {
          data: {},
          loading: false,
          error: action.error,
        }
      };
    case FETCH_CATEGORY_VIDEOS_START:
      return {
        ...state,
        categoryVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.categoryVideos.data.live_videos : [],
            total_live_videos: 0,
          },
          loading: true,
          error: false,
        }
      };
    case FETCH_CATEGORY_VIDEOS_SUCCESS:
      return {
        ...state,
        categoryVideos: {
          data: {
            ...action.data,
            live_videos: [...state.categoryVideos.data.live_videos, ...action.data.live_videos],
            total_live_videos: action.data.total_live_videos,
          },
          loading: false,
          error: false,
        }
      };
    case FETCH_CATEGORY_VIDEOS_FAILURE:
      return {
        ...state,
        categoryVideos: {
          data: state.categoryVideos.data,
          loading: true,
          error: action.error,
        }
      };
    case FETCH_LIVE_VIDEOS_HISTORY_START:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.liveVideosHistory.data.live_videos : [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_SUCCESS:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            ...action.data,
            live_videos: [...state.liveVideosHistory.data.live_videos, ...action.data.live_videos],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_FAILURE:
      return {
        ...state,
        liveVideosHistory: {
          data: state.liveVideosHistory.data,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_LIVE_VIDEOS_HISTORY_START:
      return state;

    case FETCH_MORE_CATEGORY_VIDEOS_START:
      return state;

    case FETCH_CATEGORIES_FOLLOW_START:
      return {
        ...state,
        categoriesFollow: {
          data: {
            category_live_videos: action.data?.fetchMore === true ?
              state.categoriesFollow.data.category_live_videos : [],
            total_category_live_videos: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_CATEGORIES_FOLLOW_SUCCESS:
      return {
        ...state,
        categoriesFollow: {
          data: {
            ...action.data,
            category_live_videos: [...state.categoriesFollow.data.category_live_videos,
            ...action.data.category_live_videos],
            total_category_live_videos: action.data.total_category_live_videos,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_CATEGORIES_FOLLOW_FAILURE:
      return {
        ...state,
        categoriesFollow: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_CATEGORIES_FOLLOW_START:
      return state;

    default:
      return state;
  }
};

export default HomePageReducer;

