import {
    ORDERS_LIST_START,
    ORDERS_LIST_SUCCESS,
    ORDERS_LIST_FAILURE,
    FETCH_ORDER_PAYMENT_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_SUCCESS,
    FETCH_ORDER_PAYMENT_LIST_FAILURE,
    ORDERS_MORE_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
    ORDERS_VIEW_FOR_OTHERS_SUCCESS,
    ORDERS_VIEW_FOR_OTHERS_FAILURE,
    FETCH_MORE_ORDER_PAYMENT_LIST_START,
    CANCEL_ORDER_START,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    ORDER_LIST_FOR_SELLER_START,
    ORDER_LIST_FOR_SELLER_SUCCESS,
    ORDER_LIST_FOR_SELLER_FAILURE,
    FETCH_MORE_ORDER_LIST_FOR_SELLER_START,
    UPDATE_ORDER_STATUS_START,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
    orderList: {
        data: {},
        loading: true,
        error: false,
    },
    orderPayments: {
        data: {},
        loading: true,
        error: false,
    },
    ordersViewForOthers: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    cancelOrder: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    orderListForSeller: {
        data: {},
        loading: true,
        error: false,
    },
    updateOrderStatus: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_LIST_START:
            return {
                ...state,
                orderList: {
                    data: {
                        orders: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case ORDERS_LIST_SUCCESS:
            return {
                ...state,
                orderList: {
                    data: {
                        orders: [...state.orderList.data.orders, ...action.data.orders],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                },
            };
        case ORDERS_LIST_FAILURE:
            return {
                ...state,
                orderList: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_ORDER_PAYMENT_LIST_START:
            return {
                ...state,
                orderPayments: {
                    data: {
                        orders: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                orderPayments: {
                    data: {
                        orders: [...state.orderPayments.data.orders, ...action.data.orders],
                        total: action.data.total
                    },
                    loading: false,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                orderPayments: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_MORE_ORDER_PAYMENT_LIST_START:
            return state;

        case ORDERS_MORE_LIST_START:
            return state;

        case ORDERS_VIEW_FOR_OTHERS_START:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_SUCCESS:
            return {
                ...state,
                ordersViewForOthers: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_FAILURE:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case CANCEL_ORDER_START:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                cancelOrder: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case ORDER_LIST_FOR_SELLER_START:
            return {
                ...state,
                orderListForSeller: {
                    data: {
                        orders: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case ORDER_LIST_FOR_SELLER_SUCCESS:
            return {
                ...state,
                orderListForSeller: {
                    data: {
                        orders: [...state.orderListForSeller.data.orders, ...action.data.orders],
                        total: action.data.total
                    },
                    loading: false,
                    error: false,
                }
            };
        case ORDER_LIST_FOR_SELLER_FAILURE:
            return {
                ...state,
                orderListForSeller: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
            case FETCH_MORE_ORDER_LIST_FOR_SELLER_START:
                return state;

        case UPDATE_ORDER_STATUS_START:
            return {
                ...state,
                updateOrderStatus: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case UPDATE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                updateOrderStatus: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case UPDATE_ORDER_STATUS_FAILURE:
            return {
                ...state,
                updateOrderStatus: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };

        default:
            return state;
    }
};

export default OrderReducer;
