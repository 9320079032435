import React, { useEffect } from "react";
import { useParams } from "react-router";
import { fetchStaticPageStart } from "../../store/actions/PageAction";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Container } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";

const StaticPage = (props) => {

    const t = useTranslation();
    const { unique_id } = useParams();

    useEffect(() => {
        if (unique_id) {
            props.dispatch(
                fetchStaticPageStart({ unique_id: unique_id })
            );
        }
        window.scrollTo(0, 0);
    }, [unique_id]);

    return (
        <Container>
            <div className="static-page-sec">
                {props.pageData.loading ?
                    <>
                        <div className="static-box">
                            <Skeleton count={1} height={30} className="mb-2" />
                            <Skeleton count={1} height={175} />
                        </div>
                    </>
                    : Object.keys(props.pageData.data).length > 0 ? (
                        <>
                            <div className="contact-banner-content">
                                <h4 className="head-title">{props.pageData.data.title}</h4>
                            </div>

                            <Row>
                                <Col md={12}>
                                    <div className="static-box">
                                        <h5 className="text-muted"><span>{t("updated_at")} :</span>{props.pageData.data.updated_at_formatted}</h5>
                                        <p>{props.pageData.data.title}</p>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : <NoDataFound />}

            </div>
        </Container>
    )
}

const mapStateToPros = (state) => ({
    pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(StaticPage);