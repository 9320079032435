import {
    REGISTER_START,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FETCH_USER_DETAILS_START,
    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_DETAILS_FAILURE,
    UPDATE_USER_DETAILS_START,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAILURE,
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    DELETE_ACCOUNT_START,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,
    USERNAME_VALIDATION_START,
    USERNAME_VALIDATION_SUCCESS,
    USERNAME_VALIDATION_FAILURE,
    REGISTER_VERIFY_START,
    REGISTER_VERIFY_SUCCESS,
    REGISTER_VERIFY_FAILURE,
    REGISTER_VERIFY_RESEND_START,
    REGISTER_VERIFY_RESEND_SUCCESS,
    REGISTER_VERIFY_RESEND_FAILURE,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    VERIFY_FORGOT_PASSWORD_START,
    VERIFY_FORGOT_PASSWORD_SUCCESS,
    VERIFY_FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    USERS_SEARCH_START,
    USERS_SEARCH_SUCCESS,
    USERS_SEARCH_FAILURE,
    FETCH_SINGLE_USER_DETAILS_START,
    FETCH_SINGLE_USER_DETAILS_SUCCESS,
    FETCH_SINGLE_USER_DETAILS_FAILURE,
    BECOME_SELLER_START,
    BECOME_SELLER_SUCCESS,
    BECOME_SELLER_FAILURE,
    GET_KYC_DOCUMENT_START,
    GET_KYC_DOCUMENT_SUCCESS,
    GET_KYC_DOCUMENT_FAILURE,
    SAVE_BLOCK_USER_START,
    SAVE_BLOCK_USER_SUCCESS,
    SAVE_BLOCK_USER_FAILURE,
    FETCH_BLOCK_USERS_START,
    FETCH_BLOCK_USERS_SUCCESS,
    FETCH_BLOCK_USERS_FAILURE,
    FETCH_MORE_BLOCK_USERS_START,
    FETCH_LISTS_DETAILS_START,
    FETCH_LISTS_DETAILS_SUCCESS,
    FETCH_LISTS_DETAILS_FAILURE
} from "./ActionConstant";

// User register actions.

export function registerStart(data) {
    return {
        type: REGISTER_START,
        data,
    };
}

export function registerSuccess(data) {
    return {
        type: REGISTER_SUCCESS,
        data,
    };
}

export function registerFailure(error) {
    return {
        type: REGISTER_FAILURE,
        error,
    };
}

// User login actions.

export function loginStart(data) {
    return {
        type: LOGIN_START,
        data,
    };
}

export function loginSuccess(data) {
    return {
        type: LOGIN_SUCCESS,
        data,
    };
}

export function loginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        error
    }
}

// Get user details actions.

export function fetchUserDetailsStart(data) {
    return {
        type: FETCH_USER_DETAILS_START,
        data,
    };
}

export function fetchUserDetailsSuccess(data) {
    return {
        type: FETCH_USER_DETAILS_SUCCESS,
        data,
    };
}
export function fetchUserDetailsFailure(error) {
    return {
        type: FETCH_USER_DETAILS_FAILURE,
        error,
    };
}

// Update user details actions.

export function updateUserDetailsStart(data) {
    return {
        type: UPDATE_USER_DETAILS_START,
        data,
    };
}
export function updateUserDetailsSuccess(data) {
    return {
        type: UPDATE_USER_DETAILS_SUCCESS,
        data,
    };
}

export function updateUserDetailsFailure(error) {
    return {
        type: UPDATE_USER_DETAILS_FAILURE,
        error,
    };
}

//Change Password

export function changePasswordStart(data) {
    return {
        type: CHANGE_PASSWORD_START,
        data,
    };
}

export function changePasswordSuccess(data) {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        data,
    };
}

export function changePasswordFailure(error) {
    return {
        type: CHANGE_PASSWORD_FAILURE,
        error,
    };
}

// Username Validation 

export function deleteAccountStart(data) {
    return {
        type: DELETE_ACCOUNT_START,
        data,
    };
}

export function deleteAccountSuccess(data) {
    return {
        type: DELETE_ACCOUNT_SUCCESS,
        data,
    };
}

export function deleteAccountFailure(error) {
    return {
        type: DELETE_ACCOUNT_FAILURE,
        error,
    };
}

// Delete User Account
export function usernameValidationStart(data) {
    return {
        type: USERNAME_VALIDATION_START,
        data,
    };
}

export function usernameValidationSuccess(data) {
    return {
        type: USERNAME_VALIDATION_SUCCESS,
        data,
    };
}

export function usernameValidationFailure(error) {
    return {
        type: USERNAME_VALIDATION_FAILURE,
        error,
    };
}

// Register Verify User

export function registerVerifyStart(data) {
    return {
        type: REGISTER_VERIFY_START,
        data,
    };
}

export function registerVerifySuccess(data) {
    return {
        type: REGISTER_VERIFY_SUCCESS,
        data,
    };
}

export function registerVerifyFailure(error) {
    return {
        type: REGISTER_VERIFY_FAILURE,
        error,
    };
}

// Register Verify Resend

export function registerVerifyResendStart(data) {
    return {
        type: REGISTER_VERIFY_RESEND_START,
        data,
    };
}

export function registerVerifyResendSuccess(data) {
    return {
        type: REGISTER_VERIFY_RESEND_SUCCESS,
        data,
    };
}

export function registerVerifyResendFailure(error) {
    return {
        type: REGISTER_VERIFY_RESEND_FAILURE,
        error,
    };
}

// Forgot Password

export function forgotPasswordStart(data) {
    return {
        type: FORGOT_PASSWORD_START,
        data,
    };
}

export function forgotPasswordSuccess(data) {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        data,
    };
}

export function forgotPasswordFailure(error) {
    return {
        type: FORGOT_PASSWORD_FAILURE,
        error,
    };
}

// Verify Forgot Password

export function verifyForgotPasswordStart(data) {
    return {
        type: VERIFY_FORGOT_PASSWORD_START,
        data,
    };
}

export function verifyForgotPasswordSuccess(data) {
    return {
        type: VERIFY_FORGOT_PASSWORD_SUCCESS,
        data,
    };
}

export function verifyForgotPasswordFailure(error) {
    return {
        type: VERIFY_FORGOT_PASSWORD_FAILURE,
        error,
    };
}

// Reset Password

export function resetPasswordStart(data) {
    return {
        type: RESET_PASSWORD_START,
        data,
    };
}

export function resetPasswordSuccess(data) {
    return {
        type: RESET_PASSWORD_SUCCESS,
        data,
    };
}

export function resetPasswordFailure(error) {
    return {
        type: RESET_PASSWORD_FAILURE,
        error,
    };
}

// Fetch Users Search

export function usersSearchStart(data) {
    return {
        type: USERS_SEARCH_START,
        data,
    };
}

export function usersSearchSuccess(data) {
    return {
        type: USERS_SEARCH_SUCCESS,
        data,
    };
}

export function usersSearchFailure(error) {
    return {
        type: USERS_SEARCH_FAILURE,
        error,
    };
}

// Fetch Single Users Search

export function fetchSingleUserStart(data) {
    return {
        type: FETCH_SINGLE_USER_DETAILS_START,
        data,
    };
}

export function fetchSingleUserSuccess(data) {
    return {
        type: FETCH_SINGLE_USER_DETAILS_SUCCESS,
        data,
    };
}

export function fetchSingleUserFailure(error) {
    return {
        type: FETCH_SINGLE_USER_DETAILS_FAILURE,
        error,
    };
}

// Become a Seller

export function becomeSellerStart(data) {
    return {
        type: BECOME_SELLER_START,
        data,
    };
}

export function becomeSellorSuccess(data) {
    return {
        type: BECOME_SELLER_SUCCESS,
        data,
    };
}

export function becomeSellorFailure(error) {
    return {
        type: BECOME_SELLER_FAILURE,
        error,
    };
}

// Get kyc document actions.

export function getKycDocumentStart(data) {
    return {
        type: GET_KYC_DOCUMENT_START,
        data,
    };
}

export function getKycDocumentSuccess(data) {
    return {
        type: GET_KYC_DOCUMENT_SUCCESS,
        data,
    };
}

export function getKycDocumentFailure(error) {
    return {
        type: GET_KYC_DOCUMENT_FAILURE,
        error,
    };
}

export function saveBlockUserStart(data) {
    return {
      type: SAVE_BLOCK_USER_START,
      data,
    };
  }
  
  export function saveBlockUserSuccess(data) {
    return {
      type: SAVE_BLOCK_USER_SUCCESS,
      data,
    };
  }
  
  export function saveBlockUserFailure(error) {
    return {
      type: SAVE_BLOCK_USER_FAILURE,
      error,
    };
  }
  
  export function fetchBlockUsersStart(data) {
    return {
      type: FETCH_BLOCK_USERS_START,
      data,
    };
  }
  
  export function fetchBlockUsersSuccess(data) {
    return {
      type: FETCH_BLOCK_USERS_SUCCESS,
      data,
    };
  }
  
  export function fetchBlockUsersFailure(error) {
    return {
      type: FETCH_BLOCK_USERS_FAILURE,
      error,
    };
  }

  export function fetchMoreBlockUsersStart(data) {
    return {
      type: FETCH_MORE_BLOCK_USERS_START,
      data,
    };
  }

  export function fetchListsDetailsStart(data) {
    return {
      type: FETCH_LISTS_DETAILS_START,
      data,
    };
  }
  
  export function fetchListsDetailsSuccess(data) {
    return {
      type: FETCH_LISTS_DETAILS_SUCCESS,
      data,
    };
  }
  
  export function fetchListsDetailsFailure(error) {
    return {
      type: FETCH_LISTS_DETAILS_FAILURE,
      error,
    };
  }