import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchOrderListStart,
  fetchMoreOrderListStart,
  cancelOrderStart,
} from "../../store/actions/OrderAction";
import { propTypes } from "react-bootstrap/esm/Image";
import NoDataFound from "../Helper/NoDataFound";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableOrderLoader from "../Helper/TableOrderLoader";
import InfiniteScroll from "react-infinite-scroll-component";


const OrderListIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.dispatch(fetchOrderListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreOrderListStart({
      skip: props.orderList.data.orders.length,
      take: 12,
    }));
  }


  const handleChange = (event) => {
    setSearch(event.target.value);
    props.dispatch(fetchOrderListStart({
      search_key: event.target.value,
    }));
  }

  const cancelOrder = (event, order_id) => {
    event.preventDefault();
    props.dispatch(cancelOrderStart({
      order_id: order_id,
    }));
  }

  const cancelOrderConfirmation = (event, order_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_cancel_order"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => cancelOrder(event, order_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  }

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="order-list-sec">
            <div className="order-list-tab">
              <Row>
                <Col sm={12}>
                  <div className="nav-search-tab">
                    <div className="single-page-title">
                      <Link to="#" onClick={() => navigate(-1)}>
                        <Image src={window.location.origin + "/images/back.svg"} />
                      </Link>
                      <h3>{t("order_list")}</h3>
                    </div>
                    <div className="search-end-sec">
                      <Form>
                        <div className="tab-search-end">
                          <div className="search-box">
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/order/search.svg"
                                  }
                                />
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={search}
                                onChange={(event) => handleChange(event)}
                              />
                            </InputGroup>
                          </div>
                          <div className="add-bag-img">
                            <Link to="#">
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/order/bag.svg"
                                }
                              />
                            </Link>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="order-list-table">
                    {props.orderList.loading
                      ?
                      <TableOrderLoader />
                      :
                      Object.keys(props.orderList.data).length > 0 &&
                        props.orderList.data.orders.length > 0
                        ?
                        <InfiniteScroll
                          dataLength={props.orderList.data.orders.length}
                          next={fetchMoreData}
                          hasMore={
                            props.orderList.data.orders.length <
                            props.orderList.data.total}
                          loader={<TableOrderLoader />}
                        >
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>{t("products")}</th>
                                <th>{t("order_id")}</th>
                                <th>{t("shipping_address")}</th>
                                <th>{t("Phone Number")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("status")}</th>
                                <th>{t("action_order")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.orderList.data.orders.map((order, index) => (
                                <tr key={index}>
                                  <td>
                                    {order.order_product.map(
                                      (product, index) => (
                                        <>
                                          {product.user_product_details ? (
                                            <div
                                              className={`order-list-product ${order.total_products > 1
                                                ? "mb-3"
                                                : ""
                                                }`}
                                              key={index}
                                            >
                                              <div
                                                className="img"
                                                style={{
                                                  backgroundImage: `url(${product.user_product_details.picture})`,
                                                }}
                                              ></div>
                                              <div className="email">
                                                <span>
                                                  {
                                                    product
                                                      .user_product_details
                                                      .name
                                                  }{" "}
                                                </span>
                                                <span>
                                                  {t("quantity")} :{" "}
                                                  {product.quantity}
                                                </span>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </td>
                                  <td>{order.unique_id}</td>
                                  {order.delivery_address ?
                                    <>
                                      <td className="address">
                                        {order.delivery_address.landmark}, <br />
                                        {order.delivery_address.address}-
                                        {order.delivery_address.pincode} <br />
                                      </td>
                                      <td>{order.delivery_address.contact_number}</td>
                                    </>
                                    : <><td className="address"></td><td></td></>
                                  }
                                  <td className="quantity">
                                    {order.total_formatted}
                                  </td>
                                  <td className="quantity">
                                    {order.order_status}
                                  </td>
                                  <td className="text-right">
                                    <Link
                                      className="order-view-btn mr-3"
                                      to={`/order-view/${order.unique_id}`}
                                    >
                                      {t("view")}
                                    </Link>
                                    {order.order_btn_status.cancel_btn_status ?
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={(event) => cancelOrderConfirmation(event, order.id)}
                                      >
                                        {t("cancel")}
                                      </Button>
                                      :
                                      null
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                        :
                        <NoDataFound />
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div >
    </>
  );
};

const mapStateToPros = (state) => ({
  orderList: state.order.orderList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderListIndex));

