import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const LiveVideoChatLoader = ({ count }) => {
  return (
    <Col md={3} className="p-0">
      <Skeleton count={1} height={450} />
      <Skeleton className="mt-3" count={1} height={60} />
    </Col>
  );
};

export default LiveVideoChatLoader;
