import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Link } from 'react-router-dom';
import NoDataFound from '../Helper/NoDataFound';
import {
  fetchSingleProductOrdersStart,
  fetchMoreSingleProductOrdersStart
} from '../../store/actions/ProductsAction';
import { useNavigate } from 'react-router-dom';
import TableOrderLoader from '../Helper/TableOrderLoader';
import InfiniteScroll from 'react-infinite-scroll-component';

const SingleProductOrders = (props) => {

  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.dispatch(fetchSingleProductOrdersStart({
      user_product_id: params.user_product_id,
      skip: 0,
      take: 12,
    }))
  }, [])

  const fetchMoreData = () => {
    props.dispatch(fetchMoreSingleProductOrdersStart({
      skip: props.singleProductOrders.data.orders.length,
      take: 12,
    }
    ));
  }

  const handleChange = (event) => {
    setSearch(event.target.value);
    props.dispatch(fetchSingleProductOrdersStart({
      user_product_id: params.user_product_id,
      search_key: event.target.value,
    }))
  }

  const clearItem = () => {
    setSearch("");
    props.dispatch(fetchSingleProductOrdersStart({
      user_product_id: params.user_product_id,
    }))
  }

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("order_list")}</h3>
            </div>
          </div>
          <div className="order-list-sec">
            <div className="order-list-tab">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <div className="nav-search-tab">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <div className="order-tab-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/order/order.svg"
                                }
                              />
                              <span>{t("my_orders")}</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <div className="search-end-sec">
                        <Form>
                          <div className="tab-search-end">
                            <div className="search-box">
                              <InputGroup>
                                <InputGroup.Text id="basic-addon1">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/order/search.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Search"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={search}
                                  onChange={(event) => handleChange(event)}
                                />
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="search-close"
                                  style={{
                                    border: "0",
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {search ?
                                    <span onClick={clearItem}>
                                      <i class="fa-solid fa-xmark"></i>
                                    </span>
                                    :
                                    null
                                  }
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                            <div className="add-bag-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/order/bag.svg"
                                  }
                                />
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="order-list-table">
                          {props.singleProductOrders.loading
                            ?
                            <TableOrderLoader />
                            :
                            Object.keys(props.singleProductOrders.data).length > 0 &&
                              props.singleProductOrders.data.orders.length > 0
                              ?
                              < InfiniteScroll
                                dataLength={props.singleProductOrders.data.orders.length}
                                next={fetchMoreData}
                                hasMore={
                                  props.singleProductOrders.data.orders.length <
                                  props.singleProductOrders.data.total
                                }
                                loader={<TableOrderLoader />}
                              >
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th>{t("shipping_address")}</th>
                                      <th>{t("phone_number")}</th>
                                      <th>{t("amount")}</th>
                                      <th>{t("quantity")}</th>
                                      <th className="text-right">{t("action")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {props.singleProductOrders.data.orders.map(
                                      (order, index) => (
                                        <>
                                          <tr className="alert" role="alert" key={index}>
                                            <td className="address">
                                              {order.delivery_address ?
                                                <>
                                                  {order.delivery_address.landmark}, <br />
                                                  {order.delivery_address.address}-
                                                  {order.delivery_address.pincode} <br />
                                                </> : ""}
                                            </td>
                                            <td>
                                              {order.delivery_address ? order.delivery_address.contact_number : ''}
                                            </td>
                                            <td className="quantity">
                                              {order.total_formatted}
                                            </td>
                                            <td>{order.order_product ? order.order_product.quantity : 0}</td>
                                            <td className="text-right">
                                              <Link
                                                className="order-view-btn"
                                                to={`/order-view/${order.unique_id}`}
                                              >
                                                {t("view")}
                                              </Link>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </InfiniteScroll>
                              :
                              <NoDataFound />
                          }
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </div >
    </>
  )
}

const mapStateToPros = (state) => ({
  singleProductOrders: state.userProducts.singleProductOrders,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SingleProductOrders));
