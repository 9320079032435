import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_LIVE_VIDEO_ORDERS_START,
  FETCH_MORE_LIVE_VIDEO_ORDERS_START,
  LIVE_VIDEO_CALL_BROADCAST_START,
  LIVE_VIDEOS_END_START,
  LIVE_VIDEOS_START_CALL_START,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  FETCH_LIVE_VIDEO_MESSAGE_START,
  FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
  FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
  fetchLiveVideosProductsSuccess,
  fetchLiveVideosProductsFailure,
  fetchLiveVideosHistorySuccess,
  fetchLiveVideosHistoryFailure,
  fetchLiveVideoOrdersSuccess,
  fetchLiveVideoOrdersFailure,
  videoCallBroadcastSuccess,
  videoCallBroadcastFailure,
  liveVideoStartCallSuccess,
  liveVideoStartCallFailure,
  liveVideoEndSuccess,
  liveVideoEndFailure,
  fetchSingleLiveVideoSuccess,
  fetchSingleLiveVideoFailure,
  liveViewerUpdateSuccess,
  liveViewerUpdateFailure,
  fetchLiveVideoMessageFailure,
  fetchLiveVideoMessageSuccess,
  fetchLiveVideosScheduledOwnerSuccess,
  fetchLiveVideosScheduledOwnerFailure,
} from "../actions/LiveVideoAction";
import config from "react-global-configuration";
import io from "socket.io-client";

function* liveVideoSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_create",
      action.data
    );
    if (response.data.success) {
      yield put(videoCallBroadcastSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message)
    } else {
      yield put(videoCallBroadcastFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(videoCallBroadcastFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}

function* liveEndAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_broadcast_stop", action.data);
    if (response.data.success) {
      yield put(liveVideoEndSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(liveVideoEndFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error)
    }
  } catch (error) {
    yield put(liveVideoEndFailure(error));
    yield call(getErrorNotificationMessage, error)
  }
}

function* liveStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_start",
      action.data
    );
    if (response.data.success) {
      yield put(liveVideoStartCallSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message)
    } else {
      yield put(liveVideoStartCallFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(liveVideoStartCallFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchSingleLiveVideoAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_view", action.data);
    if (response.data.success) {
      yield put(fetchSingleLiveVideoSuccess(response.data.data));
    } else {
      yield put(fetchSingleLiveVideoFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleLiveVideoFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}


function* liveViewerUpdateAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_viewer_update", action.data);
    if (response.data.success) {
      yield put(liveViewerUpdateSuccess(response.data.data));
      let chatSocket;
      let chatSocketUrl = config.get("configData.chat_socket_url");
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + response.data.data.virtual_id + `'`,
      });

      chatSocket.emit("livevideo viewerupdate", response.data.data.virtual_id);
    } else {
      yield put(liveViewerUpdateFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(liveViewerUpdateFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}

function* liveVideosProductAPI(action) {
  try {
    const response = yield api.postMethod("live_video_products_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosProductsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchLiveVideosProductsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideosProductsFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* liveVideosHistoryAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosHistorySuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideosHistoryFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchLiveVideoOrdersAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_orders_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideoOrdersSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoOrdersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideoOrdersFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* liveVideoChatAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveVideoChat.inputData
    );
    const response = yield api.postMethod(
      "live_video_chat_messages",
      inputData
    );
    if (response.data.success) {
      yield put(fetchLiveVideoMessageSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoMessageFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideoMessageFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* liveVideosScheduledOwnerAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_scheduled_owner", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosScheduledOwnerSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosScheduledOwnerFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);;
    }
  } catch (error) {
    yield put(fetchLiveVideosScheduledOwnerFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* LiveVideoSaga() {
  yield all([yield takeLatest(LIVE_VIDEO_CALL_BROADCAST_START, liveVideoSaveAPI),]);
  yield all([yield takeLatest(LIVE_VIDEOS_END_START, liveEndAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_START_CALL_START, liveStartAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_LIVE_VIDEOS_START, fetchSingleLiveVideoAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_VIEWER_UPDATE_START, liveViewerUpdateAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_PRODUCTS_START, liveVideosProductAPI)]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START, liveVideosProductAPI)]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEO_ORDERS_START, fetchLiveVideoOrdersAPI),]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEO_ORDERS_START, fetchLiveVideoOrdersAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEO_MESSAGE_START, liveVideoChatAPI)]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START, liveVideosScheduledOwnerAPI)]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_SCHEDULED_OWNER_START, liveVideosScheduledOwnerAPI)]);
}

