import {
    FETCH_DELIVERY_ADDRESS_START,
    FETCH_DELIVERY_ADDRESS_SUCCESS,
    FETCH_DELIVERY_ADDRESS_FAILURE,
    SAVE_DELIVERY_ADDRESS_START,
    SAVE_DELIVERY_ADDRESS_SUCCESS,
    SAVE_DELIVERY_ADDRESS_FAILURE,
    DELETE_DELIVERY_ADDRESS_START,
    DELETE_DELIVERY_ADDRESS_SUCCESS,
    DELETE_DELIVERY_ADDRESS_FAILURE,
    SET_DEFAULT_DELIVERY_ADDRESS_START,
    SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
    SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
    VIEW_DELIVERY_ADDRESS_START,
    VIEW_DELIVERY_ADDRESS_SUCCESS,
    VIEW_DELIVERY_ADDRESS_FAILURE,
} from "./ActionConstant";

export function fetchDeliveryAddressStart(data) {
    return {
        type: FETCH_DELIVERY_ADDRESS_START,
        data,
    };
}

export function fetchDeliveryAddressSuccess(data) {
    return {
        type: FETCH_DELIVERY_ADDRESS_SUCCESS,
        data,
    };
}

export function fetchDeliveryAddressFailure(error) {
    return {
        type: FETCH_DELIVERY_ADDRESS_FAILURE,
        error,
    };
}

export function saveDeliveryAddressStart(data) {
    return {
        type: SAVE_DELIVERY_ADDRESS_START,
        data,
    }
}

export function saveDeliveryAddressSuccess(data) {
    return {
        type: SAVE_DELIVERY_ADDRESS_SUCCESS,
        data,
    }
}

export function saveDeliveryAddressFailure(error) {
    return {
        type: SAVE_DELIVERY_ADDRESS_FAILURE,
        error,
    }
}

export function deleteDeliveryAddressStart(data) {
    return {
        type: DELETE_DELIVERY_ADDRESS_START,
        data,
    }
}

export function deleteDeliveryAddressSuccess(data) {
    return {
        type: DELETE_DELIVERY_ADDRESS_SUCCESS,
        data,
    }
}

export function deleteDeliveryAddressFailure(error) {
    return {
        type: DELETE_DELIVERY_ADDRESS_FAILURE,
        error,
    }
}

export function setDefaultDeliveryAddressStart(data) {
    return {
        type: SET_DEFAULT_DELIVERY_ADDRESS_START,
        data,
    }
}

export function setDefaultDeliveryAddressSuccess(data) {
    return {
        type: SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
        data,
    }
}

export function setDefaultDeliveryAddressFailure(error) {
    return {
        type: SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
        error,
    }
}

export function viewDeliveryAddressStart(data) {
    return {
        type: VIEW_DELIVERY_ADDRESS_START,
        data,
    }
}

export function viewDeliveryAddressSuccess(data) {
    return {
        type: VIEW_DELIVERY_ADDRESS_SUCCESS,
        data,
    }
}

export function viewDeliveryAddressFailure(error) {
    return {
        type: VIEW_DELIVERY_ADDRESS_FAILURE,
        error,
    }
}

