import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Col } from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoStartCallStart,
} from "../../store/actions/LiveVideoAction";
import Counter from "../Utils/Counter";
AgoraRTC.setLogLevel(4);
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

const LiveSample = (props) => {

  const [skipRender, setSkipRender] = useState(true);

  const startConfirmation = () => {
    props.dispatch(
      liveVideoStartCallStart({
        live_video_id: props.liveVideo.data.live_video_id,
      })
    );
  }

  useEffect(() => {
    if (!skipRender && !props.liveVideoBroadCast.loading &&
      Object.keys(props.liveVideoBroadCast.data).length > 0) {
      window.location.reload();
    }
    setSkipRender(false)
  }, [props.liveVideoBroadCast]);

  return (
    <>
      <Col md={6}>
        <div className="live-streaming-middle-sec">
          <div className="app-main">
            <div className="video-call-wrapper">
              <div className="video-participant">
                <div className="participant-actions">
                  <ul className="list-unstyled participant-notify">
                    <li>
                      {props.liveVideo.data.formatted_schedule_time}{" "}
                    </li>
                    <li>
                      <i className="fas fa-eye mr-2"></i>
                      {props.liveVideo.data.viewer_cnt}
                    </li>
                    {props.isOwner ? (
                      <li>
                        <Link
                          to="#"
                          className="live-start-now"
                          onClick={startConfirmation}
                        >
                          {t("start_now")}
                        </Link>
                      </li>
                    ) : (
                      null
                    )}
                  </ul>
                </div>
                <img
                  className="blur-image"
                  src={props.liveVideo.data.preview_file}
                ></img>
                <Counter
                  preText={"Starts In "}
                  targetDate={props.liveVideo.data.schedule_time}
                />
                <Link
                  to={`/` + props.liveVideo.data.user_unique_id}
                  className="name-tag"
                >
                  {props.liveVideo.data.user_displayname}
                </Link>
                <div
                  id="agora_local"
                  style={{ width: "100%", height: "100%", position: "relative" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="agora-card">
          <div className="button-group mt-3 mb-3">
          </div>
        </div>
      </Col>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
  liveVideoBroadCast: state.liveVideo.liveVideoBroadCast,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveSample));
