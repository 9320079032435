import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchOngoingLiveVideosStart } from "../../store/actions/HomePageAction";
import { useEffect } from "react";
import HomeCategoryLoader from "../Helper/HomeCategoryLoader";
import LiveVideoCard from "../Helper/LiveVideoCard";

const LiveShowcard = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchOngoingLiveVideosStart(
      {
        take: 5,
      }
    ));
  }, []);

  return (

    <div className="category-card-sec mt-4">
      {props.onGoingLiveVideos.loading ?
        <HomeCategoryLoader />
        :
        Object.keys(props.onGoingLiveVideos.data).length > 0 &&
          props.onGoingLiveVideos.data.live_videos.length > 0 ?
          <>
            <div className="sub-title ">
              <h3>{t("live_show")}</h3>
              {props.onGoingLiveVideos.data.live_videos.length > 4 ?
                <Link to="/ongoing-live-shows">{t("view_all")}</Link>
                :
                null
              }
            </div>
            <div className="category-card-box">
              {props.onGoingLiveVideos.data.live_videos.map(
                (live_video) => (
                  <LiveVideoCard live_video={live_video} />
                ))}
            </div>
          </>
          :
          null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  onGoingLiveVideos: state.homepage.onGoingLiveVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveShowcard);
