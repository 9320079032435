import React from 'react'
import { Accordion, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";

const StaticSidebar = () => {
  const t = useTranslation();
  return (
    <div className="sidebar-footer-list">
      <ul className="list-unstyled">
        <li>
          <Link to="/page/about" target='_blank'>
            <Image src={window.location.origin + "/images/about.svg"} />
            <span>{t("about")}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/career" target='_blank'>
            <Image src={window.location.origin + "/images/career.svg"} />
            <span>{t("careers")}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/sellonwhynot" target='_blank'>
            <Image src={window.location.origin + "/images/cart.svg"} />
            <span>{t("sell_on", { siteName: configuration.get("configData.site_name") })}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/streamteam" target='_blank'>
            <Image src={window.location.origin + "/images/stream.svg"} />
            <span>{t("stream_team")}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/faq" target='_blank'>
            <Image src={window.location.origin + "/images/faq.svg"} />
            <span>{t("faq")}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/privacy" target='_blank'>
            <Image
              src={window.location.origin + "/images/privacy-policy.svg"}
            />
            <span>{t("privacy")}</span>
          </Link>
        </li>
        <li>
          <Link to="/page/terms" target='_blank'>
            <Image
              src={
                window.location.origin + "/images/terms-and-conditions.svg"
              }
            />
            <span>{t("terms")}</span>
          </Link>
        </li>
      </ul>
    </div>

  )
}

export default StaticSidebar;