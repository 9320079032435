import React, { useState, useEffect } from "react";
import { Container, Image, Button, Row, Col, ProgressBar, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Product.css";
import ImageGallery from 'react-image-gallery';
import NumericInput from 'react-numeric-input';
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { userProductViewForOthersStart } from "../../store/actions/ProductsAction";
import SingleProductLoader from "../Helper/ SingleProductLoader";
import NoDataFound from "../Helper/NoDataFound";

const SingleProductIndex = (props) => {

  const params = useParams();
  const [skipRender, setSkipRender] = useState(true);
  const [count, setCount] = useState(1);
  const [image, setImage] = useState("");

  useEffect(() => {
    props.dispatch(userProductViewForOthersStart({
      user_products_unique_id: params.product_unique_id,
    }));
  }, [])

  const t = useTranslation();

  useEffect(() => {
    if (!skipRender && 
      !props.productView.loading && 
      Object.keys(props.productView.data).length > 0) {
      setImage(props.productView.data.user_product.picture);
    }
    setSkipRender(false);
  }, [props.productView]);

  const images = [
    {
      original: image,
      thumbnail: image,
    }
  ];

  return (
    <>
      {props.productView.loading ?
        <SingleProductLoader />
        :
        <div className="single-product-sec">
          <Container>
            <Row>
              <Col md={6}>
                <div className="single-product-full-img-sec">
                  <ImageGallery items={images} />
                </div>
              </Col>
              <Col md={6}>
                {Object.keys(props.productView.data).length > 0 ?
                  <div className="single-product-info-sec">
                    <h4>{props.productView.data.user_product.name}</h4>
                    <div className="border-head-title"></div>
                    <p className="single-product-desc">{props.productView.data.user_product.description}</p>
                    <h3>{props.productView.data.user_product.price}</h3>
                    <div class="availability-sec"><h5>{t("availability")}: <span>{props.productView.data.user_product.is_outofstock === 0 ?
                      t('out_of_stock') : t('in_stock')}</span></h5></div>
                    <div class="availability-sec"><h5>{t('quantity')}: <span>{props.productView.data.user_product.quantity}</span></h5></div>
                    <div class="availability-sec"><h5>
                      {t('seller')}:
                      {props.productView.data.user_product.user.user_unique_id !==
                        localStorage.getItem("user_unique_id") ?
                        <span className="pro-user-name text-nowrap">
                          <Link to={`/${props.productView.data.user_product.user.user_unique_id}`}>
                            @{props.productView.data.user_product.user.name}
                          </Link>
                        </span>
                        :
                        <span className="pro-user-name text-nowrap">
                          <Link to={`/profile`}>
                            {t("you")}
                          </Link>
                        </span>
                      }
                    </h5></div>
                    <div class="rating-star-card">
                      <ul class="rating-star-sec list-unstyled">
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 -10 511.991 511"
                          >
                            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"></path>
                          </svg>
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 -10 511.991 511"
                          >
                            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"></path>
                          </svg>
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 -10 511.991 511"
                          >
                            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"></path>
                          </svg>
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 -10 511.991 511"
                          >
                            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"></path>
                          </svg>
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 -10 511.991 511"
                          >
                            <path d="M510.652 185.883a27.177 27.177 0 00-23.402-18.688l-147.797-13.418-58.41-136.75C276.73 6.98 266.918.497 255.996.497s-20.738 6.483-25.023 16.53l-58.41 136.75-147.82 13.418c-10.837 1-20.013 8.34-23.403 18.688a27.25 27.25 0 007.937 28.926L121 312.773 88.059 457.86c-2.41 10.668 1.73 21.7 10.582 28.098a27.087 27.087 0 0015.957 5.184 27.14 27.14 0 0013.953-3.86l127.445-76.203 127.422 76.203a27.197 27.197 0 0029.934-1.324c8.851-6.398 12.992-17.43 10.582-28.098l-32.942-145.086 111.723-97.964a27.246 27.246 0 007.937-28.926zM258.45 409.605"></path>
                          </svg>
                        </li>
                      </ul>
                      <p className="rating-desc">{props.productView.data.user_product.description}</p>
                    </div>
                    <div className="single-product-quantity-sec">
                      <p>
                        {t("qty")}
                        <span>
                          <NumericInput min={1} value={count} onChange={setCount} mobile className="form-control" />
                        </span>
                      </p>
                    </div>
                  </div>
                  :
                  <NoDataFound /> 
                }
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  productView: state.userProducts.productViewForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SingleProductIndex));
