import { getByTestId } from "@testing-library/react";
import {
    REGISTER_START,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FETCH_USER_DETAILS_START,
    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_DETAILS_FAILURE,
    UPDATE_USER_DETAILS_START,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAILURE,
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    DELETE_ACCOUNT_START,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,
    USERNAME_VALIDATION_START,
    USERNAME_VALIDATION_SUCCESS,
    USERNAME_VALIDATION_FAILURE,
    REGISTER_VERIFY_START,
    REGISTER_VERIFY_SUCCESS,
    REGISTER_VERIFY_FAILURE,
    REGISTER_VERIFY_RESEND_START,
    REGISTER_VERIFY_RESEND_SUCCESS,
    REGISTER_VERIFY_RESEND_FAILURE,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    VERIFY_FORGOT_PASSWORD_START,
    VERIFY_FORGOT_PASSWORD_SUCCESS,
    VERIFY_FORGOT_PASSWORD_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    USERS_SEARCH_START,
    USERS_SEARCH_SUCCESS,
    USERS_SEARCH_FAILURE,
    FETCH_SINGLE_USER_DETAILS_START,
    FETCH_SINGLE_USER_DETAILS_SUCCESS,
    FETCH_SINGLE_USER_DETAILS_FAILURE,
    BECOME_SELLER_START,
    BECOME_SELLER_SUCCESS,
    BECOME_SELLER_FAILURE,
    GET_KYC_DOCUMENT_START,
    GET_KYC_DOCUMENT_SUCCESS,
    GET_KYC_DOCUMENT_FAILURE,
    FETCH_BLOCK_USERS_START,
    FETCH_BLOCK_USERS_SUCCESS,
    FETCH_BLOCK_USERS_FAILURE,
    SAVE_BLOCK_USER_START,
    SAVE_BLOCK_USER_SUCCESS,
    SAVE_BLOCK_USER_FAILURE,
    FETCH_MORE_BLOCK_USERS_START,
    FETCH_LISTS_DETAILS_START,
    FETCH_LISTS_DETAILS_SUCCESS,
    FETCH_LISTS_DETAILS_FAILURE,
} from "../actions/ActionConstant";

const initialstate = {
    register: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    login: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    profile: {
        data: {},
        loading: true,
        error: false,
    },
    profileInputData: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    changePassword: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    registerVerify: {
        data: {},
        loading: true,
        buttonDisable: false,
        loadingButtonContent: null,
        error: false,
    },
    registerVerifyResend: {
        data: {},
        loading: true,
        buttonDisable: false,
        loadingButtonContent: null,
        error: false,
    },
    deleteAccount: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    validationInputData: {
        data: {},
        loading: true,
        error: false,
        isValid: false,
        isInValid: false,
    },
    forgotPasswordInputData: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    verifyForgotPassword: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    resetPassword: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    usersSearch: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        buttonLoadingContent: null,
    },
    singleUser: {
        data: {},
        loading: true,
        error: false,
    },
    becomeSeller: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    kycDocDetails: {
        data: {},
        loading: true,
        error: false,
    },
    blockUsers: {
        data: {},
        loading: true,
        error: false,
    },
    saveBlockUser: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    lists: {
        data: {},
        loading: true,
        error: false,
      },
};

const userReducer = (state = initialstate, action) => {
    switch (action.type) {
        case REGISTER_START:
            return {
                ...state,
                register: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                },
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                register: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                register: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case LOGIN_START:
            return {
                ...state,
                login: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                },
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                login: {
                    data: {},
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case FETCH_USER_DETAILS_START:
            return {
                ...state,
                profile: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_DETAILS_SUCCESS:
            return {
                ...state,
                profile: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_DETAILS_FAILURE:
            return {
                ...state,
                profile: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            }
        case UPDATE_USER_DETAILS_START:
            return {
                ...state,
                profileInputData: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                },
            }
        case UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                profileInputData: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            }
        case UPDATE_USER_DETAILS_FAILURE:
            return {
                ...state,
                profileInputData: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            }
        case CHANGE_PASSWORD_START:
            return {
                ...state,
                changePassword: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                }
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePassword: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case DELETE_ACCOUNT_START:
            return {
                ...state,
                deleteAccount: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                }
            };
        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                deleteAccount: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case DELETE_ACCOUNT_FAILURE:
            return {
                ...state,
                deleteAccount: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case USERNAME_VALIDATION_START:
            return {
                ...state,
                validationInputData: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                },
            };
        case USERNAME_VALIDATION_SUCCESS:
            return {
                ...state,
                validationInputData: {
                    data: action.data,
                    loading: false,
                    error: false,
                    isValid: true,
                    isInValid: false,
                },
            };
        case USERNAME_VALIDATION_FAILURE:
            return {
                ...state,
                validationInputData: {
                    data: {},
                    loading: false,
                    error: action.error,
                    isInValid: true,
                    isValid: false,
                },
            };
        case REGISTER_VERIFY_START:
            return {
                ...state,
                registerVerify: {
                    data: {},
                    loading: true,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                    error: false,
                },
            };
        case REGISTER_VERIFY_SUCCESS:
            return {
                ...state,
                registerVerify: {
                    data: action.data,
                    loading: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                    error: false,
                },
            };
        case REGISTER_VERIFY_FAILURE:
            return {
                ...state,
                registerVerify: {
                    data: {},
                    loading: false,
                    buttonDisable: true,
                    loadingButtonContent: null,
                    error: action.error,
                },
            };
        case REGISTER_VERIFY_RESEND_START:
            return {
                ...state,
                registerVerifyResend: {
                    data: {},
                    loading: true,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                    error: false,
                },
            };
        case REGISTER_VERIFY_RESEND_SUCCESS:
            return {
                ...state,
                registerVerifyResend: {
                    data: action.data,
                    loading: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                    error: false,
                },
            };
        case REGISTER_VERIFY_RESEND_FAILURE:
            return {
                ...state,
                registerVerifyResend: {
                    data: {},
                    loading: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                    error: action.error,
                },
            };
        case FORGOT_PASSWORD_START:
            return {
                ...state,
                forgotPasswordInputData: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                },
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordInputData: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                forgotPasswordInputData: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case VERIFY_FORGOT_PASSWORD_START:
            return {
                ...state,
                verifyForgotPassword: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                }
            };
        case VERIFY_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                verifyForgotPassword: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case VERIFY_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                verifyForgotPassword: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case RESET_PASSWORD_START:
            return {
                ...state,
                resetPassword: {
                    data: {},
                    loading: true,
                    error: false,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                }
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPassword: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                }
            };
        case USERS_SEARCH_START:
            return {
                ...state,
                usersSearch: {
                    data: {},
                    loading: true,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                    error: false,
                },
            };
        case USERS_SEARCH_SUCCESS:
            return {
                ...state,
                usersSearch: {
                    data: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                    loading: false,
                    error: false,
                },
            };
        case USERS_SEARCH_FAILURE:
            return {
                ...state,
                usersSearch: {
                    data: {},
                    loading: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                    error: action.error,
                },
            };
        case FETCH_SINGLE_USER_DETAILS_START:
            return {
                ...state,
                singleUser: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_SINGLE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                singleUser: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_SINGLE_USER_DETAILS_FAILURE:
            return {
                ...state,
                singleUser: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case BECOME_SELLER_START:
            return {
                ...state,
                becomeSeller: {
                    data: {},
                    loading: true,
                    buttonDisable: true,
                    loadingButtonContent: "Loading please wait",
                    error: false,
                },
            };
        case BECOME_SELLER_SUCCESS:
            return {
                ...state,
                becomeSeller: {
                    data: action.data,
                    loading: false,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case BECOME_SELLER_FAILURE:
            return {
                ...state,
                becomeSeller: {
                    data: {},
                    loading: false,
                    error: action.error,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case GET_KYC_DOCUMENT_START:
            return {
                ...state,
                kycDocDetails: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case GET_KYC_DOCUMENT_SUCCESS:
            return {
                ...state,
                kycDocDetails: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case GET_KYC_DOCUMENT_FAILURE:
            return {
                ...state,
                kycDocDetails: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_BLOCK_USERS_START:
            return {
                ...state,
                blockUsers: {
                    data: {
                        block_users: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case FETCH_BLOCK_USERS_SUCCESS:
            return {
                ...state,
                blockUsers: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_BLOCK_USERS_FAILURE:
            return {
                ...state,
                blockUsers: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_MORE_BLOCK_USERS_START:
            return state;

        case SAVE_BLOCK_USER_START:
            return {
                ...state,
                saveBlockUser: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case SAVE_BLOCK_USER_SUCCESS:
            return {
                ...state,
                saveBlockUser: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SAVE_BLOCK_USER_FAILURE:
            return {
                ...state,
                saveBlockUser: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
            case FETCH_LISTS_DETAILS_START:
                return {
                  ...state,
                  lists: {
                    data: {},
                    loading: true,
                    error: false,
                  },
                };
              case FETCH_LISTS_DETAILS_SUCCESS:
                return {
                  ...state,
                  lists: {
                    data: action.data,
                    loading: false,
                    error: false,
                  },
                };
              case FETCH_LISTS_DETAILS_FAILURE:
                return {
                  ...state,
                  lists: {
                    data: {},
                    loading: true,
                    error: action.error,
                  },
                };
        default:
            return state;
    }
};
export default userReducer;

