import React from "react";
import { useTranslation } from "react-multi-lang";
import {  Image, Nav, Col} from "react-bootstrap";

const FollowingTabSec = (props) => {

    const t = useTranslation("");

    return (
        <Col sm={12}>
            <Nav variant="pills">
                <Nav.Item>
                    <Nav.Link eventKey="first"
                        onClick={() => props.setActiveSec("active-sec")}>
                        <div className="order-tab-img">
                            <Image
                                src={
                                    window.location.origin +
                                    "/images/tick-circle.svg"
                                }
                            />
                            <span>
                                {t("active")}
                            </span>
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="second"
                        onClick={() => props.setActiveSec("all")}
                    > <div className="order-tab-img">
                    <Image
                        src={
                            window.location.origin +
                            "/images/all.svg"
                        }
                    />
                    <span>
                        {t("all")}
                    </span>
                </div> </Nav.Link>
                </Nav.Item>
            </Nav>
        </Col>
    )
}

export default FollowingTabSec;