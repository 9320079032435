import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import LiveStreamingIndex from "../LiveStream/LiveStreamingIndex";
import BecomeASellerIndex from "../Seller/BecomeASellerIndex";
import BookmarIndex from "../Bookmark/BookmarIndex";
import AddBankIndex from "../BankAccounts/AddBankIndex";
import OrderListIndex from "../OrderList/OrderListIndex";
import OtherProfile from "../Profile/OtherProfile";
import ProfileIndex from "../Profile/ProfileIndex";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ChangePasswordIndex from "../Profile/ChangePassworIndex";
import DeleteAccountIndex from "../Profile/DeleteAccountIndex";
import LogoutIndex from "../Auth/LogoutIndex";
import StaticPage from "../static/StaticPage";
import VerificationIndex from "../Verification/VerificationIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import BankAccount from "../BankAccounts/BankAccount";
import VerfiyForgotPassword from "../Auth/VerfiyForgotPassword";
import ResetPassword from "../Auth/ResetPassword";
import EditBankAccount from "../BankAccounts/EditBankAccount";
import CategoryIndex from "../Category/CategoryIndex";
import OrderTransaction from "../OrderList/OrderTransaction";
import WalletIndex from "../Wallet/WalletIndex";
import UploadDocumentIndex from "../UploadDocument/UploadDocumentIndex";
import AddProduct from "../Product/AddProduct";
import EditProduct from "../Product/EditProduct";
import ProductsList from "../Product/ProductList";
import SingleProductIndex from "../Product/SingleProductIndex";
import LiveVideosHistory from "../LiveStream/LiveVideosHistory";
import GoLiveIndex from "../LiveStream/GoLiveIndex";
import LiveVideoOrder from "../LiveStream/LiveVideoOrder";
import SingleProductOrders from "../Product/SingleProductOrders";
import OrderView from "../OrderList/OrderView";
import OnGoingLiveVideos from "../LandingPage/OnGoingLiveVideos";
import CategoryVideos from "../LandingPage/CategoryVideos";
import VideoCallLayout from "../layouts/VideoCallLayout";
import VideoCallIndex from "../VideoCall/VideoCallIndex";
import PaymentsIndex from "../Wallet/PaymentsIndex";
import Page404 from "../Helper/Page404";
import SellerOrderList from "../OrderList/SellerOrderList";
import UserLiveVideosIndex from "../LiveStream/UserLiveVideosIndex";
import AddDeliveryAddress from "../DeliveryAddress/AddDeliveryAddress";
import DeliveryAddressIndex from "../DeliveryAddress/DeliveryAddressIndex";
import EditDeliveryAddress from "../DeliveryAddress/EditDeliveryAddress";
import ListIndex from "../List/ListIndex";
import FanIndex from "../Fans/FanIndex";
import FollowingIndex from "../Following/FollowingIndex";
import BlockedUserIndex from "../BlockedUser/BlockedUserIndex";
import FollowingCategories from "../LandingPage/FollowingCategories";

setTranslations({ en });

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {

  setLanguage("en");

  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={LandingPageIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/live-stream/:live_video_unique_id"}
            element={
              <AppRoute component={LiveStreamingIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/become-a-seller"}
            element={
              <AppRoute component={BecomeASellerIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/bookmarks"}
            element={<AppRoute component={BookmarIndex} layout={MainLayout} />}
          />
          <Route
            path={"/order-list"}
            element={
              <AppRoute component={OrderListIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/profile"}
            element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
          />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/change-password"}
            element={<PrivateRoute component={ChangePasswordIndex} layout={MainLayout} />}
          />
          <Route
            path={"/delete-account"}
            element={<PrivateRoute component={DeleteAccountIndex} layout={MainLayout} />}
          />
          <Route
            path={"/logout"}
            element={<PrivateRoute component={LogoutIndex} layout={MainLayout} />}
          />
          <Route
            path={"/register/verify"}
            element={<AppRoute component={VerificationIndex} layout={MainLayout} />}
          />
          <Route
            path={"/page/:unique_id"}
            element={<AppRoute component={StaticPage} layout={MainLayout} />}
          />
          <Route
            path={"/forgot-password"}
            element={<AppRoute component={ForgotPasswordIndex} layout={MainLayout} />}
          />
          <Route
            path={"/verify-forget-password"}
            element={<AppRoute component={VerfiyForgotPassword} layout={EmptyLayout} />}
          />
          <Route
            path={"/reset-password"}
            element={<AppRoute component={ResetPassword} layout={EmptyLayout} />}
          />
          <Route
            path="/add-bank-account"
            element={<PrivateRoute component={AddBankIndex} layout={MainLayout} />}
          />
          <Route
            path="/bank-accounts"
            element={<PrivateRoute component={BankAccount} layout={MainLayout} />}
          />
          <Route
            path="/edit-bank-account/:user_billing_account_id"
            element={<PrivateRoute component={EditBankAccount} layout={MainLayout} />}
          />
          <Route
            path="/:user_unique_id"
            element={<AppRoute component={OtherProfile} layout={MainLayout} />}
          />
          <Route
            path="/category/:category_id/:sub_category_id"
            element={<AppRoute component={CategoryIndex} layout={MainLayout} />}
          />
          <Route
            path={"/order-transaction"}
            element={
              <PrivateRoute component={OrderTransaction} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet"}
            element={
              <PrivateRoute component={WalletIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/add-document"}
            element={
              <AppRoute component={UploadDocumentIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/add-product"
            element={<PrivateRoute component={AddProduct} layout={MainLayout} />}
          />
          <Route
            path="/product-list"
            element={<PrivateRoute component={ProductsList} layout={MainLayout} />}
          />
          <Route
            path="/edit-product/:user_product_id"
            element={<PrivateRoute component={EditProduct} layout={MainLayout} />}
          />
          <Route
            path={"/single-product/:product_unique_id"}
            element={
              <PrivateRoute component={SingleProductIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/view-order/:unique_id/:user_product_id"}
            element={
              <PrivateRoute component={SingleProductOrders} layout={MainLayout} />
            }
          />

          <Route
            path={"/live-videos-history"}
            element={
              <PrivateRoute component={LiveVideosHistory} layout={MainLayout} />
            }
          />
          <Route
            path={"/go-live"}
            element={
              <PrivateRoute component={GoLiveIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/live/:live_video_unique_id"}
            element={
              <PrivateRoute component={VideoCallIndex} layout={VideoCallLayout} />
            }
          />
          <Route
            path={"/live-orders/:live_video_id"}
            element={
              <PrivateRoute component={LiveVideoOrder} layout={MainLayout} />
            }
          />
          <Route
            path={"/order-view/:unique_id"}
            element={
              <PrivateRoute component={OrderView} layout={MainLayout} />
            }
          />
          <Route
            path={"/order-transaction"}
            element={
              <PrivateRoute component={OrderTransaction} layout={MainLayout} />
            }
          />
          <Route
            path={"/ongoing-live-shows"}
            element={
              <PrivateRoute component={OnGoingLiveVideos} layout={MainLayout} />
            }
          />
          <Route
            path={"/category-videos/:categoryid/:category_id"}
            element={
              <PrivateRoute component={CategoryVideos} layout={MainLayout} />
            }
          />
          <Route
            path={"/payments"}
            element={
              <PrivateRoute component={PaymentsIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/seller-orders"}
            element={
              <PrivateRoute component={SellerOrderList} layout={MainLayout} />
            }
          />
          <Route
            path={"/live-videos"}
            element={
              <PrivateRoute component={UserLiveVideosIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/add-delivery-address"}
            element={
              <PrivateRoute component={AddDeliveryAddress} layout={MainLayout} />
            }
          />

          <Route
            path={"/delivery-address"}
            element={
              <PrivateRoute component={DeliveryAddressIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/edit-delivery-address/:delivery_address_unique_id"
            element={<PrivateRoute component={EditDeliveryAddress} layout={MainLayout} />}
          />
          <Route
            path="/list"
            element={<PrivateRoute component={ListIndex} layout={MainLayout} />}
          />
          <Route
            path="/fans"
            element={<PrivateRoute component={FanIndex} layout={MainLayout} />}
          />
          <Route
            path="/following"
            element={<PrivateRoute component={FollowingIndex} layout={MainLayout} />}
          />
          <Route
            path="/blocked-users"
            element={<PrivateRoute component={BlockedUserIndex} layout={MainLayout} />}
          />
         <Route
            path="/blocked-users"
            element={<PrivateRoute component={BlockedUserIndex} layout={MainLayout} />}
          />
          <Route
            path={"/categories-follow"}
            element={
              <AppRoute component={FollowingCategories} layout={MainLayout} />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;


