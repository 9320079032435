import React from "react"
import Skeleton from "react-loading-skeleton";

const FollowingLoader = () => {
  return (
    <>
      <Skeleton width={150} height={17} />
      <div className="row">
        {[...Array(6)].map(() =>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <Skeleton height={250} />
          </div>
        )}
      </div>
    </>
  )
}

export default FollowingLoader;