import React, { useEffect } from 'react'
import { useParams } from "react-router";
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  fetchSubCategoryStart,
  fetchSubCategoryVideosStart,
  fetchMoreSubCategoryVideosStart,
} from '../../store/actions/HomePageAction';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NoDataFound from '../Helper/NoDataFound';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryFollowLoader from '../Helper/CategoryFollowLoader';
import SubCategoryLoader from '../Helper/SubCategoryLoader';
import LiveVideoCard from '../Helper/LiveVideoCard';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { followsSubCategoriesStart } from '../../store/actions/LookUpAction';

const CategoryIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const { category_id, sub_category_id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSubCategoryStart({
      category_id: category_id,
    }));
  }, []);

  useEffect(() => {
    props.dispatch(fetchSubCategoryVideosStart({
      sub_category_id: sub_category_id,
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreSubCategoryVideosStart({
        sub_category_id: sub_category_id,
        skip: props.subCategoryVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  const followConfirmation = () => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(followsSubCategoriesStart({
        sub_category_id: sub_category_id,
      }))
    } else {
      navigate("/login")
    }
  }

  return (
    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
          <div className="new-card-head pt-8">
            <h3 className="sub-catdata">{t("sub_categories")}</h3>
          </div>
          {props.subCategory.loading ?
            <div className="category-tab-card">
              <ul>
                <li> <Skeleton count={1} width={110} height={50} /></li>
                <li> <Skeleton count={1} width={110} height={50} /></li>
              </ul>
            </div>
            :
            Object.keys(props.subCategory.data).length > 0 &&
              props.subCategory.data.sub_categories.length > 0
              ?
              <div className="category-tab-card">
                {props.subCategory.data.sub_categories.map(
                  (subcategory, i) => (
                    <ul key={i}>
                      <li><Link to="#">{subcategory.name}</Link></li>
                    </ul>
                  ))}
              </div>
              :
              null
          }
          <div className="category-card-sec mt-4">
            {props.subCategoryVideos.loading ?
              <SubCategoryLoader />
              :
              Object.keys(props.subCategoryVideos.data).length > 0 &&
                props.subCategoryVideos.data.live_videos.length > 0
                ?
                <>
                  <div className="new-category-header-sec">
                    <h1>{props.subCategoryVideos.data.name}</h1>
                    {props.subCategoryVideos.data.is_following ?
                      <Button
                        type="button"
                        className="outfyt-btn"
                        onClick={followConfirmation}
                      >
                        {t("following")}
                      </Button>
                      :
                      <Button
                        type="button"
                        className="outfyt-btn"
                        onClick={followConfirmation}
                      >
                        {t("follow")}
                      </Button>
                    }
                  </div>
                  <InfiniteScroll
                    dataLength={props.subCategoryVideos.data.live_videos.length}
                    next={fetchMoreData}
                    hasMore={
                      props.subCategoryVideos.data.live_videos.length <
                      props.subCategoryVideos.data.total_live_videos
                    }
                    loader={<CategoryFollowLoader />}
                  >
                    <div className="new-category-card-sec">
                      <h1> {props.subCategoryVideos.data.total_live_videos} {t("shows")}</h1>
                      <div className="category-total-card">
                        {props.subCategoryVideos.data.live_videos.map(
                          (live_video) => (
                            <LiveVideoCard live_video={live_video} />
                          ))}
                      </div>
                    </div>
                  </InfiniteScroll>
                </>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  subCategory: state.homepage.subCategory,
  subCategoryVideos: state.homepage.subCategoryVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(CategoryIndex));