import React, { useEffect } from 'react'
import { Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {
  fetchSubCategoryStart,
  fetchCategoryVideosStart,
  fetchMoreCategoryVideosStart,
} from '../../store/actions/HomePageAction';
import { useParams } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from '../Helper/NoDataFound';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import CategoryFollowLoader from '../Helper/CategoryFollowLoader';
import LiveVideoCard from '../Helper/LiveVideoCard';

const CategoryVideos = (props) => {

  const params = useParams();
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(
      fetchSubCategoryStart({
        category_id: params.categoryid,
      }));
  }, []);


  useEffect(() => {
    props.dispatch(
      fetchCategoryVideosStart({
        category_id: params.category_id,
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreCategoryVideosStart({
        category_id: params.category_id,
        skip: props.categoryVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <Container fluid className="p-0">
        <div className="new-category-sec">
          <div className="home-page-right-sec">
            <div className="new-card-head pt-8">
              <h3 className="sub-catdata">{t("sub_categories")}</h3>
            </div>
            {props.subCategory.loading ?
              <div className="category-tab-card">
                <ul>
                  <li> <Skeleton count={1} width={110} height={50} /></li>
                  <li> <Skeleton count={1} width={110} height={50} /></li>
                </ul>
              </div>
              :
              Object.keys(props.subCategory.data).length > 0 &&
                props.subCategory.data.sub_categories.length > 0
                ?
                <div className="category-tab-card">
                  {props.subCategory.data.sub_categories.map(
                    (subcategory, i) => (
                      <ul key={i}>
                        <li><Link to={`/category/${subcategory.category_id}/${subcategory.unique_id}`}>{subcategory.name}</Link></li>
                      </ul>
                    ))}
                </div>
                :
                null
            }
            <div className="category-card-sec mt-4">
              {props.categoryVideos.loading ?
                <CategoryFollowLoader />
                :
                Object.keys(props.categoryVideos.data).length > 0 &&
                  props.categoryVideos.data.live_videos.length > 0 ?
                  <InfiniteScroll
                    dataLength={props.categoryVideos.data.live_videos.length}
                    next={fetchMoreData}
                    hasMore={
                      props.categoryVideos.data.live_videos.length <
                      props.categoryVideos.data.total_live_videos
                    }
                    loader={<CategoryFollowLoader />}
                  >
                    <div className="category-card-box">
                      {props.categoryVideos.data.live_videos.map(
                        (live_video) => (
                          <LiveVideoCard live_video={live_video} />
                        ))}
                    </div>
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
const mapStateToProps = (state) => ({
  subCategory: state.homepage.subCategory,
  categoryVideos: state.homepage.categoryVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)(withTranslation(CategoryVideos));
