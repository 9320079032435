import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_CATEGORIES_LIST_START,
  FETCH_FOLLOWING_CATEGORIES_LIST_START,
  FETCH_SUB_CATEGORY_START,
  FETCH_SUB_CATEGORY_VIDEOS_START,
  FETCH_MORE_SUB_CATEGORY_VIDEOS_START,
  FETCH_ONGOING_LIVE_VIDEOS_START,
  RECENT_CATEGORIES_START,
  LIVE_VIDEO_BOOKMARK_SAVE_START,
  FETCH_BOOKMARKED_VIDEOS_START,
  FETCH_SUB_CATEGORIES_START,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_MORE_CATEGORY_VIDEOS_START,
  FETCH_MORE_ONGOING_LIVE_VIDEOS_START,
  FETCH_MORE_BOOKMARKED_VIDEOS_START,
  FETCH_CATEGORIES_FOLLOW_START,
  FETCH_MORE_CATEGORIES_FOLLOW_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
  fetchCategoriesListSuccess,
  fetchCategoriesListFailure,
  fetchFollowingCategoriesListSuccess,
  fetchFollowingCategoriesListFailure,
  fetchSubCategoryFailure,
  fetchSubCategorySuccess,
  fetchSubCategoryVideosSuccess,
  fetchSubCategoryVideosFailure,
  fetchOngoingLiveVideosSuccess,
  fetchOngoingLiveVideosFailure,
  recentCategoriesSuccess,
  recentCategoriesFailure,
  liveVideoBookmarkSaveSuccess,
  liveVideoBookmarkSaveFailure,
  fetchBookmarkedVideosSuccess,
  fetchBookmarkedVideosFailure,
  fetchSubCategoriesSuccess,
  fetchSubCategoriesFailure,
  fetchCategoryVideosSuccess,
  fetchCategoryVideosFailure,
  fetchCategoriesFollowSuccess,
  fetchCategoriesFollowFailure,
} from "../actions/HomePageAction";

function* fetchCategoriesListAPI() {
  try {
    const response = yield api.postMethod("categories_list");

    if (response.data.success) {
      yield put(fetchCategoriesListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message)
    } else {
      yield put(fetchCategoriesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCategoriesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchFollowingCategoriesListAPI() {
  try {
    const response = yield api.postMethod("following_categories_list");

    if (response.data.success) {
      yield put(fetchFollowingCategoriesListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchFollowingCategoriesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchFollowingCategoriesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchSubCategoryAPI(action) {
  try {
    const response = yield api.postMethod("sub_categories", action.data);
    if (response.data.success) {
      yield put(fetchSubCategorySuccess(response.data.data));
    } else {
      yield put(fetchSubCategoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSubCategoryFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchSubCategoryVideosAPI(action) {
  try {
    const response = yield api.postMethod("sub_category_videos", action.data);
    if (response.data.success) {
      yield put(fetchSubCategoryVideosSuccess(response.data.data));
    } else {
      yield put(fetchSubCategoryVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSubCategoryVideosFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchOnGoingLiveVideosAPI(action) {
  try {
    const response = yield api.postMethod("ongoing_live_videos", action.data);
    if (response.data.success) {
      yield put(fetchOngoingLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchOngoingLiveVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchOngoingLiveVideosFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* recentCatehoriesAPI(action) {
  try {

    const response = yield api.postMethod("recent_categories", action.data);
    if (response.data.success) {
      yield put(recentCategoriesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(recentCategoriesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(recentCategoriesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* liveVideoBookmarkSaveAPI(action) {
  try {
    const response = yield api.postMethod("live_video_bookmarks_save", action.data);
    if (response.data.success) {
      yield put(liveVideoBookmarkSaveSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(liveVideoBookmarkSaveFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(liveVideoBookmarkSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchBookmarkedVideosAPI(action) {
  try {
    const response = yield api.postMethod("live_video_bookmarks", action.data);
    if (response.data.success) {
      yield put(fetchBookmarkedVideosSuccess(response.data.data));
    } else {
      yield put(fetchBookmarkedVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBookmarkedVideosFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSubCategoriesAPI(action) {
  try {
    const response = yield api.postMethod("sub_categories", action.data);
    if (response.data.success) {
      yield put(fetchSubCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchSubCategoriesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSubCategoriesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
function* fetchCategoryVideosAPI(action) {
  try {
    const response = yield api.postMethod("category_videos", action.data);
    if (response.data.success) {
      yield put(fetchCategoryVideosSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchCategoryVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCategoryVideosFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchCategoriesFollowAPI(action) {
  try {
    const response = yield api.postMethod("following_category_videos", action.data);
    if (response.data.success) {
      yield put(fetchCategoriesFollowSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchCategoriesFollowFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCategoriesFollowFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

export default function* HomePageSaga() {
  yield all([
    yield takeLatest(FETCH_CATEGORIES_LIST_START, fetchCategoriesListAPI),
    yield takeLatest(FETCH_FOLLOWING_CATEGORIES_LIST_START, fetchFollowingCategoriesListAPI),
    yield takeLatest(FETCH_SUB_CATEGORY_START, fetchSubCategoryAPI),
    yield takeLatest(FETCH_SUB_CATEGORY_VIDEOS_START, fetchSubCategoryVideosAPI),
    yield takeLatest(FETCH_MORE_SUB_CATEGORY_VIDEOS_START, fetchSubCategoryVideosAPI),
    yield takeLatest(FETCH_ONGOING_LIVE_VIDEOS_START, fetchOnGoingLiveVideosAPI),
    yield takeLatest(FETCH_MORE_ONGOING_LIVE_VIDEOS_START, fetchOnGoingLiveVideosAPI),
    yield takeLatest(RECENT_CATEGORIES_START, recentCatehoriesAPI),
    yield takeLatest(LIVE_VIDEO_BOOKMARK_SAVE_START, liveVideoBookmarkSaveAPI),
    yield takeLatest(FETCH_BOOKMARKED_VIDEOS_START, fetchBookmarkedVideosAPI),
    yield takeLatest(FETCH_MORE_BOOKMARKED_VIDEOS_START, fetchBookmarkedVideosAPI),
    yield takeLatest(FETCH_SUB_CATEGORIES_START, fetchSubCategoriesAPI),
    yield takeLatest(FETCH_CATEGORY_VIDEOS_START, fetchCategoryVideosAPI),
    yield takeLatest(FETCH_MORE_CATEGORY_VIDEOS_START, fetchCategoryVideosAPI),
    yield takeLatest(FETCH_CATEGORIES_FOLLOW_START, fetchCategoriesFollowAPI),
    yield takeLatest(FETCH_MORE_CATEGORIES_FOLLOW_START, fetchCategoriesFollowAPI),

  ])
}


