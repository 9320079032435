import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import FollowingLoader from '../Helper/FollowingLoader';
import { connect } from 'react-redux';
import UserCard from './UserCard';
import { fetchMoreActiveFollowersStart } from '../../store/actions/FollowAction';
import NoDataFound from '../Helper/NoDataFound';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation, withTranslation } from 'react-multi-lang';
import Skeleton from 'react-loading-skeleton';

const FanActiveSec = (props) => {

  const t = useTranslation();

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreActiveFollowersStart({
        skip: props.followers.data.followers.length,
        take: 12,
      })
    );
  };

  return (
    <div
      role="tabpanel"
    >
      {props.followers.loading ? (
        <FollowingLoader />
      ) : (
        <>
          <div className="bookmarkes-list bookmarks-right-side">
            <div className="pull-left">
              <Link className="bookmarkes-list" to="#">
                <span className="mr-2">
                  {props.followers.data.total
                    ? props.followers.data.total
                    : 0}{" "}
                </span>{" "}
                {t("active")}
              </Link>
            </div>
          </div>
          <Row>
            {Object.keys(props.followers.data).length > 0
              && props.followers.data.followers.length > 0 ?
              <InfiniteScroll
                className="custom-infinite-scroll"
                dataLength={props.followers.data.followers.length}
                next={fetchMoreData}
                hasMore={props.followers.data.followers.length < props.followers.data.total}
                loader={
                  <div className="row">
                    {[...Array(6)].map(() =>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Skeleton height={250} />
                      </div>
                    )}
                  </div>
                }
              >
                {props.followers.data.followers.map((follower) => (
                  <UserCard user={follower.otherUser} />
                ))}
              </InfiniteScroll>
              : (
                <NoDataFound />
              )}
          </Row>
        </>
      )}
    </div>
  )
}


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  null, mapDispatchToProps)(withTranslation(FanActiveSec));

